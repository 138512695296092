import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "layouts";
import { Banner } from "components/commons";
import { LOGIN_PATH } from "routes";
import { resetPassword, getAuthCodeEmail } from "api/clinic/rests";
import { checkPassword } from "utils/formatter";

const ClinicFindPasswordPage = () => {
  const navigate = useNavigate();
  const [isSend, setIsSend] = useState(false);
  const [email, setEmail] = useState("");
  const [authCode, setAuthCode] = useState<string>("");
  const [memberId, setMemberId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rePassword, setRePassword] = useState<string>("");

  const getPassAuth = () => {
    if (email.trim() === "") {
      alert("이메일을 입력해주세요.");
      return;
    }
    getAuthCodeEmail(email, memberId)
      .then((_) => {
        setIsSend(true);
      })
      .catch((err) => {
        if (err) {
          alert(
            `일치하는 계정을 찾을 수 없습니다. 입력하신 내용을 다시 확인해주세요.`
          );
        }
      });
  };
  const reset = async () => {
    if (!memberId) {
      alert("아이디를 입력해주세요.");
      return;
    }

    if (!checkPassword(password)) {
      alert("8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.");
      return;
    }

    if (password !== rePassword) {
      alert("비밀번호가 맞지않습니다.");
      return;
    }

    try {
      await resetPassword(memberId, password, authCode);
      alert("비밀번호가 변경되었습니다.\n로그인페이지로 이동합니다.");
      navigate(`${LOGIN_PATH}`);
    } catch (e: any) {
      const data = e.response.data;
      if (data) {
        if (/Member id/.test(data.data)) {
          alert("아이디 혹은 인증코드를 다시 확인해주세요.");
        }
      }
    }
  };

  return (
    <Layout extraClass="exclude-menu bg-grey" excludeMenu={true}>
      <Banner type="SIGNUP" />

      <section className="join">
        <div className="inner wrap">
          <div className="join-form find-form">
            <div className="form-box">
              <div className="form-tit-box">병원회원 비밀번호 찾기</div>

              <div className="form-con-box">
                <div className="row">
                  <div className="input-box">
                    <input
                      type="text"
                      placeholder="이메일"
                      value={email}
                      onChange={(e) =>
                        setEmail(
                          e.target.value.replace(
                            /[^ㄱ-ㅎㅏ-ㅣ가-힣\w-\d@.]/,
                            ""
                          )
                        )
                      }
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="input-box">
                    <input
                      type="text"
                      placeholder="아이디"
                      value={memberId}
                      onChange={(e) => setMemberId(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-box type-find">
              <div className="submit-box">
                <button
                  className="submit-btn"
                  onClick={() => {
                    if (!isSend) {
                      getPassAuth();
                    }
                  }}
                  disabled={isSend}
                >
                  {isSend ? "인증코드 발송됨" : "인증코드 발송"}
                </button>
              </div>
            </div>

            {isSend && (
              <>
                <div className="form-box">
                  <div className="form-con-box">
                    <div className="row">
                      <div className="input-msg-box type-success">
                        인증코드를 전송했습니다. 새로운 비밀번호와 인증코드를
                        입력해주세요
                      </div>
                    </div>

                    <div className="row">
                      <div className="input-box">
                        <input
                          type="text"
                          placeholder="인증코드"
                          value={authCode}
                          onChange={(e) => setAuthCode(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-box">
                  <div className="form-tit-box">새 비밀번호 입력</div>
                  <div className="form-con-box">
                    <div className="row">
                      <div className="input-box">
                        <input
                          type="password"
                          placeholder="새 비밀번호를 입력해주세요"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      {!checkPassword(password) ? (
                        <div className="input-msg-box">
                          비밀번호는 영문, 숫자, 특수문자 조합(따옴표, 부등호, 물음표 제외)으로 무조건 한개
                          이상 포함하여, 8 ~ 16자리까지 가능합니다.
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="row">
                      <div className="input-box">
                        <input
                          type="password"
                          placeholder="비밀번호를 한번 더 입력해주세요"
                          value={rePassword}
                          onChange={(e) => setRePassword(e.target.value)}
                        />
                      </div>
                      {password !== rePassword ? (
                        <div className="input-msg-box">
                          비밀번호가 일치하지 않습니다
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-box type-find">
                  <div className="form-con-box">
                    <div className="menu-box">
                      <div className="menu-btn" onClick={reset}>
                        완료
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default ClinicFindPasswordPage;
