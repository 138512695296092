import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "layouts";
import {
  CareerBox,
  CoverLetterBox,
  EducationBox,
  JobBox,
  LicenseBox,
  ProfileBox,
  RecommendResume,
  TitleBox,
} from "components/Resume";
import { useUserContext } from "contexts/UserContext";
import useResume from "hooks/resume/useResume";
import { USER_TYPE } from "utils/enums";
import OfferPopup from "components/Popups/OfferPopup";
import PrintPopup from "components/Popups/PrintPopup";
import { LOGIN_PATH, MAIN_PATH } from "routes";

const useResumePage = () => {
  const { resumeId } = useParams();
  const resume = useResume(Number(resumeId) || -1);
  const [showOfferPopup, setShowOfferPopup] = useState<boolean>(false);
  const [showPrintPopup, setShowPrintPopup] = useState<boolean>(false);

  return {
    resume,
    showOfferPopup,
    showPrintPopup,
    handler: {
      print: () => setShowPrintPopup(true),
      propose: () => setShowOfferPopup(true),
      closeOfferPopup: () => setShowOfferPopup(false),
      closePrintPopup: () => setShowPrintPopup(false),
    },
  };
};
const ResumePage = () => {
  const navigate = useNavigate();
  const { isSignIn, userType } = useUserContext();

  const {
    resume,
    showOfferPopup,
    showPrintPopup,
    handler: { print, propose, closeOfferPopup, closePrintPopup },
  } = useResumePage();

  useEffect(() => {
    if (!isSignIn) {
      navigate(LOGIN_PATH);
    }
    if (userType === USER_TYPE.개인회원) {
      alert("개인회원은 접근할 수 없습니다.");
      navigate(`${MAIN_PATH}`);
    }
  }, [isSignIn, navigate, userType]);

  const getResumeSection = (printVer?: boolean) => (
    <section className="resume-view">
      <div className="inner wrap">
        <div className="resume-view-layout">
          <div className="main-layout-box">
            <TitleBox
              title={resume?.title || ""}
              showPropose={userType === USER_TYPE.병원회원}
              hideActions={printVer}
              onPrint={print}
              onPropose={propose}
            />

            <ProfileBox
              name={resume?.name || ""}
              birth_date={resume?.birth_date || ""}
              image_filename={resume?.image_filename || ""}
              email={resume?.email || "비공개"}
              phone_number={resume?.phone_number || "비공개"}
              address={resume?.address || ""}
            />

            <div className="resume-data-box">
              <JobBox
                occupation_list={resume?.occupation_list || []}
                work_list={resume?.work_list || []}
                work_type_list={resume?.work_type_list || []}
                salary_info={resume?.salary_info || null}
              />
              <EducationBox
                last_education_type={resume?.last_education_type || ""}
                last_education_name={resume?.last_education_name || ""}
                last_education_start_date={
                  resume?.last_education_start_date || ""
                }
                last_education_end_date={resume?.last_education_end_date || ""}
              />
              <CareerBox
                work_experience_month={resume?.work_experience_month || 0}
                work_experience_list={resume?.work_experience_list || []}
              />
              <LicenseBox license_list={resume?.license_list || []} />

              <div className="resume-data-group">
                <div className="data-tit-box">치과인 이수 교육</div>

                <div className="data-list-box">
                  <div className="row">
                    <div className="subject"></div>
                    <div className="con"></div>
                  </div>
                  {/* <div className="row">
                <div className="subject">교육명</div>
                <div className="con">교육명이 들어가는 자리입니다.</div>
              </div> */}
                </div>
              </div>

              <CoverLetterBox cover_letter={resume?.cover_letter || ""} />

              {printVer ? (
                <></>
              ) : (
                <div className="resume-menu-box">
                  <div className="menu-btn web type-1" onClick={print}>
                    <span className="txt">인쇄하기</span>
                    <i className="ic ic-print"></i>
                  </div>
                  {userType === USER_TYPE.병원회원 && (
                    <div
                      className="menu-btn type-2"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        propose();
                      }}
                    >
                      <span className="txt">면접 제안</span>
                    </div>
                  )}

                  <button
                    className="menu-btn type-3"
                    onClick={() => navigate(-1)}
                  >
                    <span className="txt">목록보기</span>
                  </button>
                </div>
              )}
            </div>
          </div>
          {printVer ? (
            <></>
          ) : (
            <div className="sub-layout-box">
              {userType === USER_TYPE.병원회원 && <RecommendResume />}
            </div>
          )}
        </div>
      </div>
    </section>
  );
  if (userType === USER_TYPE.개인회원) return null;
  return (
    <>
      <Layout>{getResumeSection()}</Layout>

      {showOfferPopup && (
        <OfferPopup
          resumeId={resume?.resume_id || 0}
          handleClose={closeOfferPopup}
        />
      )}
      {showPrintPopup && (
        <PrintPopup
          title={resume?.title || "이력서 출력"}
          handleClose={closePrintPopup}
        >
          {getResumeSection(true)}
        </PrintPopup>
      )}
    </>
  );
};
export default ResumePage;
