import { useEffect, useState } from "react";
import { RESUME_FORM_PATH } from "routes";

import { IResumeGroupView } from "api/member/types";
import {
  addResumeGroup as _addResumeGroup,
  deleteResumeGroup as _deleteResumeGroup,
  updateResumeGroup,
} from "api/member/rests";
import useResumeGroups from "hooks/resume/useResumeGroups";

import { ResumeGroupDetail } from "components/ResumeGroup";
import { GroupNameInputField } from "components/ResumeGroup/Comps";
import { ResumeGroup } from "components/ResumeGroup";
import HeadBox from "components/MyPage/HeadBox";
import { PageTitle } from "components/MyPage";

enum MODE {
  LIST = "LIST",
  EDIT = "EDIT",
  DETAIL = "DETAIL",
}

const ResumeGroupPage = () => {
  const { resumeGroups, handler: fetch } = useResumeGroups();
  const [mode, setMode] = useState<MODE>(MODE.LIST);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [newResumeGroup, setNewResumeGroup] = useState<IResumeGroupView | null>(
    null
  );

  const isDetailMode = mode === MODE.DETAIL;
  const isEditMode = mode === MODE.EDIT;
  const isListMode = mode === MODE.LIST;

  const changeListMode = () => {
    setSelectedId(-1);
    setMode(MODE.LIST);
  };
  const changeEditMode = async () => {
    setNewResumeGroup(null);
    setMode(MODE.EDIT);
    await fetch();
  };
  const showGroupDetail = (id: number) => setSelectedId(id);

  const addResumeGroup = async () => {
    const { data } = await _addResumeGroup({
      group_name: `이력서 그룹${resumeGroups.length + 1}`,
    });
    setNewResumeGroup(data as IResumeGroupView);
  };

  const changeNewResumeGroup = async () => {
    if (!newResumeGroup) return;

    await updateResumeGroup(newResumeGroup?.group_id, {
      group_name: newResumeGroup?.group_name,
    });
    setNewResumeGroup(null);
    await fetch();
  };

  const deleteResumeGroups = async () => {
    await Promise.all(selectedIds.map((id) => _deleteResumeGroup(id)));
    await fetch();
    setMode(MODE.LIST);
  };

  useEffect(() => {
    if (selectedId > 0) {
      setMode(MODE.DETAIL);
    }
  }, [selectedId]);

  const selectResumeGroup = (groupId: number) => {
    let newValue: number[] = [];

    if (selectedIds.includes(groupId)) {
      newValue = selectedIds.filter((item) => item !== groupId);
    } else {
      newValue = [...selectedIds, groupId];
    }

    setSelectedIds(newValue);
  };

  useEffect(() => {
    newResumeGroup ? setDisabled(false) : setDisabled(true);
  }, [newResumeGroup]);

  return (
    <section className="my-resume-group">
      <article className="lnb-contents-tit">
        <PageTitle iconClass="ic-resume" title="이력서 관리" />

        <div className="option-box">
          <a href={`${RESUME_FORM_PATH}`} className="option-btn type1">
            이력서 작성하기
          </a>
        </div>
      </article>

      {isDetailMode ? (
        <ResumeGroupDetail groupId={selectedId} />
      ) : (
        <>
          <HeadBox
            count={resumeGroups.length}
            countUnit="그룹"
            actionComp={
              isEditMode ? (
                <button
                  className="edit-btn type-2 web"
                  onClick={deleteResumeGroups}
                >
                  <span className="txt">삭제</span>
                  <i className="ic ic-remove"></i>
                </button>
              ) : (
                <button
                  className="edit-btn type-2 web"
                  onClick={changeEditMode}
                >
                  <span className="txt">편집</span>
                </button>
              )
            }
          />

          <div className="group-body-box">
            <div className="list-wrap">
              <ul>
                {resumeGroups.map((group) => (
                  <li key={group.group_id}>
                    <ResumeGroup
                      key={group.group_id}
                      resumeGroup={group}
                      isEditMode={isEditMode}
                      onClickCheckbox={() => selectResumeGroup(group.group_id)}
                      onClickDetail={() =>
                        group.resume_count > 0 &&
                        showGroupDetail(group.group_id)
                      }
                      refresh={fetch}
                    />
                  </li>
                ))}

                {newResumeGroup && (
                  <li>
                    <div className="item-box">
                      <div className="info-box edit-on">
                        <div className="toggle-input-box">
                          {/* <input
                            type="text"
                            className="name-input-box"
                            style={{ display: "block" }}
                            value={newResumeGroup.group_name}
                            onChange={(e) =>
                              setNewResumeGroup({
                                ...newResumeGroup,
                                group_name: e.target.value,
                              })
                            }
                          /> */}
                          <GroupNameInputField
                            value={newResumeGroup.group_name}
                            onChange={(name) =>
                              setNewResumeGroup({
                                ...newResumeGroup,
                                group_name: name,
                              })
                            }
                            onEnter={changeNewResumeGroup}
                          />
                          <a
                            href="#none"
                            className="edit-toggle-btn"
                            onClick={changeNewResumeGroup}
                          ></a>
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </>
      )}

      {isListMode && (
        <div className="group-foot-box">
          <button
            className="group-add-btn"
            style={{ backgroundColor: "#fff" }}
            onClick={addResumeGroup}
            disabled={!disabled}
          >
            <i className="ic ic-add"></i>
            <span className="txt">그룹 추가하기</span>
          </button>
        </div>
      )}
      {isEditMode && (
        <div className="group-foot-box">
          <button
            className="group-add-btn"
            style={{ backgroundColor: "#fff" }}
            onClick={changeListMode}
          >
            <span className="txt">수정완료</span>
          </button>
        </div>
      )}
    </section>
  );
};

export default ResumeGroupPage;
