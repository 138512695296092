const LoginButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="login-submit-box">
      <button className="login-submit-btn" onClick={onClick}>
        로그인
      </button>
      {/* <a>태그를 <button>태그로 대체 */}
    </div>
  );
};

export default LoginButton;
