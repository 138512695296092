import * as echarts from "echarts/core";
import { PieChart } from "echarts/charts";
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import BasicCharts, { getColor } from "./BasicChart";
import { Card, Col, Row } from "react-bootstrap";
import Flex from "./Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
]);

interface pieData {
  id: number;
  value: number;
  name: string;
  color: string;
}

const radius = ["100%", "55%"];
const getOptions = (data: pieData[], radius: string[]) => ({
  color: data.map((item) => getColor(item.color)),

  tooltip: {
    padding: [7, 10],
    transitionDuration: 0,
    backgroundColor: getColor("gray-100"),
    borderColor: getColor("gray-300"),
    textStyle: { color: getColor("dark") },
    borderWidth: 1,
    formatter: (params: any) =>
      `<strong>${params.data.name}:</strong> ${params.percent}%`,
  },
  series: [
    {
      name: "",
      type: "pie",
      radius,
      avoidLabelOverlap: false,
      emphasis: {
        scale: false,
      },
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor("gray-100"),
      },
      label: {
        show: true,
        position: "center",
        formatter: "{a}",
        fontSize: 23,
        color: getColor("dark"),
      },
      data,
    },
  ],
});

const MarketShareItem = ({
  item,
  index,
  total,
}: {
  item: pieData;
  index: number;
  total: number;
}) => {
  const { name, color, value } = item;

  const percentage = ((value * 100) / total).toFixed(0);
  return (
    <Flex
      alignItems="center"
      justifyContent="between"
      className={`fw-semi-bold fs--2 ${index == 0 && "mt-3"}`}
    >
      <p className="mb-1">
        <FontAwesomeIcon
          icon="circle"
          className={`me-2 text-${color.split("-")[1] || color || "text-info"}`}
        />
        {name}
      </p>
      <div className="d-xxl-none">{value} 명</div>
    </Flex>
  );
};

const data: pieData[] = [
  { id: 1, value: 530, name: "병원회원", color: "primary" },
  { id: 2, value: 190, name: "치과위생사", color: "info" },
  { id: 3, value: 200, name: "치과의사", color: "warning" },
  { id: 4, value: 50, name: "일반인", color: "success" },
];

const MemberTypeStat = () => {
  const total = data.reduce((acc, val) => val.value + acc, 0);
  return (
    <Card className="h-md-100">
      <Card.Body>
        <Row className="justify-content-between g-0">
          <Col xs={5} sm={6} xxl className="pe-2">
            <h6 className="mt-1">회원 비율</h6>

            {data.map((item, index) => (
              <MarketShareItem
                item={item}
                index={index}
                key={index}
                total={total}
              />
            ))}
          </Col>
          <Col xs="auto">
            <div className="ps-0">
              <BasicCharts
                echarts={echarts}
                options={getOptions(data, radius)}
                style={{ width: "6.625rem", height: "6.625rem" }}
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default MemberTypeStat;
