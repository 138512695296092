import { Badge, Card } from "react-bootstrap";
import BasicCharts, { getColor, rgbaColor } from "./BasicChart";
import Flex from "./Flex";

import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
]);

const getOptions = (data: number[]) => ({
  tooltip: {
    trigger: "axis",
    formatter: "<strong>{b0}</strong> : {c0}",
  },
  xAxis: {
    data: ["3주 전", "2주 전", "1주 전", "이번 주"],
  },
  series: [
    {
      type: "line",
      data,
      smooth: true,
      lineStyle: {
        width: 3,
      },

      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor("#2c7be5", 0.25),
            },
            {
              offset: 1,
              color: rgbaColor("#2c7be5", 0),
            },
          ],
        },
      },
    },
  ],
  grid: {
    bottom: "2%",
    top: "2%",
    right: "10px",
    left: "10px",
  },
});

const WithdrawMemberStat = () => {
  const stat = [40, 10, 15, 22];

  return (
    <Card className="h-md-100">
      <Card.Header
        className="pb-0"
        style={{ borderBottom: "none", backgroundColor: "Background" }}
      >
        <h6 className="mb-0 mt-2">탈퇴회원 수</h6>
      </Card.Header>
      <Card.Body
        as={Flex}
        alignItems="end"
        justifyContent="between"
        className="pt-0"
      >
        <div>
          <h2 className="fw-normal text-700 mb-1 lh-1">
            {stat.reduce((prev, item) => prev + item, 0)} 명
          </h2>
          <Badge
            pill
            bg="200"
            className="text-primary fs--2"
            style={{
              backgroundColor: "rgb(237,242,249)",
              borderRadius: "50rem!important",
            }}
          >
            {((stat.at(-1)! / stat.at(-2)! - 1) * 100).toFixed(2)}%
          </Badge>
        </div>
        <div className="ps-0">
          <BasicCharts
            echarts={echarts}
            options={getOptions([40, 10, 15, 22])}
            style={{ width: "8.5rem", height: 90 }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default WithdrawMemberStat;
