import { useCallback, useEffect, useState } from "react";
import { Button, Table, Pagination } from "react-bootstrap";

import { deleteLecture, fetchEducations } from "api/admin/rests";
import { ILectureView } from "api/education/types";
import { lectureTypeToLabel } from "utils/formatter";
import { useLocation } from "react-router-dom";

import OralHealthLecture from "./OralHealthLecture";

const LECTURE_TYPE = "HEALTH";

const OralHealthLectureTable = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [courses, setCourses] = useState<ILectureView[]>([]);
  

  const [showCourse, setShowCourse] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] =
    useState<ILectureView | null>(null);

  const location = useLocation();
  const reg =  new RegExp(/[^0-9]+/, "gi");
  const lectureId =  +location.pathname.replace(reg ,"")

  const getEducations = useCallback(async () => {
    const {
      data: { element_list, total_page },
    } = await fetchEducations(LECTURE_TYPE, page);
    
    const lectureList = element_list.filter((lecture) => lecture.course_id === lectureId)
    console.log(lectureList)
    setCourses(lectureList[0].lecture_list);
    setTotalPage(total_page);

  }, [page]);

  const removeEducationLecture = async (lecture_id : number) => {
  
    const data = await deleteLecture(lecture_id);
    console.log(data);

  }

  useEffect(() => {
    (async () => {
      getEducations();
    })();
  }, [getEducations]);


  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={() => setShowCourse(true)}>강의 등록</Button>
      </div>

      <div style={{ minHeight: "750px" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>강의 ID</th>
              <th>제작자</th>
              <th>제목</th>
              <th>순서</th>
              <th>영상 아이디</th>
              <th>재생 시간</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {courses.map((course) => (
              <tr key={course.lecture_id}>
                <td>{course.lecture_id}</td>
                <td>{lectureTypeToLabel(course.creator)}</td>
                <td>{course.name}</td>
                <td>{course.seq}</td>
                <td>{course.video_id}</td>
                <td>{course.play_time}</td>
                <td style={{display: "flex", gap: "6px"}}>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      setSelectedCourse(course);
                      setShowCourse(true);
                    }}
                  >
                    편집
                  </Button>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      removeEducationLecture(course.lecture_id);
                      getEducations();
                    }}
                  >
                    삭제
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination>
          {[...Array(totalPage).keys()].map((pageIdx) => (
            <Pagination.Item
              key={pageIdx + 1}
              active={pageIdx + 1 === page}
              onClick={() => setPage(pageIdx + 1)}
            >
              {pageIdx + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>

      <OralHealthLecture
        lectureId={lectureId}
        show={showCourse}
        course={selectedCourse}
        onClose={() => {
          setSelectedCourse(null);
          setShowCourse(false);
          getEducations();
        }}
      />
    </>
  );
};
export default OralHealthLectureTable;
