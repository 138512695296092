import { ILectureCourseOverview } from "api/education/types";
import { resourcePath, lectureBaseurl } from "utils/formatter";

const LectureOverview = ({ lecture }: { lecture: ILectureCourseOverview }) => (
  <li className="swiper-wrapper">
    <div className="item-wrap">
      <a
        href={`${lectureBaseurl(lecture.lecture_type)}/${lecture.course_id}`}
        className="item-box"
      >
        {lecture.image_filename ? (
          <div className="img-box">
            <img src={resourcePath(lecture.image_filename)} alt="이미지" />
          </div>
        ) : (
          <div className="no-picture">
            <div className="name">
              {lecture.title.substring(0, 30)}
              {lecture.title.length >= 30 && "..."}
            </div>
          </div>
        )}
        <div className="info-box">
          <div className="subject-box">{lecture.title}</div>
          <div className="con-box">{lecture.description}편</div>

          <div className="label-group">
            <div className="label-list">
              {lecture.lecture_type === "DENTIN" && lecture.score > 0 && (
                <div className="label-box type-1">
                  이수점수 {lecture.score}점
                </div>
              )}

              <div className="label-box type-2">
                총 {lecture.lecture_count}강
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </li>
);
export default LectureOverview;
