import { useMemo, useState } from "react";

import MobileHeadBox from "components/MobileHeadBox";
import CheckPassword from "./CheckPassword";
import ChangePassword from "./ChangePassword";
import ChangeSuccess from "./ChangeSuccess";
import usePassword from "hooks/usePassword";

const PasswordPopup = ({ handleClose }: { handleClose: () => void }) => {
  const {
    isConfirmed,
    isUpdated,
    password,
    handler: { setPassword, checkPassword, updatePassword },
  } = usePassword();

  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
  const [newPassword, setNewPassword] = useState<string>("");
  const [rePassword, setRePassword] = useState<string>("");

  const changePassword = async () => {
    console.log(newPassword, rePassword);
    if (newPassword === rePassword) {
      setIsValid(true);
      await updatePassword(newPassword);
    } else {
      setIsValid(false);
    }
  };

  const extraClass = useMemo(
    () =>
      isConfirmed !== true
        ? "popup-password-check"
        : isUpdated === true
        ? "popup-password-success"
        : "popup-password-change",
    [isConfirmed, isUpdated]
  );

  return (
    <article className={`popup ${extraClass}`} style={{ display: "block" }}>
      <div className="popup-wrap">
        <div className="popup-head">
          <MobileHeadBox popup title="비밀번호 수정" onClose={handleClose} />

          <button className="close-btn" onClick={handleClose}></button>
        </div>

        <div className="popup-body">
          {isConfirmed !== true && (
            <CheckPassword
              isConfirmed={isConfirmed}
              password={password}
              onChange={setPassword}
              onConfirm={checkPassword}
            />
          )}
          {isConfirmed === true && isUpdated === undefined && (
            <ChangePassword
              isValid={isValid}
              newPassword={newPassword}
              rePassword={rePassword}
              setNewPassword={setNewPassword}
              setRePassword={setRePassword}
            />
          )}
          {isConfirmed === true && isUpdated === true && <ChangeSuccess />}
        </div>

        <div className={`popup-foot ${isConfirmed !== true ? "mobile" : ""}`}>
          <div className="foot-menu-box">
            {isConfirmed !== true ? (
              <button className="foot-menu-btn type-1" onClick={checkPassword}>
                입력
              </button>
            ) : isUpdated === true ? (
              <button className="foot-menu-btn type-1" onClick={handleClose}>
                완료
              </button>
            ) : (
              <button className="foot-menu-btn type-1" onClick={changePassword}>
                완료
              </button>
            )}
          </div>
        </div>
      </div>
    </article>
  );
};
export default PasswordPopup;
