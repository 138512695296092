import {useCallback} from "react";
import { unionBy } from "lodash";
import { nanoid } from "nanoid";

/**
 * TODO: refactoring
 */
import { useEffect, useMemo, useState } from "react";
import {
  fetchArea,
  fetchWorkarea,
  createWorkarea,
  createWorkareas,
  updateWorkarea,
} from "api/area/rests";
import { IWorkAreaView, IMemberWorkAreaView } from "api/area/types";

export type TArea = IWorkAreaView & { id?: string };
export interface IWorkArea {
  city: string;
  areas: TArea[];
}

export const useWorkAreaOptions = () => {
  const [area, setArea] = useState<TArea[]>([]);
  const [selectedCity, setSelectedCity] = useState<string>("");

  const _pickByCity = useCallback((city: string) =>
    area.filter((area) => area.city === city), [area]);

  const cities = useMemo(
    () => unionBy(area, "city").map((area) => area.city),
    [area]
  );

  const filteredAreas = useMemo(
    () => _pickByCity(selectedCity),
    [_pickByCity, selectedCity]
  );

  const workAreas: IWorkArea[] = cities.map((city) =>
    Object.assign({ city: city, areas: _pickByCity(city) })
  );

  const getArea = (id: string) => area.find((a) => a.id === id) || null;

  useEffect(() => {
    (async () => {
      const { data } = await fetchArea();
      setArea(data.map((d) => ({ ...d, id: nanoid() })));
    })();
  }, []);

  return {
    /**
     * 희망근무지 선택옵션
     */
    cities,
    filteredAreas,
    selectedCity,
    workAreas,
    handler: {
      getArea,
      selectCity: (city: string) => setSelectedCity(city),
    },
  };
};

const useWorkArea = () => {
  const [memberWorkAreas, setMemberWorkAreas] = useState<IMemberWorkAreaView[]>(
    []
  );
  const [firstSeq, setFirstSeq] = useState<TArea | null>(null);
  const [secondSeq, setSecondSeq] = useState<TArea | null>(null);

  const prevFirstSeq = useMemo(
    () => memberWorkAreas.find((d) => d.seq === 1),
    [memberWorkAreas]
  );
  const prevSecondSeq = useMemo(
    () => memberWorkAreas.find((d) => d.seq === 2),
    [memberWorkAreas]
  );

  const _createOrUpdate = async (seq: number) => {
    const prevArea = seq === 1 ? prevFirstSeq : prevSecondSeq;
    const area = seq === 1 ? firstSeq : secondSeq;

    if (!area) return;
    if (prevArea === undefined) {
      await createWorkarea(seq, area);
    } else {
      await updateWorkarea(seq, area);
    }
  };

  const register = async () => {
    const params = [];
    if (firstSeq) {
      const { id, ...rest } = firstSeq;
      params.push({ seq: 1, ...rest });
    }
    if (secondSeq) {
      const { id, ...rest } = secondSeq;
      params.push({ seq: 2, ...rest });
    }

    if (params.length === 0) return;
    await createWorkareas({ work_area_list: params });
  };

  useEffect(() => setFirstSeq(prevFirstSeq || null), [prevFirstSeq]);
  useEffect(() => setSecondSeq(prevSecondSeq || null), [prevSecondSeq]);

  useEffect(() => {
    (async () => {
      const { data } = await fetchWorkarea();
      setMemberWorkAreas(data);
    })();
  }, []);

  return {
    /**
     * 1순위 희망근무지역
     */
    firstSeq,
    /**
     * 2순위 희망근무지역
     */
    secondSeq,
    handler: {
      setFirstSeq: (area: TArea | null) => setFirstSeq(area),
      setSecondSeq: (area: TArea | null) => setSecondSeq(area),
      updateFirstSeq: async () =>
        firstSeq !== null && (await _createOrUpdate(1)),
      updateSecondSeq: async () =>
        secondSeq !== null && (await _createOrUpdate(2)),
      register,
    },
  };
};
export default useWorkArea;
