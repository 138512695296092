import { IResumeView } from "api/resume/types";
import { InfoRow } from "./Comps";

import { salaryTypeText, taxTypeText, salaryText } from "utils/formatter";

type TProps = Pick<
  IResumeView,
  "occupation_list" | "work_list" | "work_type_list" | "salary_info"
>;

const JobBox = ({
  occupation_list,
  work_list,
  work_type_list,
  salary_info,
}: TProps) => (
  <div className="resume-data-group m-first-type">
    <div className="data-list-box">
      <InfoRow label="희망직종" value={occupation_list.join(", ")} />
      <InfoRow label="희망업무" value={work_list.join(", ")} />
      <InfoRow
        label={
          <>
            <span className="web">희망</span> 근무형태
          </>
        }
        value={work_type_list.join(", ")}
      />
      <InfoRow
        label="희망연봉"
        value={
          // NEGOTIATION
          salary_info
            ? `${salaryText(salary_info)} 
            `
            : "면접시 협의"
        }
      />
    </div>
  </div>
);
export default JobBox;
