import { useUserContext } from "contexts/UserContext";

import { USER_TYPE } from "utils/enums";
import ClinicProfile from "./ClinicProfile";
import MemberProfile from "./MemberProfile";

const ProfilePage = () => {
  const { userType } = useUserContext();

  return (
    <section className="my-setting">
      <article className="lnb-contents-tit">
        <div className="tit-box">
          <i className="ic ic-setting"></i>
          <span className="txt">회원정보 관리</span>
        </div>
      </article>

      {userType === USER_TYPE.개인회원 ? <MemberProfile /> : <ClinicProfile />}
    </section>
  );
};

export default ProfilePage;
