/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useNavigate } from "react-router-dom";

const DetailButton = ({
  resumeCount,
  onClick,
}: {
  resumeCount: number;
  onClick: () => void;
}) => {
  const navigate = useNavigate();

  const noResume = (resume: number) => {
    resume === 0 ? navigate("/resume-form") : onClick();
  };
  return (
    <div className="menu-box">
      <button className="menu-btn" onClick={() => noResume(resumeCount)}>
        <span className="txt">
          <b>{resumeCount}</b>건의 이력서
        </span>
        <i className="ic ic-link"></i>
      </button>
    </div>
  );
};

export default DetailButton;
