import { useEffect, useState } from "react";

import { fetchBanner } from "api/banner/rests";
import { IBannerView, TBannerType } from "api/banner/types";

const useBanners = (bannerType: TBannerType) => {
  const [banners, setBanners] = useState<IBannerView[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await fetchBanner(bannerType);
      setBanners(data);
    })();
  }, []);

  return banners;
};
export default useBanners;
