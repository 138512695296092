import { useNavigate } from "react-router-dom";

import { JOBPOSTING_FORM_PATH } from "routes";
import { fetchJobPostingCount } from "api/clinic/rests";

const LinkCreateJobPostingButton = () => {
  const navigate = useNavigate();
  const handleClick = async () => {
    try {
      const {
        data: { current_count, max_count },
      } = await fetchJobPostingCount(false);
      if (current_count >= max_count) {
        alert(`노출 가능한 공고는 최대 ${max_count}개입니다.`);
        return;
      } else {
        navigate(`${JOBPOSTING_FORM_PATH}`);
      }
    } catch (e) {}
  };

  return (
    <div className="option-box">
      <div className="option-btn type1" onClick={handleClick}>
        공고 등록
      </div>
    </div>
  );
};

export default LinkCreateJobPostingButton;
