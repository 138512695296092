import MobileHeadBox from "components/MobileHeadBox";

import useOccupation from "hooks/useOccupation";

const Option = ({
  label,
  isSelected = false,
  onClick = () => {},
}: {
  label: string;
  isSelected?: boolean;
  onClick?: () => void;
}) => (
  <li>
    <div className="item-wrap">
      <a
        className={`item-btn ${isSelected ? "on" : ""}`}
        onClick={onClick}
        role="button"
      >
        {label}
      </a>
    </div>
  </li>
);

const OccupationSelector = ({
  selected,
  onClose,
  onSelect,
}: {
  selected: string[];
  onClose: () => void;
  onSelect: (query: string) => void;
}) => {
  const { occupations } = useOccupation();

  return (
    <article
      className="info-search-group info-search-job"
      data-tabs="job"
      style={{ display: "block" }}
    >
      <div className="search-wrap">
        <MobileHeadBox popup onClose={onClose} />

        <div className="job-wrap">
          <div className="list-wrap">
            <ul>
              {occupations.map((occupation) => {
                const isSelected = selected.includes(
                  `o|${occupation.description}`
                );
                return (
                  <Option
                    key={occupation.code_id}
                    label={occupation.description}
                    isSelected={isSelected}
                    onClick={() => {
                      !isSelected && onSelect(occupation.description);
                    }}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </article>
  );
};
export default OccupationSelector;
