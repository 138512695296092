import { useParams } from "react-router-dom";
import { LectureTable } from "components/Lecture";
import useLecture from "hooks/lecture/useLecture";

import {
  bookmarkLecture as bookmarkMemberLecture,
  removeBookmarkLecture as removeBookmarkMemberLecture,
} from "api/member/rests";
import {
  bookmarkLecture as bookmarkClinicLecture,
  removeBookmarkLecture as removeBookmarkClinicLecture,
} from "api/clinic/rests";
import { useBookmarkContext } from "contexts/BookmarkContext";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";
import { lectureTypeToLabel, lectureBaseurl } from "utils/formatter";

const LecturePage = () => {
  const {
    bookmarks,
    handler: { addBookmark, removeBookmark },
  } = useBookmarkContext();
  const { userType } = useUserContext();
  const { courseId } = useParams();
  const lecture = useLecture(Number(courseId));

  const isBookmarked = bookmarks
    .map((b) => b.course_id)
    .includes(lecture?.course_id || 0);

  const bookmarkOrUnset = async () => {
    if (lecture) {
      if (isBookmarked) {
        userType === USER_TYPE.개인회원
          ? await removeBookmarkMemberLecture(lecture.course_id)
          : await removeBookmarkClinicLecture(lecture.course_id);
        removeBookmark(lecture.course_id);
      } else {
        const { data } =
          userType === USER_TYPE.개인회원
            ? await bookmarkMemberLecture(lecture.course_id)
            : await bookmarkClinicLecture(lecture.course_id);
        addBookmark(data);
      }
    }
  };

  return (
    <section className="edu-view">
      <div className="edu-view-tit-box">
        <div className="tit-box">
          {lectureTypeToLabel(lecture?.lecture_type || "")}
        </div>

        <div className="main-box">
          <div className="subject-box">
            <div className="subject-txt">{lecture?.title}</div>
            {lecture?.lecture_type === "DENTIN" && lecture?.score > 0 && (
              <div className="label-txt type-1 web">
                이수점수 {lecture?.score}점
              </div>
            )}
          </div>

          <div className="option-box">
            {lecture?.lecture_type === "DENTIN" && lecture?.score > 0 && (
              <div className="label-txt type-1 mobile">
                이수점수 {lecture?.score}점
              </div>
            )}

            <div
              className={`favorite-btn ${isBookmarked ? "on" : ""}`}
              onClick={bookmarkOrUnset}
            >
              <i className="ic ic-favorite"></i>
              <span className="txt">즐겨찾기</span>
              <span className="txt on-txt">중</span>
            </div>
          </div>
        </div>

        <div className="sub-box">연자: {lecture?.creator}</div>
      </div>

      <div className="edu-view-introduce-box">
        <div className="tit-box">강좌소개</div>
        <div className="con-box">{lecture?.description}</div>
      </div>

      <LectureTable
        courseId={Number(courseId)}
        courseTitle={lecture?.title}
        lectureType={lecture?.lecture_type || 'ETC'}
        lectureBaseUrl={`${lectureBaseurl(
          lecture?.lecture_type || ""
        )}/${courseId}`}
        lectures={lecture?.lecture_list || []}
        hideIssueButton={lecture?.lecture_type !== "DENTIN"}
      />
    </section>
  );
};
export default LecturePage;
