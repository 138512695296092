import { createContext, useContext } from "react";

import { USER_TYPE } from "utils/enums";

interface IUserContext {
  isSignIn: boolean;
  name: string;
  memberId: string;
  userType: USER_TYPE;
  clinicType:string;
  occupation: string;
}

const UserContext = createContext<IUserContext | null>(null);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(
      "useUserContext must be used within a UserContext.Provider"
    );
  }

  return context;
};
export default UserContext;
