import {
  MYPAGE_PATH,
  MYPATH_SUB_PATH,
  MESSAGE_PATH,
  SEARCH_PATH,
} from "routes";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";
import AlarmBox from "./commons/Header/AlarmBox";

const MobileHeadBox = ({
  title = "",
  popup,
  headerRightSideText = "",
  onClose,
}: {
  title?: string;
  popup?: boolean;
  headerRightSideText?: string;
  onClose: () => void;
}) => {
  const { userType } = useUserContext();
  return (
    <article className="mobile-head-box">
      <div className="mobile-head-txt">{title}</div>

      {popup && headerRightSideText && (
        <button className="area-select-submit-btn" onClick={onClose}>
          {headerRightSideText}
        </button>
      )}

      {!popup && (
        <div className="header-menu">
          <div className="menu-box mobile">
            <a href={`${SEARCH_PATH}`} className="search-btn"></a>
          </div>

          <div className="menu-box">
            <a href={`${MESSAGE_PATH}`} className="msg-btn on"></a>
          </div>

          <AlarmBox />

          <div className="menu-box">
            <a
              href={`${MYPAGE_PATH}/${
                userType === USER_TYPE.개인회원
                  ? MYPATH_SUB_PATH.RESUME_GROUP
                  : MYPATH_SUB_PATH.JOBPOSTING
              }`}
              className="my-btn"
            ></a>
          </div>
        </div>
      )}
      <div className="close-btn" onClick={onClose}></div>
    </article>
  );
};
export default MobileHeadBox;
