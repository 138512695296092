import { Breadcrumb, Tab, Tabs } from "react-bootstrap";

import CourseTable from "components/Admin/CourseTable";
import CourseNoticeTable from "components/Admin/CourseNoticeTable";

const AdminEducationPage = () => (
  <>
    <Breadcrumb>
      <Breadcrumb.Item>법정의무교육</Breadcrumb.Item>
    </Breadcrumb>

    <Tabs defaultActiveKey="home">
      <Tab eventKey="home" title="온라인 교육">
        <CourseTable />
      </Tab>
      <Tab eventKey="notice" title="공지사항">
        <CourseNoticeTable />
      </Tab>
    </Tabs>
  </>
);
export default AdminEducationPage;
