import { useEffect, useState } from "react";

import UploaderPopup from "components/Popups/UploaderPopup";
import { resourcePath } from "utils/formatter";

const PhotoIdBox = ({
  label = "사진 추가",
  isResume = true,
  photoUrl,
  popupTitle = "사진등록",
  onChange,
  onRemove,
}: {
  label?: string;
  isResume?: boolean;
  photoUrl?: string;
  popupTitle?: string;
  onChange: (filename: string) => void;
  onRemove?: (filename: string) => void;
}) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);

  return (
    <>
      <article className="img-upload-box">
        <input type="file" style={{ display: "none" }} />

        <button
          onClick={() => setShowPopup(true)}
          className="upload-btn"
          style={{
            backgroundColor:"#F5F5F5",
            backgroundImage:
              photoUrl !== undefined
              ? `url(${resourcePath(photoUrl)})`:'',
            backgroundPosition:'center center',
            backgroundSize:'100% 100%',
          }}
        >
          {photoUrl === undefined && (
            <>
              <i className="ic ic-photo"></i>
              <span className="txt">{label}</span>
            </>
          )}
        </button>

        {onRemove !== undefined && photoUrl !== undefined && (
          <div className="img-item">
            <img src={resourcePath(photoUrl)} alt="이미지" />
            <div
              className="upload-remove-btn"
              onClick={() => onRemove(photoUrl)}
            ></div>
          </div>
        )}
      </article>

      {showPopup && (
        <UploaderPopup
          title={popupTitle}
          isResume={isResume}
          photoUrl={photoUrl}
          onClose={() => setShowPopup(false)}
          onUpload={onChange}
        />
      )}
    </>
  );
};

export default PhotoIdBox;
