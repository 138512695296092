import { INoticeView } from "api/notice/types";
import { LECTURE_PATH, LECTURE_SUBPATH } from "routes";

const NoticeOverview = ({ notice }: { notice: INoticeView }) => (
  <a
    href={`${LECTURE_PATH}/${LECTURE_SUBPATH.NOTICE}/${notice.notice_id}`}
    className="row-box"
  >
    <div className="subject-box">{notice.title}</div>
    <div className="con-box">
      {notice?.contents && (
        <div
          className="data-txt-box"
          dangerouslySetInnerHTML={{
            __html: notice.contents.replaceAll("\n", ""),
          }}
        ></div>
      )}
    </div>

    <div className="count-box">
      <div className="cnt-box">
        <i className="ic ic-hit"></i>
        <span className="txt">{notice.view_count}</span>
      </div>
    </div>
  </a>
);
export default NoticeOverview;
