import { ChangeEvent, KeyboardEvent } from "react";

const InputFieldRow = ({
  value,
  placeholder,
  type = "text",
  onChange,
  onKeyPress,
}: {
  placeholder: string;
  type?: string;
  value: string;
  onChange: (newValule: string) => void;
  onKeyPress: (e: string) => void;
}) => (
  <div className="row">
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => onKeyPress(e.key)}
    />
  </div>
);

export default InputFieldRow;
