import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import { fetchMember, updateMemberInfo, searchMember } from "api/admin/rests";
import { IAdminMemberView } from "api/admin/types";

const AdminMember = ({
  memberId,
  onClose,
}: {
  memberId: string;
  onClose: () => void;
}) => {
  const [member, setMember] = useState<IAdminMemberView | null>(null);

  const updateMember = async () => {
    console.log(member);
    if (!member) return;
    try {
      await updateMemberInfo(member.member_id, member);
      alert("수정되었습니다.");
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }
  };

  useEffect(() => {
    if (!memberId) return;
    (async () => {
      const { data } = await fetchMember(memberId);
      setMember(data);
    })();
  }, [memberId]);

  return (
    <Modal show={!!memberId} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{member?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {member ? (
          <Form
            id="member-form"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              console.log(e);
            }}
          >
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                이메일
              </Form.Label>
              <Col>
                <Form.Control
                  type="email"
                  value={member.email}
                  onChange={(e) =>
                    setMember({ ...member, email: e.target.value })
                  }
                />
              </Col>
            </Form.Group>
            <div
              style={{
                display: "flex",
                gap: "8px",
                justifyContent: "flex-end",
                marginTop: "50px",
              }}
            >
              <Button
                id="member-form"
                type="submit"
                variant="primary"
                onClick={updateMember}
              >
                수정하기
              </Button>
              <Button variant="secondary" onClick={onClose}>
                닫기
              </Button>
            </div>
          </Form>
        ) : (
          <>선택된 회원이 없습니다.</>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default AdminMember;
