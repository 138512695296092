import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "layouts";
import { Banner } from "components/commons";
import { LOGIN_PATH } from "routes";
import {checkMemberExistById, checkMemberExistByIdWithName, findMemberId, resetPassword} from "api/member/rests";
import { checkPassword } from "utils/formatter";

const MemberFindPasswordPage = () => {
  const navigate = useNavigate();
  const [authCode, setAuthCode] = useState<string>("");
  const [memberName, setMemberName] = useState<string>("");
  const [memberId, setMemberId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rePassword, setRePassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const checkId = async () => {
    checkMemberExistByIdWithName({
      member_id:memberId,
      name:memberName
    }).then((res)=>{
      if(res.result === 'SUCCESS') {
        getPassAuth()
      }
    }).catch((e)=>alert('아이디를 찾을 수 없습니다.\n아이디를 확인해주세요'))
  }

  const getPassAuth = () => {
    const userCode = process.env.REACT_APP_IMP_CODE;
    // @ts-ignore
    const { IMP } = window;
    // IMP.init(userCode);
    IMP.init("imp88488634");
    IMP.certification({}, (response: any) => {
      if (response.success) {
        (async () => {
          try {
            const { data } = await findMemberId(response.imp_uid);
            if (data.member_id !== memberId || data.name !== memberName) {
              throw Error();
            }
          } catch (e) {
            alert("가입 정보가 없습니다.");
            return false;
          }
          setAuthCode(response.imp_uid);
        })();
      }
    });
  };

  const reset = async () => {
    setError("");
    if (!memberId) {
      setError("아이디를 입력해주세요.");
      return;
    }

    if (!checkPassword(password)) {
      setError("8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.");
      return;
    }

    if (password !== rePassword) {
      setError("비밀번호가 맞지않습니다.");
      return;
    }

    try {
      await resetPassword(memberId, password, authCode);
      alert("비밀번호가 변경되었습니다.\n 로그인페이지로 이동합니다.");
      navigate(`${LOGIN_PATH}`);
    } catch (e) {
      setError("존재하지 않는 회원정보입니다.");
    }
  };

  return (
    <Layout extraClass="exclude-menu bg-grey" excludeMenu={true}>
      <Banner type="SIGNUP" />

      <section className="join">
        <div className="inner wrap">
          <div className="join-form find-form">
            <div className="form-box">
              <div className="form-tit-box">개인회원 비밀번호 찾기</div>

              <div className="form-con-box">
                <div className="row">
                  <div className="input-box">
                    <input
                      type="text"
                      placeholder="이름"
                      value={memberName}
                      onChange={(e) => setMemberName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="input-box">
                    <input
                      type="text"
                      placeholder="아이디"
                      value={memberId}
                      onChange={(e) => setMemberId(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-box">
              {/*<div className="form-con-box">
                <div className="row">
                  <div className="input-msg-box2 type-success">
                    유효한 회원입니다. 본인인증을 완료해주세요.
                  </div>
                </div>
              </div>*/}
              <div className="form-con-box">
                <div className="row">
                  <div className="input-msg-box2">{error}</div>
                </div>
              </div>
            </div>
            <div className="form-box">
              <div className="form-tit-box2">본인인증🔒</div>
            </div>

            {!authCode ? (
              <div className="form-box type-find">
                <div className="submit-box">
                  <button
                    className="submit-btn"
                    onClick={checkId}
                    disabled={!memberId || !memberName}
                  >
                    휴대폰 인증
                  </button>
                </div>
              </div>
            ) : (
              <div className="form-box type-find">
                <div className="submit-box">
                  <div className="submit-btn off">인증이 완료되었습니다.</div>
                </div>
              </div>
            )}

            {authCode && (
              <>
                <div className="form-box">
                  <div className="form-con-box">
                    <div className="row">
                      <div className="input-msg-box type-success">
                        인증이 완료되었습니다. 새로운 비밀번호를 입력해주세요
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-box">
                  <div className="form-tit-box">새 비밀번호 입력</div>
                  <div className="form-con-box">
                    <div className="row">
                      <div className="input-box">
                        <input
                          type="password"
                          placeholder="새 비밀번호를 입력해주세요"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      {!checkPassword(password) ? (
                        <div className="input-msg-box">
                          비밀번호는 영문, 숫자, 특수문자 조합(따옴표, 부등호, 물음표 제외)으로 무조건 한개
                          이상 포함하여, 8 ~ 16자리까지 가능합니다.
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="row">
                      <div className="input-box">
                        <input
                          type="password"
                          placeholder="비밀번호를 한번 더 입력해주세요"
                          value={rePassword}
                          onChange={(e) => setRePassword(e.target.value)}
                        />
                      </div>
                      {password !== rePassword ? (
                        <div className="input-msg-box">
                          비밀번호가 일치하지 않습니다
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-box type-find">
                  <div className="form-con-box">
                    <div className="menu-box">
                      <div className="menu-btn" onClick={reset}>
                        완료
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default MemberFindPasswordPage;
