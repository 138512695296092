import { useEffect, useState } from "react";

import { fetchMyLecture as fetchClinicLecture } from "api/clinic/rests";
import { fetchMyLecture as fetchMemberLecture } from "api/member/rests";
import { ILectureCourseTakeOverview } from "api/education/types";
import { LECTURE_PATH, LECTURE_SUBPATH } from "routes";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";
import { lectureBaseurl, resourcePath } from "utils/formatter";
import { displayDate, lectureTypeToLabel } from "utils/formatter";

import DummyImg from "assets/img/img_edu_dummy.png";
import { Certification } from "components/Lecture";
import PrintPopup from "components/Popups/PrintPopup";
import CertificationLegal from "components/Lecture/CertificationLegal";
import { Banner } from "components/commons";

const LectureMyPage = ({ tab }: { tab: string }) => {
  const { userType } = useUserContext();
  const isDonePage = tab === "done";
  const [lectures, setLectures] = useState<ILectureCourseTakeOverview[]>([]);
  const [lectureToPrint, setLectureToPrint] =
    useState<ILectureCourseTakeOverview | null>(null);

  useEffect(() => {
    (async () => {
      const { data } =
        userType === USER_TYPE.개인회원
          ? await fetchMemberLecture()
          : await fetchClinicLecture();

      console.log(data)
      setLectures(
        data.filter((d) =>
          isDonePage ? d.take_type === "COMPLETE" && d.lecture_type !=="LEGAL" : d.take_type !== "COMPLETE" && d.lecture_type !=="LEGAL"
        )
      );
    })();
  }, [isDonePage, userType]);
  return (
    <section className="edu-my">
      <div className="edu-banner-box" style={{ marginBottom: 30 }}>
        <Banner type="MY_LECTURE" />
      </div>
      <div className="edu-my-tit-box">MY</div>

      <div className="edu-tabs-list">
        <a
          href={`${LECTURE_PATH}/${LECTURE_SUBPATH.MY_ING}`}
          className={`edu-tabs-btn ${!isDonePage ? "on" : ""}`}
        >
          수강중인강좌
        </a>
        <a
          href={`${LECTURE_PATH}/${LECTURE_SUBPATH.MY_DONE}`}
          className={`edu-tabs-btn ${isDonePage ? "on" : ""}`}
        >
          이수내역
        </a>
      </div>

      <div className="edu-tabs-group">
        {isDonePage ? (
          <div className="fin-list-box">
            <div className="web-table-box">
              <table>
                <colgroup>
                  <col style={{ width: "150px" }} />
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "150px" }} />
                  <col style={{ width: "150px" }} />
                  <col style={{ width: "150px" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>카테고리명</th>
                    <th>강의명</th>
                    <th>이수점수</th>
                    <th>이수날짜</th>
                    <th>이수증</th>
                  </tr>
                </thead>
                <tbody>
                  {lectures.map((lecture) => (
                    <tr key={lecture.course_id}>
                      <td>{lectureTypeToLabel(lecture.lecture_type)}</td>
                      <td>{lecture.title}</td>
                      <td>
                        {lecture.lecture_type === "LEGAL"
                          ? lecture.collect_flag
                            ? "집합교육"
                            : "개별교육"
                          : `${lecture.score}점`}
                      </td>
                      <td>
                        <b>{displayDate(lecture.complete_date)}</b>
                      </td>
                      <td className="center">
                        {lecture.lecture_type === "LEGAL" ? (
                          lecture.collect_flag ? (
                            <a
                              href={`${lectureBaseurl(lecture.lecture_type)}/${
                                lecture.course_id
                              }/personnel`}
                              className="fin-btn"
                            >
                              <span className="txt">교육수강 직원 목록</span>
                              <i className="ic ic-arrow"></i>
                            </a>
                          ) : (
                            <a
                              className="fin-btn"
                              role="button"
                              onClick={() => setLectureToPrint(lecture)}
                            >
                              <span className="txt">출석증 발급</span>
                              <i className="ic ic-arrow"></i>
                            </a>
                          )
                        ) : (
                          <div
                            className="fin-btn"
                            onClick={() => setLectureToPrint(lecture)}
                          >
                            <span className="txt">이수증 발급</span>
                            <i className="ic ic-arrow"></i>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                  {/* <tr>
                    <td>법정의무교육</td>
                    <td>직장내 괴롭힘 방지 교육</td>
                    <td>집합교육</td>
                    <td>
                      <b>2022.08.10</b>
                    </td>
                    <td className="center">
                      <a href="#none" className="fin-btn">
                        <span className="txt">교육수강 직원 목록</span>
                        <i className="ic ic-arrow"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>법정의무교육</td>
                    <td>직장내 괴롭힘 방지 교육</td>
                    <td className="accent">4점</td>
                    <td>
                      <b>2022.08.10</b>
                    </td>
                    <td className="center">
                      <a href="#none" className="fin-btn">
                        <span className="txt">이수증 발급</span>
                        <i className="ic ic-arrow"></i>
                      </a>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>

            <div className="mobile-table-box">
              <div className="item-list">
                {lectures.map((lecture) => (
                  <div key={lecture.course_id} className="item-box">
                    <div className="type-box">법정의무교육</div>
                    <div className="subject-box">{lecture.title}</div>
                    <div className="date-box">
                      이수날짜 {displayDate(lecture.complete_date)}
                    </div>

                    <div className="label-list">
                      <div className="label-box type-1">
                        이수점수 {lecture.score}점
                      </div>
                    </div>

                    {lecture.lecture_type === "LEGAL" ? (
                      lecture.collect_flag ? (
                        <a
                          href={`${lectureBaseurl(lecture.lecture_type)}/${
                            lecture.course_id
                          }/personnel`}
                          className="fin-btn"
                        >
                          <span className="txt">교육수강 직원 목록</span>
                          <i className="ic ic-arrow"></i>
                        </a>
                      ) : (
                        <div className="fin-btn">
                          <span className="txt">출석증 발급</span>
                          <i className="ic ic-arrow"></i>
                        </div>
                      )
                    ) : (
                      <div
                      // className="fin-btn"
                      >
                        {/* <span className="txt">이수증 발급</span>
                        <i className="ic ic-arrow"></i> */}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="ing-list-box">
            <div className="item-box">
              {lectures.map((lecture) => (
                <a
                  key={lecture.course_id}
                  href={`${lectureBaseurl(lecture.lecture_type)}/${
                    lecture.course_id
                  }`}
                  className="item-btn-box"
                >
                  {/* <div className="img-box">
                    {lecture.image_filename ? (
                      <img
                        src={resourcePath(lecture.image_filename)}
                        alt="이미지"
                      />
                    ) : (
                      <div className="no-picture">
                        <div className="name">
                          {lecture.title.substring(0, 30)}
                          {lecture.title.length > 30 && "..."}
                        </div>
                      </div>
                    )}
                  </div> */}

                  <div className="info-box">
                    <div className="type-box">
                      {lectureTypeToLabel(lecture.lecture_type)}
                    </div>
                    <div className="subject-box">{lecture.title}</div>
                    <div className="con-box">{lecture.description}</div>
                    <div className="label-list">
                      {lecture.lecture_type === "DENTIN" &&
                        lecture.score > 0 && (
                          <div className="label-box type-1">
                            이수점수 {lecture.score}점
                          </div>
                        )}
                      <div className="label-box type-2">
                        총 {lecture.lecture_count}강
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>

      {lectureToPrint && (
        <PrintPopup
          title={lectureToPrint.title || "이력서 출력"}
          handleClose={() => setLectureToPrint(null)}
        >
          {lectureToPrint.lecture_type === "LEGAL" ? (
            <CertificationLegal courseId={lectureToPrint.course_id} />
          ) : (
            <Certification courseId={lectureToPrint.course_id} />
          )}
        </PrintPopup>
      )}
    </section>
  );
};
export default LectureMyPage;
