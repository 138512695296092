export enum USER_TYPE {
  개인회원 = "1",
  병원회원 = "2",
}

export enum GENDER {
  남 = "M",
  여 = "F",
}

export enum CAREER_TYPE {
  신입 = "신입",
  경력 = "경력",
}

/**
 * 직종
 */
export enum OCCUPATION {
  치과의사 = "치과의사",
  간호조무사 = "간호조무사",
  치과기공사 = "치과기공사",
  치과위생사 = "치과위생사",
  코디네이터 = "코디네이터",
  기타 = "기타",
  일반인 = "일반인",
}

/**
 * 희망업무
 */
export enum OBJECTIVE_WORK {
  교정과 = "교정과",
  보철과 = "보철과",
  임플란트 = "임플란트",
  진료실 = "진료실",
  상담 = "상담",
  소아 = "소아",
  경영지원 = "경영지원",
  기타 = "기타",
}
/**
 * 희망근무형태
 */
export enum WORK_TYPE {
  정규직 = "정규직",
  계약직 = "계약직",
  아르바이트 = "아르바이트",
}
/**
 * 최종학력
 */
export enum EDUCATION_TYPE {
  고등학교졸업 = "고등학교 졸업",
  전문대졸업 = "전문대 졸업",
  "대학교(4년제)졸업" = "대학교(4년제) 졸업",
  "대학교(석/박사)졸업" = "대학교(석/박사) 졸업",
}
/**
 * 인재 검색 분류, search_category
 */
export enum RESUME_SEARCH_CATEGORY {
  REGION = "REGION",
  OCCUPATION = "OCCUPATION",
  KEYWORD = "KEYWORD",
}

/**
 * 정렬 대상, sort_property
 */
export enum SORT_PROPERTY {
  UPDATE_DATE = "UPDATE_DATE",
}
/**
 * 정렬 방식, sort_direction
 */
export enum SORT_DIRECTION {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SALARY_TYPE {
  협의 = "NEGOTIATION",
  월급 = "MONTHLY",
  시급 = "HOURLY",
  연봉 = "ANNUAL",
}
export enum TAX_TYPE {
  세전 = "BEFORE",
  세후 = "AFTER",
}
