import { useEffect, useState } from "react";

import { fetchLecturesByCourse } from "api/education/rests";
import { ILectureCourseOverview, TCourseType } from "api/education/types";

const useLecturesByCourse = (courseType: TCourseType) => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [lectures, setLectures] = useState<ILectureCourseOverview[]>([]);
  const [mainCate, setMainCate] = useState<number | undefined>(undefined);
  const [subCate, setSubCate] = useState<number | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const {
        data: { total_page, element_list },
      } = await fetchLecturesByCourse(courseType, page, mainCate, subCate);

      setLectures(element_list);
      setTotalPage(total_page);
    })();
  }, [page, mainCate, subCate]);

  return {
    page,
    totalPage,
    lectures,
    mainCate,
    subCate,
    handler: { setPage, setMainCate, setSubCate },
  };
};
export default useLecturesByCourse;
