interface IAlert {
  text?: string;
  yesEvent: () => void;
  noEvent: () => void;
  commonCallback?: () => void;
}
const Alert = ({ text, yesEvent, noEvent, commonCallback }: IAlert) => {
  return (
    <article
      className="popup alert-popup confirm-button"
      style={{ display: "block" }}
    >
      <div className="popup-wrap">
        <div className="popup-head"></div>
        <div className="popup-body">
          {text && <p className="alert-text">{text}</p>}
          <div className="popup-alert-btns">
            <button
              onClick={() => {
                yesEvent();
                if (typeof commonCallback === "function") commonCallback();
              }}
            >
              네
            </button>
            <button
              onClick={() => {
                noEvent();
                if (typeof commonCallback === "function") commonCallback();
              }}
            >
              아니오
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Alert;
