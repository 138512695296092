import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import {
  addMainNotice as _addMainNotice,
  updateMainNotice as _updateMainNotice,
} from "api/admin/rests";

import DatePicker from "components/DatePicker";
import { IAdminMainNoticeRequest, IAdminMainNoticeView } from "api/admin/types";

const INITIAL_NOTICE = {
  notice_type: "NORMAL",
  title: "",
  contents: "",
  start_date: "",
  end_date: ""
};

const AdminNotice = ({
  show,
  notice: _notice,
  onClose,
}: {
  show: boolean;
  notice: IAdminMainNoticeView | null;
  onClose: () => void;
}) => {
  const [notice, setNotice] = useState<IAdminMainNoticeRequest>(
    _notice || INITIAL_NOTICE
  );

  const addMainNotice = async () => {
    try {
      await _addMainNotice({
        ...notice
      });
      onClose();
      setNotice(INITIAL_NOTICE);
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }
  };

  const updateMainNotice = async () => {
    if (!_notice || !notice) return;
  
    try {
      await _updateMainNotice( _notice, _notice.notice_id);

      alert("수정되었습니다.");
      onClose();
      setNotice(INITIAL_NOTICE);
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }
  };

  useEffect(() => {
    if (!_notice){
      setNotice(INITIAL_NOTICE);
    }else{
      const { notice_id, ...params } =
      _notice;

      setNotice(params || INITIAL_NOTICE);
    }
    
  }, [_notice]);

  return (
    <Modal size="lg" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{_notice?.title || "공지사항 등록"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              제목
            </Form.Label>
            <Col>
              <Form.Control
                value={notice.title}
                onChange={(e) =>
                  setNotice({ ...notice, title: e.target.value })
                }
              />
            </Col>
          </Form.Group>
          
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              시작 일시
            </Form.Label>
            <Col>
              <DatePicker
                value={notice.start_date}
                minDate={new Date()}
                onChange={(date) =>
                  setNotice({ ...notice, start_date: `${date} 00:00:00` })
                }
              />
            </Col>
            <Form.Label column sm={3}>
              종료 일시
            </Form.Label>
            <Col>
              <DatePicker
                value={notice.end_date}
                minDate={new Date()}
                onChange={(date) =>
                  setNotice({ ...notice, end_date: `${date} 23:59:59` })
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              내용
            </Form.Label>
            <Col>
              <Form.Control
                as="textarea"
                rows={3}
                value={notice.contents}
                onChange={(e) =>
                  setNotice({
                    ...notice,
                    contents: e.target.value,
                  })
                }
              />
            </Col>
          </Form.Group> 

          <div
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "flex-end",
              marginTop: "50px",
            }}
          >
            <Button
              id="clinic-member-form"
              type="submit"
              variant="primary"
              onClick={() => (_notice ? updateMainNotice() : addMainNotice())}
            >
              {_notice ? "수정하기" : "등록하기"}
            </Button>
            <Button variant="secondary" onClick={onClose}>
              닫기
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AdminNotice;
