import { useEffect, useState } from "react";

import {
  updateResumeGroup,
  addResumeGroup as _addResumeGroup,
} from "api/member/rests";
import { IResumeGroupView } from "api/member/types";

import {
  DetailButton,
  GroupName,
  GroupNameInputField,
} from "components/ResumeGroup/Comps";

interface IProps {
  isEditMode: boolean;
  resumeGroup: IResumeGroupView | null;
  onClickCheckbox?: () => void;
  onClickDetail?: () => void;
  refresh?: () => void;
}

const ResumeGroup = ({
  isEditMode = false,
  resumeGroup,
  onClickCheckbox = () => {},
  onClickDetail = () => {},
  refresh = () => {},
}: IProps) => {
  const { group_id, group_name, resume_count } = resumeGroup || {
    group_id: -1,
    group_name: "",
    resume_count: 0,
  };
  const [name, setName] = useState<string>(group_name);
  const [isChangeName, setIsChangeName] = useState<boolean>(group_id < 0);

  const addResumeGroup = async () => {
    await _addResumeGroup({
      group_name: name,
    });
    await refresh();
  };

  const editResumeGroup = async () => {
    await updateResumeGroup(group_id, {
      group_name: name,
    });
    await refresh();
  };

  const handleChangeName = (name: string) => setName(name);
  const handleClickEditName = async () => {
    if (isChangeName && group_id > 0) {
      await editResumeGroup();
      setIsChangeName(false);
    } else if (isChangeName && group_id < 0) {
      await addResumeGroup();
      setIsChangeName(false);
    } else {
      setIsChangeName(true);
    }
  };

  useEffect(() => setName(group_name), [group_name]);

  return (
    <div className="item-box">
      <div className={`info-box ${isChangeName ? "edit-on" : ""}`}>
        {!isEditMode ? (
          <GroupName name={name} />
        ) : (
          <div className="toggle-input-box">
            {group_id > 0 && !resumeGroup?.default_flag && (
              <div className="cbox type-2 static">
                <label>
                  <input type="checkbox" onClick={onClickCheckbox} />
                  <i></i>
                </label>
              </div>
            )}
            <GroupName name={name} />
            <GroupNameInputField
              value={name}
              onChange={handleChangeName}
              onEnter={handleClickEditName}
            />

            <button className="edit-toggle-btn" onClick={handleClickEditName} />
            <button className="remove-btn mobile" onClick={() => {}}>
              <span className="txt">삭제</span>
              <i className="ic-remove"></i>
            </button>
          </div>
        )}
      </div>

      {group_id > 0 && (
        <DetailButton resumeCount={resume_count} onClick={onClickDetail} />
      )}
    </div>
  );
};

export default ResumeGroup;
