import { useEffect, useState } from "react";
import {
  JOBPOSTING_PATH,
  LECTURE_PATH,
  NOTICE_PATH,
  RESUME_PATH,
  LECTURE_SUBPATH,
  NOTICE_SUBPATH,
} from "routes";

import MenuItem from "./MenuItem";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";

const MenuBar = () => {
  const [showSub, setShowSub] = useState<boolean>(false);
  const { isSignIn, userType, clinicType } = useUserContext();

  const jobMenuProps = {
    label: "채용정보",
    url: JOBPOSTING_PATH,
    subs: [
      {
        label: "치과스텝",
        url: `${JOBPOSTING_PATH}?tab=staff`,
      },
      {
        label: "치과의사",
        url: `${JOBPOSTING_PATH}?tab=dentist`,
      },
      {
        label: "지도검색",
        url: `${JOBPOSTING_PATH}?tab=map`,
      },
      {
        label: "실습치과찾기",
        url: `${JOBPOSTING_PATH}?tab=intern`,
      },
    ],
  };

  const talentMenuProps = {
    label: "인재정보",
    url: RESUME_PATH,
    subs: [
      {
        label: "치과스텝",
        url: `${RESUME_PATH}?tab=staff`,
      },
      {
        label: "치과의사",
        url: `${RESUME_PATH}?tab=dentist`,
      },
    ],
  };

  const lectureMenuProps = {
    label: "온라인교육",
    url: LECTURE_PATH,
    subs: [
      {
        label: "홈",
        url: LECTURE_PATH,
      },
      {
        label: "법정의무교육",
        url: `${LECTURE_PATH}/${LECTURE_SUBPATH.DUTY}`,
      },
      {
        label: "치과인 강좌",
        url: `${LECTURE_PATH}/${LECTURE_SUBPATH.DENTAL_IN}`,
      },
      {
        label: "구강보건교육",
        url: `${LECTURE_PATH}/${LECTURE_SUBPATH.ORAL_HEALTH}`,
      },
      {
        label: "기타교육",
        url: `${LECTURE_PATH}/${LECTURE_SUBPATH.ETC}`,
      },
    ],
  };
  if (userType === USER_TYPE.개인회원)
    lectureMenuProps.subs = lectureMenuProps.subs.filter(
      (sub) => sub.label !== "법정의무교육"
    );

  const noticeMenuProps = {
    label: "공지사항",
    url: NOTICE_PATH,
    subs: [
      {
        label: "공지사항",
        url: NOTICE_PATH,
      },
      {
        label: "이벤트",
        url: `${NOTICE_PATH}/${NOTICE_SUBPATH.EVENT}`,
      },
      {
        label: "자료실",
        url: `${NOTICE_PATH}/${NOTICE_SUBPATH.DRIVE}`,
      },
    ],
  };

  return (
    <div className="header-bottom-box">
      <div
        className="inner wrap"
        onMouseEnter={() => setShowSub(true)}
        onMouseLeave={() => setShowSub(false)}
      >
        <nav className={showSub ? "on" : ""}>
          <ul className="depth1">
            <MenuItem {...jobMenuProps} />
            {isSignIn && userType !== USER_TYPE.개인회원 && (
              <MenuItem {...talentMenuProps} />
            )}
            {clinicType !== "보건소" && <MenuItem {...lectureMenuProps} />}
            <MenuItem {...noticeMenuProps} />
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MenuBar;
