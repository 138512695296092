import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Table,
  Pagination,
  Modal,
  ModalDialog,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Toast,
  ToastBody,
  ToastHeader,
  ToastContainer,
} from "react-bootstrap";

import { fetchMembers, activeMember, searchMember } from "api/admin/rests";
import { IAdminMemberView } from "api/admin/types";

import { displayDateTime } from "utils/formatter";

import AdminMember from "./AdminMember";

const QUERY_TYPE = [
  { label: "멤버 ID", value: "member_id" },
  { label: "이름", value: "name" },
  { label: "전화번호", value: "phone_number" },
];

const AdminMemberTable = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [queryType, setQueryType] = useState<{ label: string; value: string }>(
    QUERY_TYPE[0]
  );
  const [query, setQuery] = useState<string>("");
  const [members, setMembers] = useState<IAdminMemberView[]>([]);
  const [selectedMemberId, setSelectedMemberId] = useState<string>("");
  const [sendSMSBody, setSendSMSBody] = useState<string>("");
  const [sendSMSToast, setSendSMSToast] = useState<boolean>(false);
  const [sendSMSEvent, setSendSMSEvent] = useState<IAdminMemberView | null>(
    null
  );

  const toggleMemberActive = async (memberId: string, activeFlag: boolean) => {
    if (
      window.confirm(
        `해당 멤버를 ${activeFlag ? "재개" : "중지"} 하시겠습니까?`
      )
    ) {
      await activeMember(memberId, activeFlag);
      getMembers();
    }
  };

  const getMembers = useCallback(async () => {
    if (query) {
      const {
        data: { element_list, total_page },
      } = await searchMember(page, {
        [queryType.value]: query,
        sort_property: "MEMBER_ID",
        sort_direction: "ASC",
      });
      setMembers(element_list);
      setTotalPage(total_page);
    } else {
      const {
        data: { element_list, total_page },
      } = await fetchMembers(page);

      setMembers(element_list);
      setTotalPage(total_page);
    }
  }, [page, query]);

  useEffect(() => {
    (async () => {
      getMembers();
    })();
  }, [getMembers]);

  return (
    <>
      <ToastContainer>
        <Toast
          onClose={() => setSendSMSToast(false)}
          show={sendSMSToast}
          delay={3000}
          autohide
        >
          <ToastHeader>문자 전송 알림</ToastHeader>
          <ToastBody>문자 전송은 정식 런칭 이후에 지원됩니다.</ToastBody>
        </Toast>
      </ToastContainer>
      <Modal show={!!sendSMSEvent} size="lg" centered>
        <ModalDialog>
          <ModalHeader>{sendSMSEvent?.name} 회원 문자 전송</ModalHeader>
          <ModalBody>
            <textarea
              placeholder="보내실 문자 내용을 입력하세요"
              rows={20}
              cols={30}
              onChange={(e) => setSendSMSBody(e.target.value)}
            ></textarea>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={() => setSendSMSEvent(null)}>
              닫기
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setSendSMSToast(true);
                setSendSMSEvent(null);
              }}
              disabled={!sendSMSBody.length}
            >
              문자 전송
            </Button>
          </ModalFooter>
        </ModalDialog>
      </Modal>

      <div>
        <InputGroup className="mb-4">
          <DropdownButton variant="outline-secondary" title={queryType.label}>
            {QUERY_TYPE.map((qType, i) => (
              <Dropdown.Item key={i} onClick={() => setQueryType(qType)}>
                {qType.label}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <Form.Control
            placeholder={`${queryType.label}를 입력하세요.`}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          {/* <Button variant="outline-secondary">검색</Button> */}
        </InputGroup>
      </div>
      <div style={{ minHeight: "750px" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>MemberId</th>
              <th>이름</th>
              <th>생년월일</th>
              <th>전화번호</th>
              <th>이메일</th>
              <th>가입일</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {members.map((member) => (
              <tr key={member.member_id}>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedMemberId(member.member_id)}
                >
                  {member.member_id}
                  {!member.active_flag && <> (중지된 회원)</>}
                </td>
                <td>{member.name}</td>
                <td>{member.birth_date}</td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => setSendSMSEvent(member)}
                >
                  {member.phone_number}
                </td>
                <td>{member.email}</td>
                <td>{member.reg_date}</td>
                <td style={{ display: "flex", gap: "4px" }}>
                  <Button
                    variant={member.active_flag ? "light" : "primary"}
                    size="sm"
                    onClick={() =>
                      toggleMemberActive(member.member_id, !member.active_flag)
                    }
                  >
                    회원 {member.active_flag ? "중지" : "재개"}
                  </Button>

                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => setSelectedMemberId(member.member_id)}
                  >
                    편집
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination>
          {[...Array(totalPage).keys()].map((pageIdx) => (
            <Pagination.Item
              key={pageIdx + 1}
              active={pageIdx + 1 === page}
              onClick={() => setPage(pageIdx + 1)}
            >
              {pageIdx + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>

      <AdminMember
        memberId={selectedMemberId}
        onClose={() => {
          setSelectedMemberId("");
          getMembers();
        }}
      />
    </>
  );
};
export default AdminMemberTable;
