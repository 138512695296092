import { useEffect, useState } from "react";
import {
  InputFieldRow,
  LoginButton,
  RememberIdCheckbox,
} from "components/Login/Comps";
import UserTypeSelector from "components/commons/UserTypeSelector";

import { FIND_ID_PASSWORD, SIGNUP_PATH } from "routes";
import useLogin from "hooks/useLogin";
import { useSearchParams } from "react-router-dom";
import { USER_TYPE } from "utils/enums";
import Popup from "./Popup";
import { loadFCM } from "utils/appBridge";
import {logout} from "../../utils/storage";

const LoginForm = () => {
  const [params] = useSearchParams();
  const [isMaster, setMaster] = useState(false);
  const popup = false;
  const {
    state: { member_id, password, isSaveMemberId, userType, isFailed },
    handler: {
      login,
      setMemberId,
      setPassword,
      setUserType,
      toggleSaveMemberId,
      resetFailedState,
    },
  } = useLogin();
  useEffect(() => {
    const loginType = params.get("login_type");
    const id = params.get("id");
    switch (loginType) {
      case "1":
        setUserType(USER_TYPE.개인회원);
        break;
      case "2":
        setUserType(USER_TYPE.병원회원);
        break;
    }
    if(id) {
      setMemberId(id)
      setPassword(Math.random().toString(36).substring(2, 12))
      setMaster(true)
    }
  }, [params]);
  useEffect(() => {
    if(isMaster) {
      login(true).then((r)=>{
        if(window.opener){ // popup일 때
          window.opener.parent.location.reload()
          window.self.close()
        }
      })
    }

  }, [isMaster]);
  useEffect(() => {
    loadFCM();
  }, []);

  return (
    <div className="login-form">
      {popup && <Popup />}
      <div className="login-msg-box">로그인이 필요한 서비스입니다.</div>

      <UserTypeSelector
        userType={userType}
        onChange={(userType) => setUserType(userType)}
      />

      <div className="login-input-box">
        <div className="form-box">
          <div className="row mobile">
            <div className="mobile-tit-txt">ID/PW</div>
          </div>
          <InputFieldRow
            value={member_id}
            placeholder="ID"
            onChange={(memberId) => {
              resetFailedState();
              setMemberId(memberId);
            }}
            onKeyPress={(e: string) => {}}
          />
          <InputFieldRow
            value={password}
            placeholder="PW"
            type="password"
            onChange={(password: string) => {
              resetFailedState();
              setPassword(password);
            }}
            onKeyPress={(e: string) => {
              if (e == "Enter") {
                login(false);
              }
            }}
          />
        </div>
      </div>

      {isFailed && (
        <div className="login-input-msg-box">
          아이디 또는 비밀번호를 잘못 입력했습니다. 입력하신 내용을 다시
          확인해주세요.
        </div>
      )}

      <LoginButton onClick={()=>{
          login(false)
      }
      } />

      <div className="login-option-box">
        <div className="menu-box">
          <a href={SIGNUP_PATH} className="menu-btn type-1">
            회원가입
          </a>
          <a href={`${FIND_ID_PASSWORD}`} className="menu-btn type-2">
            ID/PW찾기
          </a>
        </div>
        <RememberIdCheckbox
          checked={isSaveMemberId}
          onChange={toggleSaveMemberId}
        />
      </div>
    </div>
  );
};
export default LoginForm;
