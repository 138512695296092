import { useContext } from "react";
import InputFieldRow from "./Comps/InputFieldRow";
import OccupationFieldRow from "./Comps/OccupationFieldRow";
import { MemberSignupContext } from "contexts/SignupContex";

const EmailOccupationBox = () => {
  const context = useContext(MemberSignupContext);

  const {
    licence_number,
    email,
    occupation,
    errors,
    setEmail,
    setOccupation,
    setLicenceNumber,
  } = context;

  return (
    <div className="form-box" data-type="guest">
      <div className="form-tit-box">이메일/직종선택</div>
      <div className="form-con-box">
        <InputFieldRow
          errorMessage={errors?.email}
          placeholder="이메일*"
          value={email}
          onChange={(value) =>
            setEmail(value.replace(/[^ㄱ-ㅎㅏ-ㅣ가-힣\w-\d@.]/, ""))
          }
        />
        <OccupationFieldRow
          errorMessage={errors?.occupation}
          value={occupation}
          onChange={(value) => {
            if (value !== "치과의사") setLicenceNumber("");
            setOccupation(value);
          }}
        />
        {occupation.includes("치과의사") && (
          <InputFieldRow
            errorMessage={errors?.licence_number}
            placeholder="면허번호*"
            value={licence_number}
            onChange={(value) => setLicenceNumber(value)}
          />
        )}
      </div>
    </div>
  );
};

export default EmailOccupationBox;
