import { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import { updateBanner, addBanner as _addBanner, uploadAdminImage } from "api/admin/rests";
import DatePicker from "components/DatePicker";
import { IAdminMainBannerView, IAdminMainBannerRequest } from "api/admin/types";

const INITIAL_BANNER = {
  banner_type: "",
  image_filename: "",
  banner_url: "",
  start_date: "",
  end_date: "",
};

const AdminBanner = ({
  show,
  banner: _banner,
  onClose,
}: {
  show: boolean;
  banner: IAdminMainBannerView | null;
  onClose: () => void;
}) => {
  const [adminBanner, setAdminBanner] = useState<IAdminMainBannerRequest>(
    _banner || INITIAL_BANNER
  );
  const [imgFile, setImgFile] = useState<File>();
  const [selectedType, setSelectedType] = useState<string>("MAIN")

  const addBanner = async () => {
    if(!imgFile) return;

    const formData = new FormData();
    formData.append("file", imgFile);

    try {

      const result = await uploadAdminImage(formData)

      await _addBanner({
        ...adminBanner,
        banner_type : selectedType,
        image_filename : result.data.file_name
      });
      onClose();
      setAdminBanner(INITIAL_BANNER);
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }
  };

  const updateAdminBanner = async () => {
    if (!_banner || !adminBanner) return;

    try {

      if(imgFile) {
        const formData = new FormData();
        formData.append("file", imgFile);
  
        const result = await uploadAdminImage(formData);
        
        await updateBanner(_banner?.banner_id , {...adminBanner, image_filename: result.data.file_name});

      }else{

        await updateBanner(_banner?.banner_id , adminBanner);

      }
      
      onClose();
      setAdminBanner(INITIAL_BANNER);
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }
  };


  useEffect(() => {
    if (!_banner){
      setAdminBanner(INITIAL_BANNER)
    }else{
      const { banner_id, ...params } =
      _banner;

      setAdminBanner(params || INITIAL_BANNER);
    }
   
  }, [_banner]);

  const handleFile = (e:ChangeEvent<HTMLInputElement>) =>{

    if(!e.target.files) return;
  
    setImgFile(e.target.files[0]);
    
  }

  return (
    <Modal size="lg" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{_banner?.banner_type || "공지사항 등록"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              타입
            </Form.Label>
            <Col>
              <Form.Select value={adminBanner.banner_type} onChange={(e) => {
                setAdminBanner({...adminBanner, banner_type: e.target.value})
                setSelectedType(e.target.value);
                }}>
                  <option>MAIN</option>
                  <option>LOGIN</option>
              </Form.Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              이미지명
            </Form.Label>
            <Col>
              <input type="file" className="form-control" onChange={(e) => handleFile(e)}/>
            </Col>
          </Form.Group>
          
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              시작 일시
            </Form.Label>
            <Col>
              <DatePicker
                value={adminBanner.start_date}
                minDate={new Date()}
                onChange={(date) =>
                  setAdminBanner({ ...adminBanner, start_date: `${date} 00:00:00` })
                }
              />
            </Col>
            <Form.Label column sm={3}>
              종료 일시
            </Form.Label>
            <Col>
              <DatePicker
                value={adminBanner.end_date}
                minDate={new Date()}
                onChange={(date) =>
                  setAdminBanner({ ...adminBanner, end_date: `${date} 23:59:59` })
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              배너 URL
            </Form.Label>
            <Col>
              <Form.Control
                as="textarea"
                rows={3}
                value={adminBanner.banner_url}
                onChange={(e) =>
                  setAdminBanner({
                    ...adminBanner,
                    banner_url: e.target.value,
                  })
                }
              />
            </Col>
          </Form.Group> 

          <div
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "flex-end",
              marginTop: "50px",
            }}
          >
            <Button
              id="clinic-member-form"
              type="submit"
              variant="primary"
              onClick={() => (_banner ? updateAdminBanner() : addBanner())}
            >
              {_banner ? "수정하기" : "등록하기"}
            </Button>
            <Button variant="secondary" onClick={onClose}>
              닫기
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AdminBanner;
