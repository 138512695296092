import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";

import { uploadMemberImage } from "api/member/rests";
import { uploadClinicImage } from "api/clinic/rests";
import { useUserContext } from "contexts/UserContext";
import MobileHeadBox from "components/MobileHeadBox";
import MenuBox from "./MenuBox";
import { USER_TYPE } from "utils/enums";
import { resourcePath } from "utils/formatter";

const UploaderPopup = ({
  title = "사진등록",
  photoUrl,
  isResume,
  onClose,
  onUpload,
}: {
  title?: string;
  photoUrl?: string;
  isResume?: boolean;
  onClose: () => void;
  onUpload: (filename: string) => void;
}) => {
  const { userType } = useUserContext();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const editor = useRef<AvatarEditor | null>(null);

  const setEditorRef = (ed: any) => {
    editor.current = ed;
  };

  const handleSave = async () => {
    if (editor.current) {
      // const canvasScaled = ;
      if (!editor.current.props.image) {
        alert("사진을 업로드 해주세요.");
        return;
      }
      const croppedImg = editor.current.getImageScaledToCanvas().toDataURL();

      var blobBin = atob(croppedImg.split(",")[1]); // base64 데이터 디코딩
      var array = [];
      for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      var file = new Blob([new Uint8Array(array)], { type: "image/png" }); // Blob 생성

      const formData = new FormData();
      formData.append("file", file, selectedFile?.name);

      if (userType === USER_TYPE.개인회원) {
        const {
          data: { file_name },
        } = await uploadMemberImage(formData);

        onUpload(file_name);
      } else {
        const {
          data: { file_name },
        } = await uploadClinicImage(formData);
        onUpload(file_name);
      }

      onClose();
    }
  };

  return (
    <article className="popup popup-logo-upload" style={{ display: "block" }}>
      <div className="popup-wrap">
        <div className="popup-head">
          <MobileHeadBox popup onClose={onClose} />

          <button className="close-btn" onClick={onClose}></button>
        </div>

        <div className="popup-body">
          <article className="popup-img-upload">
            <div className="tit-box">
              <div className="icon-box">📷</div>
              <div className="txt-box">{title}</div>
            </div>
            <div className="info-box">
              1. 아래 [찾기]를 클릭하여 등록할 사진을 선택합니다.
              <br />
              2. 반영이미지에 이미지를 드래그 하여 위치를 변경할 수 있습니다.
              <br />
              3. 사진을 새로 등록 또는 변경하면 <br />
              작성한 이력서나 공고에도 이미지가 반영 됩니다.
              <br />
              4. 권장 이미지 사이즈 : 300px x 300px
            </div>

            <div className="img-preview-box">
              <div className="upload-box">
                <div className="tit-box">원본이미지</div>
                <div className="img-box type-circle">
                  {photoUrl && (
                    <img src={resourcePath(photoUrl)} alt="이미지" />
                  )}
                </div>
              </div>

              <div className="upload-box">
                <div className="tit-box">반영이미지</div>
                <div className="img-box type-circle">
                  <div className="img-select-box">
                    <AvatarEditor
                      ref={setEditorRef}
                      image={
                        selectedFile ? URL.createObjectURL(selectedFile) : ""
                      }
                      width={300}
                      height={300}
                      border={0}
                      borderRadius={50}
                      color={[255, 255, 255, 0.6]} // RGBA
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <MenuBox
              onClear={() => setSelectedFile(null)}
              onClose={onClose}
              onImageChange={(image) => setSelectedFile(image)}
              onUpload={handleSave}
            />
          </article>
        </div>
      </div>
    </article>
  );
};

export default UploaderPopup;
