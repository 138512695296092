import { MemberSignupContext } from "contexts/SignupContex";
import { useContext } from "react";

const ReferrerFieldRow = () => {
  const context = useContext(MemberSignupContext);
  const { referrer, setReferrer } = context;

  return (
    <div className="form-box">
      <div className="form-tit-box">가입경로 선택</div>
      <div className="form-con-box">
        <div className="row">
          <div className="category-box">
            <div className="sbox full off">
              <select
                className="job"
                value={referrer}
                onChange={(e) => setReferrer(e.target.value)}
              >
                <option value="지인추천">지인추천</option>
                <option value="언론(기사)">언론(기사)</option>
                <option value="광고">광고</option>
                <option value="모어덴">모어덴</option>
                <option value="치즈톡">치즈톡</option>
                <option value="기타">기타</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferrerFieldRow;
