import axiosClient from "api/axiosClient";
import { IResponse } from "api/types";
import {
  IWorkAreaView,
  IMemberWorkAreaView,
  IMemberWorkAreaListCreateRequest,
} from "./types";

/**
 * 근무 지역 조회 API
 */
export const fetchArea = async (): Promise<IResponse<IWorkAreaView[]>> => {
  const resp = await axiosClient.get("/area");
  return resp.data;
};

/**
 * 희망 근무 지역 전체 생성
 * POST /member/area/
 */
export const createWorkareas = async (
  params: IMemberWorkAreaListCreateRequest
) => {
  const resp = await axiosClient.post(`/member/area`, params);
  return resp.data;
};

/**
 * 희망 근무 지역 생성
 * POST /member/area/{seq}
 */
export const createWorkarea = async (seq: number, params: IWorkAreaView) => {
  const resp = await axiosClient.post(`/member/area/${seq}`, params);
  return resp.data;
};

/**
 * 희망 근무 지역 수정
 * PUT /member/area/{seq}
 */
export const updateWorkarea = async (seq: number, params: IWorkAreaView) => {
  const resp = await axiosClient.put(`/member/area/${seq}`, params);
  return resp.data;
};

/**
 * 희망 근무 지역 전체 조회
 * GET /member/area
 */
export const fetchWorkarea = async () => {
  const resp = await axiosClient.get<IResponse<IMemberWorkAreaView[]>>(
    "/member/area"
  );
  return resp.data;
};
