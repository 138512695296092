import { useState } from "react";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";

import AdminBannerTable from "components/Admin/AdminBannerTable";

const AdminBannerPage = () => (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>배너관리</Breadcrumb.Item>
      </Breadcrumb>
  
      <Tabs defaultActiveKey="adminBanner"
      id="uncontrolled-tab-example"
      className="mb-3">
        <Tab eventKey="adminBanner" title="배너관리">
          <AdminBannerTable />
        </Tab>
      </Tabs>
    </>
  );
  export default AdminBannerPage;