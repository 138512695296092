import { useState } from "react";

const InputSearchType = ({
  label,
  type,
  selected,
  onClick,
  onConfirm,
}: {
  label: string;
  type: string;
  selected: boolean;
  onClick: () => void;
  onConfirm: (value: string) => void;
}) => {
  const [value, setValue] = useState<string>("");

  return (
    <div className="type-box">
      <div className="type-btn mobile" data-type={type} onClick={onClick}>
        <i className="ic ic-search"></i>
        <span className="txt">{label}</span>
      </div>
      <div
        className={`keyword-box web ${selected ? "on active" : ""}`}
        style={{ zIndex: 99 }}
      >
        <div className="input-box" onClick={onClick}>
          <input
            type="text"
            placeholder="키워드 검색"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.nativeEvent.isComposing === false) {
                !!value && onConfirm(value);
                setValue("");
              }
            }}
          />
          <i className="ic ic-search"></i>
          <div
            className="keyword-search-btn"
            onClick={(e) => {
              !!value && onConfirm(value);
              setValue("");

              e.stopPropagation();
            }}
          >
            확인
          </div>
        </div>

        <div className="keyword-list-box">
          <div className="no-data">
            검색할 <b>키워드</b>를 입력해주세요. 예&#41; 정규직, 계약직 등
          </div>
        </div>
      </div>
    </div>
  );
};
export default InputSearchType;
