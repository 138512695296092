import { Banner } from "components/commons";

const Marketing = () => (
  <div className="login-marketing">
    <div className="marketing-box">
      <Banner type="LOGIN" />
    </div>
  </div>
);

export default Marketing;
