import { useState } from "react";
import {
  JOBPOSTING_PATH,
  LECTURE_PATH,
  LECTURE_SUBPATH,
  NOTICE_PATH,
  NOTICE_SUBPATH,
  RESUME_PATH,
} from "routes";
// import { useBookmarkContext } from "contexts/BookmarkContext";
// import { lectureBaseurl } from "utils/formatter";
import { logout } from "utils/storage";
import { useUserContext } from "contexts/UserContext";

type TMenu = "" | "recruit" | "resume" | "education" | "notice";
const MobileMenu = ({
  isShow,
  onClose,
}: {
  isShow: boolean;
  onClose: () => void;
}) => {
  // const { bookmarks } = useBookmarkContext();
  const [activeMenu, setActiveMenu] = useState<TMenu>("");
  const { isSignIn } = useUserContext();

  return (
    <nav className="mobile-menu" style={{ display: isShow ? "block" : "none" }}>
      <button onClick={onClose} className="close-btn"></button>

      <div className="mobile-menu-wrap">
        <div className="mobile-menu-group">
          <ul className="depth1">
            <li>
              <div
                className={`depth1-txt toggle box ${
                  activeMenu === "recruit" ? "on" : ""
                }`}
                onClick={() => setActiveMenu("recruit")}
              >
                채용정보
              </div>
              <ul className="depth2">
                <li>
                  <a href={`${JOBPOSTING_PATH}`}>스탭</a>
                </li>
                <li>
                  <a href={`${JOBPOSTING_PATH}?tab=dentist`}>치과의사</a>
                </li>
                <li>
                  <a href={`${JOBPOSTING_PATH}?tab=map`}>지도검색</a>
                </li>
              </ul>
            </li>
            <li>
              <div
                className={`depth1-txt toggle box ${
                  activeMenu === "resume" ? "on" : ""
                }`}
                onClick={() => setActiveMenu("resume")}
              >
                인재정보
              </div>
              <ul className="depth2">
                <li>
                  <a href={`${RESUME_PATH}`}>스탭</a>
                </li>
                <li>
                  <a href={`${RESUME_PATH}?tab=dentist`}>치과의사</a>
                </li>
              </ul>
            </li>

            <li>
              <div
                className={`depth1-txt toggle box ${
                  activeMenu === "education" ? "on" : ""
                }`}
                onClick={() => setActiveMenu("education")}
              >
                온라인교육
              </div>
              <ul className="depth2">
                <li>
                  <a href={`${LECTURE_PATH}`}>홈</a>
                </li>
                <li>
                  <a href={`${LECTURE_PATH}/${LECTURE_SUBPATH.DUTY}`}>
                    법정의무교육
                  </a>
                </li>
                <li>
                  <a href={`${LECTURE_PATH}/${LECTURE_SUBPATH.DENTAL_IN}`}>
                    치과인 강좌
                  </a>
                </li>
                <li>
                  <a href={`${LECTURE_PATH}/${LECTURE_SUBPATH.ORAL_HEALTH}`}>
                    구강보건교육
                  </a>
                </li>
                <li>
                  <a href={`${LECTURE_PATH}/${LECTURE_SUBPATH.ETC}`}>
                    기타교육
                  </a>
                </li>
              </ul>
            </li>
            {/* <li>
              <div
                className={`depth1-txt toggle box ${showFavorite ? "on" : ""}`}
                onClick={() => setShowFavorite(!showFavorite)}
              >
                즐겨찾기
              </div>
              <ul className="depth2">
                {bookmarks.map((bookmark) => (
                  <li key={bookmark.course_id}>
                    <a
                      href={`${lectureBaseurl(bookmark.lecture_type)}/${
                        bookmark.course_id
                      }`}
                    >
                      {bookmark.title}
                    </a>
                  </li>
                ))}
              </ul>
            </li> */}

            <li>
              <div
                className={`depth1-txt toggle box ${
                  activeMenu === "notice" ? "on" : ""
                }`}
                onClick={() => setActiveMenu("notice")}
              >
                공지사항
              </div>
              <ul className="depth2">
                <li>
                  <a href={NOTICE_PATH}>공지사항</a>
                </li>
                <li>
                  <a href={`${NOTICE_PATH}/${NOTICE_SUBPATH.EVENT}`}>이벤트</a>
                </li>
              </ul>
            </li>
            {isSignIn && (
              <li>
                <div
                  className="depth1-txt box"
                  role="button"
                  onClick={() => {
                    logout();
                    window.location.reload();
                  }}
                >
                  로그아웃
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MobileMenu;
