import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "layouts";

import { IChatMemberView, IQnaChatView } from "api/types";
import { fetchChatList as fetchClinicChatList } from "api/clinic/rests";
import {
  fetchChatList as fetchMemberChatList,
  fetchClinicQna,
  fetchMemberQna,
} from "api/member/rests";
import { useUserContext } from "contexts/UserContext";
import { ChatContent } from "components/Message";
import { USER_TYPE } from "utils/enums";
import { displayTime } from "utils/formatter";

import ChatListHead from "components/Message/ChatListHead";
import { MESSAGE_PATH } from "routes";
import QnaContent from "components/Message/QnaContent";
import LoginPage from "./LoginPage";

const MessagePage = () => {
  const [params, setParams] = useSearchParams();
  const chat_name = params.get("name");
  const is_qna = params.get("qna");
  const navigate = useNavigate();

  const { isSignIn, userType } = useUserContext();
  const [keyword, setKeyword] = useState<string>("");
  const [chats, setChats] = useState<IChatMemberView[]>([]);
  const [qnas, setQnas] = useState<IQnaChatView[]>([]);
  const [selectedChat, setSelectedChat] = useState<IChatMemberView | null>(
    null
  );
  const [onlyUnread, setOnlyUnread] = useState<boolean>(false);
  const [updateTrigger, setUpdateTrigger] = useState<number>(0);

  const fetchChats = useCallback(async () => {
    const { data } =
      userType === USER_TYPE.개인회원
        ? await fetchMemberChatList(keyword)
        : await fetchClinicChatList(keyword);
    setChats(data);

    const { data: qna_data } =
      userType === USER_TYPE.개인회원
        ? await fetchMemberQna()
        : await fetchClinicQna();
    setQnas(qna_data);
    if (!selectedChat)
      setSelectedChat(data.find((d) => d.name === chat_name) || null);
  }, [chat_name, userType, keyword, updateTrigger]);

  useEffect(() => {
    (async () => await fetchChats())();
  }, [fetchChats]);

  if (!isSignIn) {
    return <LoginPage />;
  }

  return (
    <Layout>
      <section className="chat">
        <div className="inner wrap">
          <div className="chat-list">
            <ChatListHead
              onlyUnread={onlyUnread}
              keyword={keyword}
              onChangeOnlyUnread={(onlyUnread) => setOnlyUnread(onlyUnread)}
              onChangeKeyword={(keyword) => setKeyword(keyword)}
              // onSearch={searchChats}
            />
            <div className="chat-list-body scroll-effect">
              <ul>
                <li>
                  <div
                    className="chat-item-box"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`${MESSAGE_PATH}?qna=true`);
                    }}
                  >
                    <div className="subject-box">
                      <div className="subject-txt">1:1 문의</div>
                      <div className="date-txt">
                        {displayTime(qnas.at(-1)?.reg_date)}
                      </div>
                    </div>

                    <div className="con-box">{qnas.at(-1)?.message}</div>
                  </div>
                </li>
                {chats
                  .filter((chat) => (onlyUnread ? !chat.read_flag : true))
                  .map((chat) => (
                    <li key={chat.chat_id}>
                      <div
                        className="chat-item-box"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setParams({ target_id: chat.chat_id.toString() });
                          setSelectedChat(chat);
                        }}
                      >
                        <div className="subject-box">
                          <div className="subject-txt">{chat.name}</div>
                          <div className="date-txt">
                            {displayTime(chat.last_reg_date)}
                          </div>
                        </div>

                        <div className="con-box">{chat.last_message}</div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {!is_qna ? (
            <ChatContent
              chat={selectedChat}
              onUpdateChat={() => setUpdateTrigger(updateTrigger + 1)}
            />
          ) : (
            <QnaContent qnas={qnas} setQnas={setQnas} />
          )}
        </div>
      </section>
    </Layout>
  );
};

export default MessagePage;
