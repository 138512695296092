import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import {
  fetchJobPostingHistory,
  readRequestedJobPosting,
  responseRequestedJobPosting,
} from "api/member/rests";
import { IJobActionOpeningView } from "api/member/types";
import Paginator from "components/Paginator";
import ApplyPopup from "components/Popups/ApplyPopup";
import { JOBPOSTING_PATH, MESSAGE_PATH } from "routes";
import { displayDate, isOverdue, displayMinMaxMonth } from "utils/formatter";

const MemberOfferHistoryPage = () => {
  const [page, setPage] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [histories, setHistories] = useState<IJobActionOpeningView[]>([]);

  const fetch = useCallback(async () => {
    const {
      data: { total_page, total_elements, element_list },
    } = await fetchJobPostingHistory("OFFER", page);
    setHistories(element_list);
    setTotalPage(total_page);
    setTotalElements(total_elements);
  }, [page]);

  useEffect(() => {
    (async () => await fetch())();
  }, [page]);

  return (
    <section className="my-resume-group">
      <article className="lnb-contents-tit">
        <div className="tit-box">
          <i className="ic ic-request"></i>
          <span className="txt">병원이 보낸 요청</span>
        </div>
      </article>

      <article className="my-group-head-box">
        <div className="group-head-box">
          <div className="left-box">
            <div className="cnt-box">총 {totalElements}건</div>
          </div>
        </div>
      </article>

      <div className="group-data-body-box">
        <div className="list-wrap">
          <ul>
            {histories.map((history) => (
              <History key={history.resume_id} history={history} />
            ))}
          </ul>
        </div>
      </div>

      {totalPage > 1 && (
        <Paginator
          currentPage={page}
          totalPage={totalPage}
          changePage={(page) => setPage(page)}
        />
      )}
    </section>
  );
};
export default MemberOfferHistoryPage;

const History = ({ history }: { history: IJobActionOpeningView }) => {
  const navigate = useNavigate();
  const [showApplyPopup, setShowApplyPopup] = useState<boolean>(false);

  const read = async () => {
    await readRequestedJobPosting(history.job_id, history.resume_id);
    navigate(`${JOBPOSTING_PATH}/${history.job_id}`);
  };

  const apply = async () => {
    await responseRequestedJobPosting(
      history.job_id,
      history.resume_id,
      "ACCEPT"
    );
  };

  const reject = async () => {
    try {
      await responseRequestedJobPosting(
        history.job_id,
        history.resume_id,
        "REJECT"
      );
      /// 리로드 동작 추가 필요
      window.location.reload();
      alert("거절되었습니다.");
    } catch (e) {
      alert("오류가 발생하였습니다.");
    }
  };

  return (
    <>
      <li>
        <div className="item-box">
          <div className="date-box">
            <span className="subject">마감일</span>{" "}
            <span className="con">{displayDate(history.closing_date)}</span>
          </div>
          <div className="name-box" onClick={read}>
            {history.title}
          </div>

          <article className="my-summary-box m-type2">
            <div className="summary-box">
              <div className="summary-item-box">
                <div className="row">
                  <div className="summary-txt-box type-5">
                    {history.clinic_name}
                  </div>
                </div>
              </div>

              <div className="summary-item-box">
                <div className="row">
                  <div className="summary-txt-box type-2">
                    지역 {history.city}
                  </div>
                </div>
              </div>
            </div>
          </article>

          <article className="my-information-box web">
            <div className="information-box">
              <div className="information-txt">
                {history.work_type_list.join(", ")}
              </div>
              <div className="information-txt">
                {history.occupation_list.join(", ")}
              </div>
              <div className="information-txt">
                {displayMinMaxMonth(
                  history.min_work_experience,
                  history.max_work_experience
                )}
              </div>
              <div className="information-txt">
                {history.work_list.join(", ")}
              </div>
            </div>
          </article>
          

          
            <article className="my-menu-box">
              <div className="menu-box">
              {!isOverdue(history.closing_date) && (
                <>
                <a
                  href={`${MESSAGE_PATH}?target_id=${history.job_id}`}
                  className="menu-btn type-3 type-small"
                >
                  <span className="txt web">메세지</span>
                  <i className="ic ic-message"></i>
                </a>
                <div
                  className="menu-btn type-1"
                  onClick={() => setShowApplyPopup(true)}
                >
                  <span className="txt">지원</span>
                </div>
                </>
                )}
                <div className="menu-btn type-2" onClick={reject}>
                  <span className="txt">거절</span>
                </div>
              </div>
            </article>
          
        </div>
      </li>

      {showApplyPopup && (
        <ApplyPopup
          jobId={history.job_id}
          handleClose={() => setShowApplyPopup(false)}
          afterSuccessCallback={apply}
        />
      )}
    </>
  );
};
