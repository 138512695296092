import { useState } from "react";
import { ChatTypeSelector } from "./Comps";

enum MODE {
  IDEL = "IDEL",
  READ_TYPE = "READ_TYPE",
  SEARCH = "SEARCH",
}

const useCharListHead = () => {
  const [mode, setMode] = useState<MODE>(MODE.IDEL);

  return {
    mode,
    handler: {
      toggleReadType: () =>
        mode === MODE.READ_TYPE ? setMode(MODE.IDEL) : setMode(MODE.READ_TYPE),
      toggleSearch: () =>
        mode === MODE.SEARCH ? setMode(MODE.IDEL) : setMode(MODE.SEARCH),
    },
  };
};
const ChatListHead = ({
  onlyUnread,
  keyword,
  onChangeOnlyUnread,
  onChangeKeyword,
}: // onSearch,
{
  onlyUnread: boolean;
  keyword: string;
  onChangeOnlyUnread: (onlyUnread: boolean) => void;
  onChangeKeyword: (keyword: string) => void;
  // onSearch: () => void;
}) => {
  const {
    mode,
    handler: { toggleReadType, toggleSearch },
  } = useCharListHead();

  return (
    <div className="chat-list-head">
      <div
        className="chat-head-box chat-type"
        style={{ display: mode === MODE.SEARCH ? "none" : "block" }}
      >
        {/* <ChatTypeSelector /> */}

        <div className="chat-btn-box">
          <div className="chat-btn" onClick={toggleReadType}>
            {onlyUnread ? "안 읽은 메세지" : "전체 메세지"}
          </div>
          <ul style={{ display: mode === MODE.READ_TYPE ? "block" : "none" }}>
            <li>
              <div
                className={onlyUnread ? "box" : "on box"}
                onClick={() => {
                  onChangeOnlyUnread(false);
                  toggleReadType();
                }}
              >
                전체 메세지
              </div>
            </li>
            <li>
              <div
                className={onlyUnread ? "on box" : "box"}
                onClick={() => {
                  onChangeOnlyUnread(true);
                  toggleReadType();
                }}
              >
                안 읽은 메세지
              </div>
            </li>
          </ul>
        </div>

        <div
          className="chat-head-btn"
          onClick={() => {
            onChangeKeyword("");
            toggleSearch();
          }}
        >
          <i className="ic ic-search"></i>
        </div>
      </div>

      <div
        className="chat-head-box chat-search"
        style={{ display: mode === MODE.SEARCH ? "block" : "none" }}
      >
        <div className="chat-btn-box">
          <div className="chat-btn">
            닉네임, 메모 검색
          </div>
          <ul>
            <li>
              <div className="on">
                닉네임, 메모 검색
              </div>
            </li>
            <li>
              <div>다른 검색</div>
            </li>
          </ul>
        </div>

        <div
          className="chat-head-btn"
          onClick={() => {
            onChangeKeyword("");
            toggleSearch();
          }}
        >
          <i className="ic ic-close"></i>
        </div>

        <div className="chat-input-box">
          <input
            type="text"
            placeholder="검색어를 입력하세요"
            value={keyword}
            onChange={(e) => onChangeKeyword(e.target.value)}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     onSearch();
            //   }
            // }}
          />
        </div>
      </div>
    </div>
  );
};
export default ChatListHead;
