import { TBannerType } from "api/banner/types";
import useBanners from "hooks/useBanners";
import { resourcePath } from "utils/formatter";

const Banner = ({ type }: { type: TBannerType }) => {
  const banners = useBanners(type);
  const lastBanner = banners[banners.length - 1];

  if (!lastBanner) return <></>;
  return (
    <article className="banner">
      <a
        target={"_blank"}
        rel="noreferrer"
        href={lastBanner.banner_url}
        className="join-banner"
      >
        <div className="banner-contents">
          <img src={resourcePath(lastBanner.image_filename)} alt="이미지" />
        </div>
      </a>
    </article>
  );
};

export default Banner;
