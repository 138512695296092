import { useState } from "react";

import { IClinicEmployeeView } from "api/clinic/types";
import StaffAddPopup from "components/Popups/StaffAddPopup";
import StaffRemovePopup from "components/Popups/StaffRemovePopup";

import useLectureEmployees from "hooks/clinic/useLectureEmployees";

const LecturePage = () => {
  const [showAddPopup, setShowAddPopup] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] =
    useState<IClinicEmployeeView | null>(null);
  const {
    employees,
    handler: { setEmployees },
  } = useLectureEmployees();

  const onRemoveSuccess = () => {
    setEmployees(
      employees.filter(
        (employee) => employee.employee_id !== selectedEmployee?.employee_id
      )
    );
    setSelectedEmployee(null);
  };

  return (
    <>
      <section className="my-corp-job">
        <article className="lnb-contents-tit">
          <div className="tit-box">
            <i className="ic ic-edu"></i>
            <span className="txt">법정의무교육 관리</span>
          </div>

          <div className="option-box">
            <a
              href="#none"
              className="option-btn type1"
              onClick={() => setShowAddPopup(true)}
            >
              직원 추가
            </a>
          </div>
        </article>

        <article className="my-group-head-box">
          <div className="group-head-box">
            <div className="left-box">
              <div className="cnt-box">총 {employees.length}건</div>
            </div>
            <div className="right-box"></div>
          </div>
        </article>

        <article className="page-list-table">
          <div className="table-box">
            <table>
              <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "25%" }} />
                <col style={{ width: "auto" }} />
                <col style={{ width: "15%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>이름</th>
                  <th>아이디</th>
                  <th>연락처</th>
                  <th className="center">관리</th>
                </tr>
              </thead>
              <tbody>
                {employees.map((employee) => (
                  <tr>
                    <td>
                      <div className="mobile-head-box">이름</div>
                      <div className="con-box">{employee.name}</div>
                    </td>
                    <td>
                      <div className="mobile-head-box">아이디</div>
                      <div className="con-box">{employee.employee_id}</div>
                    </td>
                    <td>
                      <div className="mobile-head-box">연락처</div>
                      <div className="con-box">{employee.phone_number}</div>
                    </td>
                    <td className="center">
                      <div className="mobile-head-box">관리</div>
                      <div className="con-box">
                        <a
                          href="#none"
                          className="remove-btn"
                          onClick={() => setSelectedEmployee(employee)}
                        >
                          <span className="txt">삭제</span>
                          <i className="ic ic-remove"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </article>
      </section>

      {showAddPopup && (
        <StaffAddPopup
          handleAdd={(employee) => setEmployees([...employees, employee])}
          handleClose={() => setShowAddPopup(false)}
        />
      )}
      {selectedEmployee && (
        <StaffRemovePopup
          employee={selectedEmployee}
          onSuccess={onRemoveSuccess}
          handleClose={() => setSelectedEmployee(null)}
        />
      )}
    </>
  );
};
export default LecturePage;
