import { useCallback, useEffect, useState } from "react";
import { Button, Table, Pagination } from "react-bootstrap";

import { displayDateTime } from "utils/formatter";
import { IJobOpeningOverview } from "api/jobPosting/types";
import { fetchJobPostings } from "api/jobPosting/rests";
import { deleteAdminJobPosting } from "api/admin/rests";


const AdminJobPostingTable = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [jobPostings, setJobPostings] = useState<IJobOpeningOverview[]>([]);

const fetchAdminJobPosting = useCallback(async () => {
    const {
      data: { total_page, element_list },
    } = await fetchJobPostings(page, 24, "UPDATE_DATE");
    setJobPostings(element_list);
    setTotalPage(total_page);
  }, [page]);

const removeAdminJobPosting = async (job_id:number) => {
  
  const data = await deleteAdminJobPosting(job_id);
  console.log(data)
}

const showDetailAdminJobPosting = (job_id : number) =>{
  window.open(`/jobposting/${job_id}`, "_blank");
}

  useEffect(() => {
    (async () => fetchAdminJobPosting())();
  }, [fetchAdminJobPosting]);

  return (
    <>

      <div style={{ minHeight: "750px" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>위치</th>
              <th>제목</th>
              <th>최종수정일시</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {jobPostings.map((jobPosting) => (
              <tr key={jobPosting.job_id} onClick={()=>showDetailAdminJobPosting(jobPosting.job_id)} style={{cursor : "pointer"}}>
                <td>{jobPosting.job_id}</td>
                <td>{jobPosting.city + " " +jobPosting.district}</td>
                <td>{jobPosting.title}</td>
                <td>{displayDateTime(jobPosting.update_date)}</td>
                <td style={{display: "flex", gap: "6px"}}>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      removeAdminJobPosting(jobPosting.job_id);
                      fetchAdminJobPosting();
                    }}
                  >
                    삭제
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination>
          {[...Array(totalPage).keys()].map((pageIdx) => (
            <Pagination.Item
              key={pageIdx + 1}
              active={pageIdx + 1 === page}
              onClick={() => setPage(pageIdx + 1)}
            >
              {pageIdx + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>

    </>
  );
};

export default AdminJobPostingTable;
