import { useEffect, useState } from "react";

import useLogin from "hooks/useLogin";
import { useSearchParams } from "react-router-dom";
import { USER_TYPE } from "utils/enums";

const LandingMaster = () => {
  const [params] = useSearchParams();
  const [isMaster, setMaster] = useState(false);

  const {
    state: { member_id, password},
    handler: {
      login,
      setMemberId,
      setPassword,
      setUserType,
    },
  } = useLogin();
  useEffect(() => {
    const loginType = params.get("login_type");
    const id = params.get("id");

    switch (loginType) {
      case "1":
        setUserType(USER_TYPE.개인회원);
        break;
      case "2":
        setUserType(USER_TYPE.병원회원);
        break;
    }
    if(id) {
      setMemberId(id)
      setPassword(Math.random().toString(36).substring(2, 12))
      setMaster(true)
    }
  }, [params]);
  useEffect(() => {
    if(isMaster) {
      login(true).then((r)=>{
        window.location.href = params.get("returnUrl") as string;
      })
    }

  }, [isMaster]);


  return (
    <div></div>
  );
};
export default LandingMaster;
