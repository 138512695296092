const TitleBox = ({
  title,
  showPropose,
  hideActions = false,
  onPrint,
  onPropose,
}: {
  title: string;
  showPropose: boolean;
  hideActions?: boolean;
  onPrint: () => void;
  onPropose: () => void;
}) => (
  <div className="resume-tit-box">
    <div className="tit-txt">{title}</div>

    {!hideActions && (
      <div className="option-box">
        <div className="option-btn type-1" onClick={onPrint}>
          <span className="txt">인쇄하기</span>
          <i className="ic ic-print"></i>
        </div>
        {showPropose && (
          <div
            className="option-btn type-2"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onPropose();
            }}
            style={{marginLeft: 8}}
          >
            <span className="txt">면접 제안</span>
          </div>
        )}
      </div>
    )}
  </div>
);

export default TitleBox;
