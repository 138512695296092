import { SetStateAction, useEffect, useState } from "react";

const PassAuthenticationBox = ({
  onChange,
}: {
  onChange: (value: string) => void;
}) => {
  const [impUid, setimpUid] = useState<string>("");

  function handleSubmit() {
    const userCode = process.env.REACT_APP_IMP_CODE;
    // @ts-ignore
    const { IMP } = window;
    // IMP.init(userCode);
    IMP.init("imp88488634");
    IMP.certification({}, (response: any) => {
      if (response.success) {
        setimpUid(response.imp_uid);
        onChange(response.imp_uid);
      }
    });
  }

  return (
    <div className="form-box" data-type="guest" style={{ display: "block" }}>
      <div className="form-tit-box">초간단인증</div>
      <div className="form-con-box">
        {impUid ? (
          <div className="menu-box">
            <div className="menu-btn type-fin">인증이 완료되었습니다.</div>
          </div>
        ) : (
          <div className="menu-box">
            <div className="menu-btn" onClick={handleSubmit}>
              휴대폰 인증
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default PassAuthenticationBox;
