import { SetStateAction, useEffect, useState } from "react";
import usePolicies from "hooks/policy/usePolicies";
import { USER_TYPE } from "utils/enums";
import { IMemberPolicyAgreeRequest } from "api/member/types";
import { POLICY_PATH } from "routes";
import { useMediaQuery } from "react-responsive";
import { newWindowURL } from "utils/appBridge";
import { IPolicyView } from "api/policy/types";

function popupwindow(url: string, title: string, w: number, h: number) {
  var left = window.screen.width / 2 - w / 2;
  var top = window.screen.height / 2 - h / 2;
  return window.open(
    url,
    title,
    "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left
  );
}

const Term = ({
  checked,
  type,
  require_flag,
  policy_id,
  title,
  onChange,
}: {
  checked: boolean;
  type:string;
  require_flag: boolean;
  policy_id: number;
  title: string;
  onChange: () => void;
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <div className="terms-row">
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="subject-box">
        <span
          className={`txt ${require_flag ? "type-accent" : ""}`}
          onClick={() => {
            if (isTabletOrMobile)
              newWindowURL(
                `${POLICY_PATH}?type=${type}&title=${title}`
              );
          }}
        >
          {require_flag ? <span className="ess">(필수)</span> : "(선택)"}{" "}
          {title}
        </span>
      </div>

      <div className="detail-box">
        <a
          // href={}
          onClick={() =>
            popupwindow(
              `${POLICY_PATH}?policy_id=${policy_id}&noheader=true`,
              title,
              600,
              800
            )
          }
          // target="_blank"
          className="detail-btn"
          role="button"
        >
          상세보기
        </a>
      </div>

      <div className="chk-box">
        <div className="cbox static">
          <label>
            <input type="checkbox" checked={checked} onChange={onChange} />
            <i></i>
          </label>
        </div>
      </div>
    </div>
  );
};

const TermsBox = ({
  userType,
  onChange,
  setDisabled,
}: {
  userType: USER_TYPE;
  onChange: (agreeFields: IMemberPolicyAgreeRequest[]) => void;
  setDisabled?: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const privacyPolicies = usePolicies(userType, "PRIVACY")
    .sort((a, b) => b.policy_id - a.policy_id).filter((v,i,arr)=>arr.findIndex(x => x.title === v.title) === i)

    const ternmPolicies = usePolicies(userType, "TERM")
    .sort((a, b) => b.policy_id - a.policy_id).filter((v,i,arr)=>arr.findIndex(x => x.title === v.title) === i)

  const [selectedPolicyIds, setSelectedPolicyIds] = useState<Array<number>>([]);

  const handleChange = (clickedId: number) => {
    let newValue: Array<number> = [];

    if (selectedPolicyIds.includes(clickedId)) {
      newValue = selectedPolicyIds.filter((item) => item !== clickedId);
    } else {
      newValue = [...selectedPolicyIds, clickedId];
    }

    setSelectedPolicyIds(newValue);

    const policyAgreeField: IMemberPolicyAgreeRequest[] = privacyPolicies
      .concat(ternmPolicies)
      .map((policy) => {
        return {
          policy_id: policy.policy_id,
          agree: newValue.includes(policy.policy_id),
        };
      });
    onChange(policyAgreeField);
  };

  const isAllChecked =
    selectedPolicyIds.length === privacyPolicies.length + ternmPolicies.length;

  const onAll = () => {
    const privacyIds = privacyPolicies.map((policy) => policy.policy_id);
    const ternmIds = ternmPolicies.map((policy) => policy.policy_id);
    const allIds = [...privacyIds, ...ternmIds];

    setSelectedPolicyIds(allIds);
    const policyAgreeField: IMemberPolicyAgreeRequest[] = allIds.map((id) => ({
      policy_id: id,
      agree: true,
    }));
    onChange(policyAgreeField);
  };

  const offAll = () => {
    setSelectedPolicyIds([]);
    onChange([]);
  };

  useEffect(() => {
    !isAllChecked
      ? setDisabled && setDisabled(false)
      : setDisabled && setDisabled(true);
  }, [isAllChecked, setDisabled]);

  return (
    <div className="form-box">
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="form-tit-box">약관동의</div>
      <div className="form-con-box">
        <div className="terms-box">
          <div className="terms-row">
            <div className="subject-box">
              <span className="all-txt">전체동의</span>
            </div>

            <div className="chk-box">
              <div className="cbox static">
                <label>
                  <input
                    type="checkbox"
                    checked={isAllChecked}
                    onChange={isAllChecked ? offAll : onAll}
                  />
                  <i></i>
                </label>
              </div>
            </div>
          </div>

          {privacyPolicies
            .concat(ternmPolicies)
            // .sort((a, b) =>
            //   a.require_flag !== b.require_flag
            //     ? a.require_flag
            //       ? -1
            //       : 1
            //     : a.policy_id - b.policy_id
            // )
            .map((policy) => (
              <Term
                key={policy.policy_id}
                {...policy}
                checked={selectedPolicyIds.includes(policy.policy_id)}
                onChange={() => handleChange(policy.policy_id)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default TermsBox;
