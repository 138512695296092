import { IResumeView } from "api/resume/types";
import { displayDate } from "utils/formatter";
import { InfoRow, RangeInfoRow, SectionTitle } from "./Comps";

type TProps = Pick<
  IResumeView,
  | "last_education_type"
  | "last_education_name"
  | "last_education_start_date"
  | "last_education_end_date"
>;
const EducationBox = ({
  last_education_type,
  last_education_name,
  last_education_start_date,
  last_education_end_date,
}: TProps) => (
  <div className="resume-data-group">
    <SectionTitle title="최종 학력 정보" />

    <div className="data-list-box">
      <InfoRow label="최종학력" value={last_education_type} />
      <InfoRow label="학교명" value={last_education_name} />
      {/* <div className="row">
        <div className="subject">학과명</div>
        <div className="con">치과대학</div>
      </div> */}
      <RangeInfoRow
        label="재학기간"
        startValue={displayDate(last_education_start_date)}
        endValue={displayDate(last_education_end_date)}
      />
    </div>
  </div>
);
export default EducationBox;
