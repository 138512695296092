const win = window as any;

win.setFcmToken = (token: string) => {
  if (!token) return;
  const KEY_FCM_TOKEN = "fcmToken";
  localStorage.setItem(KEY_FCM_TOKEN, token);
};

export const bridgeLogin = (accessToken: string, refreshToken: string) => {
  if (win.appBridge) {
    win.appBridge.postMessage(
      JSON.stringify({
        key: "token",
        value: { access_token: accessToken, refresh_token: refreshToken },
      })
    );
  }
};
export const bridgeLogout = () => {
  if (win.appBridge) {
    win.appBridge.postMessage(
      JSON.stringify({
        key: "logout",
        value: "",
      })
    );
  }
};
export const bridgeAlert = (text: string) => {
  if (win.appBridge) {
    win.appBridge.postMessage(
      JSON.stringify({
        key: "alert",
        value: text,
      })
    );
  }
};
(function (wAlert) {
  window.alert = function (text: string) {
    bridgeAlert(text);
    wAlert(text);
  };
})(window.alert);
export const loadFCM = () => {
  if (win.appBridge) {
    win.appBridge.postMessage(
      JSON.stringify({ key: "getFcmToken", value: {} })
    );
  }
};

export const newWindowURL = (url: string) => {
  if (win.appBridge) {
    win.appBridge.postMessage(
      JSON.stringify({
        key: "navigateURL",
        value: `${window.location.hostname}/${url}`,
      })
    );
    return;
  }
  window.open(url);
  return;
};
