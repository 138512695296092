import {createContext, useEffect} from "react";

import Layout from "layouts";
import { AddressFieldRow, InputFieldRow } from "components/FormFields";
import DescriptionForm from "components/JobPosting/DescriptionForm";
import PhotoForm from "components/JobPosting/PhotoForm";

import useRegisterJobPosting from "hooks/jobPosting/useRegisterJobPosting";
import PhotoIdBox from "components/PhotoIdBox";
import { useSearchParams } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";

export const DescriptionContext = createContext<any>(null);

const JobPostingFormPage = () => {
  const {
    state,
    handler: {
      setClinicName,
      setAddress,
      setExtraAddress,
      setPhoneNumber,
      setLogoImageFilename,
      setImageFilenameList,
      sethomepageUrl,
      ...descriptionHandler
    },
  } = useRegisterJobPosting();
  const {
    clinic_name,
    logo_image_filename,
    homepage_url,
    address = "",
    extra_address,
    phone_number,
    image_filename_list,
    ...descriptionState
  } = state;

  const [searchParams] = useSearchParams();
  const isIntern = searchParams.get("isIntern") === "on";

  const { isSignIn } = useUserContext();
  useEffect(() => {
    const master = searchParams.get("master")

    if (!isSignIn) {
      window.location.href = "/login";
    }

  }, [isSignIn]);
  return (
    <Layout>
      <section className="job-register">
        <div className="inner wrap">
          <div className="job-register-head">
            <div className="head-box">
              <i className="ic ic-register"></i>
              <span className="txt">
                {isIntern ? "실습공고 작성" : "공고 작성"}
              </span>
            </div>
          </div>

          <article className="job-register-corp-info">
            <div className="corp-info-group">
              <div className={`profile-box`}>
                <PhotoIdBox
                  label="대표 사진추가"
                  popupTitle="로고등록"
                  isResume={false}
                  photoUrl={logo_image_filename || undefined}
                  onChange={setLogoImageFilename}
                />
              </div>

              <div className="corp-info-box">
                <InputFieldRow
                  label="병원명*"
                  value={clinic_name}
                  onChange={setClinicName}
                  placeholder="병원명을 입력해주세요"
                />

                {/* <InputFieldRow
                  label="주소*"
                  value={address}
                  onChange={setAddress}
                  placeholder="주소를 입력해주세요"
                /> */}
                <div className="row">
                  <div className="subject">주소*</div>
                  <div className="con">
                    <AddressFieldRow
                      value={address}
                      onChange={(value) => setAddress(value)}
                    />
                  </div>
                </div>

                <InputFieldRow
                  label="상세주소"
                  value={extra_address}
                  onChange={(value) => setExtraAddress(value)}
                  placeholder="상세주소를 입력해주세요"
                />

                <InputFieldRow
                  label="홈페이지"
                  value={homepage_url}
                  onChange={sethomepageUrl}
                  placeholder="홈페이지 주소를 입력해주세요"
                />

                <InputFieldRow
                  label="대표번호"
                  value={phone_number}
                  onChange={setPhoneNumber}
                  numberOnly
                  placeholder="대표번호를 입력해주세요 (-없이 숫자만 입력)"
                />
              </div>
            </div>
          </article>

          <PhotoForm
            photos={image_filename_list}
            onUpload={setImageFilenameList}
            isResume={false}
          />
          <DescriptionContext.Provider
            value={{ ...descriptionState, handler: descriptionHandler }}
          >
            <DescriptionForm isIntern={isIntern} />
          </DescriptionContext.Provider>
        </div>
      </section>
    </Layout>
  );
};
export default JobPostingFormPage;
