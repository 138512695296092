import { createContext, useContext } from "react";

import { ILectureCourseBookmarkView } from "api/education/types";

interface IBookmarkContext {
  bookmarks: Array<ILectureCourseBookmarkView>;
  handler: {
    addBookmark: (bookmark: ILectureCourseBookmarkView) => void;
    removeBookmark: (courseId: number) => void;
  };
}

const BookmarkContext = createContext<IBookmarkContext>({
  bookmarks: [],
  handler: {
    addBookmark: () => {},
    removeBookmark: () => {},
  },
});

export const useBookmarkContext = () => {
  const context = useContext(BookmarkContext);
  if (!context) {
    throw new Error(
      "useBookmarkContext must be used within a BookmarkContext.Provider"
    );
  }

  return context;
};
export default BookmarkContext;
