import { useEffect, useReducer } from "react";

import { IMemberUpdateRequest } from "api/member/types";
import { updateMember } from "api/member/rests";
import useProfile from "hooks/useProfile";
import { GENDER } from "utils/enums";

enum ACTIONS {
  UPDATE_INITIAL = "UPDATE_INITIAL",
  SET_VALUE = "SET_VALUE",
}

type State = IMemberUpdateRequest & {
  birth_date: string;
  phone_number: string; // birth_date, phone_number PASS인증으로 수정되지만, 단순 표시용.
  occupation: string;
};
type Action =
  | {
      type: ACTIONS.UPDATE_INITIAL;
      payload: Partial<State>;
    }
  | {
      type: ACTIONS.SET_VALUE;
      payload: {
        key: string;
        value: string | GENDER;
      };
    };

const initialState: State = {
  name: "",
  gender: GENDER.남,
  email: "",
  birth_date: "",
  phone_number: "",
  image_filename: "",
  occupation: "",
  auth_code: "",
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_INITIAL:
      return { ...state, ...action.payload };
    case ACTIONS.SET_VALUE:
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    default:
      throw new Error(`Unknown action type: ${action}`);
  }
};

const useMemberProfile = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const profile = useProfile();

  const _getValue = (key: keyof State) => state[key];
  const _setValue = (key: string, newValue: string) =>
    dispatch({
      type: ACTIONS.SET_VALUE,
      payload: { key: key, value: newValue },
    });

  const updateProfile = async (key: keyof State) =>
    await updateMember({ [key]: _getValue(key) });

  const updatePhoneInfo = async (code: string) =>
    await updateMember({ auth_code: code });

  useEffect(() => {
    if (profile) {
      dispatch({
        type: ACTIONS.UPDATE_INITIAL,
        payload: profile,
      });
    }
  }, [profile]);

  return {
    state,
    handler: {
      // setName: (name: string) => _setValue("name", name),
      // updateName: () => updateProfile("name"),
      setOccupation: (occupation: string) =>
        _setValue("occupation", occupation),
      updateOccupation: () => updateProfile("occupation"),
      setEmail: (email: string) => _setValue("email", email),
      updateEmail: () => updateProfile("email"),
      setImage: (filename: string) => _setValue("image_filename", filename),

      updateAuthCode: (code: string) => updatePhoneInfo(code),
    },
  };
};

export default useMemberProfile;
