import { useState, useRef, ChangeEvent } from "react";

interface IProps {
  onClear: () => void;
  onClose: () => void;
  onImageChange: (image: File) => void;
  onUpload: () => void;
}

const useMenuBox = ({ onClear, onClose, onImageChange, onUpload }: IProps) => {
  const [filename, setFilename] = useState<string>("첨부파일");

  const handleChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      setFilename(file.name);
      onImageChange(file);
    }
  };

  const handleUpload = () => onUpload();

  const handleClear = () => {
    setFilename("");
    onClear();
  };
  const handleClose = () => onClose();

  return {
    filename,
    handler: {
      handleClear,
      handleUpload,
      handleClose,
      handleChangeImage,
    },
  };
};

const MenuBox = (props: IProps) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const {
    filename,
    handler: { handleClear, handleClose, handleChangeImage, handleUpload },
  } = useMenuBox(props);

  return (
    <div className="img-menu-box">
      <div className="upload-box">
        <div className="tbox">
          <input
            type="file"
            accept="image/*"
            ref={fileRef}
            style={{ display: "none" }}
            onChange={handleChangeImage}
          />
          <input
            type="text"
            className="readonly-bg"
            readOnly={true}
            value={filename}
          />
        </div>
        <button
          className="upload-btn"
          onClick={() => fileRef.current && fileRef.current.click()}
        >
          찾기
        </button>
        <input type="file" style={{ display: "none" }} />
      </div>

      <div className="menu-box">
        <button className="menu-btn type-1" onClick={handleUpload}>
          업로드
        </button>
        <button className="menu-btn type-2" onClick={handleClear}>
          초기화
        </button>
        <button className="menu-btn type-3" onClick={handleClose}>
          취소
        </button>
      </div>
    </div>
  );
};
export default MenuBox;
