import { useEffect, useState } from "react";

import { fetchEducationNotices } from "api/education/rests";
import { INoticeView } from "api/notice/types";

const useEducationNotices = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [notices, setNotices] = useState<INoticeView[]>([]);

  useEffect(() => {
    (async () => {
      const {
        data: { total_page, element_list },
      } = await fetchEducationNotices(page);
      setNotices(element_list);
      setTotalPage(total_page);
    })();
  }, [page]);

  return { page, totalPage, notices, handler: { setPage } };
};
export default useEducationNotices;
