const Salary = ({
  salary_type,
  tax_type,
  salary,
}: {
  salary_type: string;
  tax_type: string;
  salary: number;
}) => (
  <>
    {salaryTypeToLabel(salary_type)} {salary !== null ? salary.toLocaleString() : ''}{" "}
    {tax_type ? taxTypeToLabel(tax_type) : ''}
  </>
);
export default Salary;

const salaryTypeToLabel = (SalaryType: string) => {
  switch (SalaryType) {
    case "ANNUAL":
      return "연봉";
    case "HOURLY":
      return "시급";
    case "MONTHLY":
      return "월급";
    default:
      return "면접 시 협의";
  }
};
const taxTypeToLabel = (taxType: string) =>
  taxType === "BEFORE" ? "세전" : "세후";
