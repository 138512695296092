import axiosClient from "../axiosClient";

import { IPageResponse, IResponse, IUploadFileView } from "api/types";
import { setTokens } from "utils/storage";
import {
  IAdminView,
  IAdminMemberView,
  IAdminMemberQuery,
  IAdminClinicMemberView,
  IAdminClinicMemberQuery,
  IAdminMainNoticeView,
  IAdminMainNoticeRequest,
  IAdminMainBannerView,
  IAdminMainBannerRequest,
} from "./types";
import {
  ILectureView,
  ILectureCourseView,
  ILectureCourseUpdateRequest,
  ILectureCourseCreateRequest,
  ILectureRequest,
  ILectureCourseNoticeRequest,
    IPageViewNoticeView,
    ILectureCreateRequest,
} from "api/education/types";
import { INoticeView } from "api/notice/types";

/**
 * 인증 체크.
 * 배너 정보 조회 GET /admin/banner  사용.
 */
export const checkAdmin = async () =>
  await axiosClient.get<IResponse<any>>("/admin/banner?banner_type=");

/**
 * POST
/admin/login
관리자 로그인

관리자 로그인 API
 */
export const loginAdmin = async (admin_id: string, password: string) => {
  const resp = await axiosClient.post<IResponse<IAdminView>>("/admin/login", {
    admin_id,
    password,
  });
  const { data } = resp.data;
  const { authorization } = resp.headers;

  console.log(authorization);

  console.log(axiosClient.defaults.headers.common);

  axiosClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${authorization}`;

  console.log(axiosClient.defaults.headers.common);

  console.log(authorization);

  // setAuth({
  //   member_id: data.admin_id,
  //   name: data.name,
  //   user_type: USER_TYPE.개인회원,
  // });
  setTokens({
    token: authorization,
    refresh_token: "",
  });

  return resp.data;
};

/****************
 ** 개인 회원
 ****************/

/**
 * 개인 회원 조회
 * GET /admin/member
 */
export const fetchMembers = async (page: number) => {
  const resp = await axiosClient.get<IPageResponse<IAdminMemberView[]>>(
    `/admin/member?page=${page}&size=15&sort_property=MEMBER_ID&sort_direction=DESC`
  );

  return resp.data;
};

/**
 * 개인 회원 계정 정지 또는 재개
 * PATCH /admin/member/active/{member_id}
 */
export const activeMember = async (member_id: string, active_flag: boolean) => {
  const resp = await axiosClient.patch<IResponse<IAdminMemberView[]>>(
    `/admin/member/active/${member_id}?member_id=${member_id}&active_flag=${active_flag}`
  );

  return resp.data;
};

/**
 * 개인 회원 조회
 * GET /admin/member/{member_id}
 */
export const fetchMember = async (member_id: string) => {
  const resp = await axiosClient.get<IResponse<IAdminMemberView>>(
    `/admin/member/${member_id}`
  );

  return resp.data;
};

/**
 * 개인 회원 검색
 * POST /admin/member/search
 */
export const searchMember = async (page: number, query: IAdminMemberQuery) => {
  const resp = await axiosClient.post<IPageResponse<IAdminMemberView[]>>(
    `/admin/member/search`,
    {
      page,
      size: 15,
      query,
    }
  );

  return resp.data;
};

/**
 * 개인 회원 정보 수정
 * PATCH /admin/member/{member_id}
 */
export const updateMemberInfo = async (member_id: string, params: any) => {
  const resp = await axiosClient.patch<IResponse<IAdminMemberView>>(
    `/admin/member/${member_id}`,
    params
  );

  return resp.data;
};

/****************
 ** 병원 회원
 ****************/

/**
 * 병원 회원 조회
 * GET /admin/clinic
 */
export const fetchClinicMembers = async (page: number) => {
  const resp = await axiosClient.get<IPageResponse<IAdminClinicMemberView[]>>(
    `/admin/clinic?page=${page}&size=15&sort_property=REG_DATE&sort_direction=DESC`
  );

  return resp.data;
};

/**
 * 병원 회원 계정 정지 또는 재개
 * PATCH /admin/clinic/active/{member_id}
 */
export const activeClinicMember = async (
  member_id: string,
  active_flag: boolean
) => {
  const resp = await axiosClient.patch<IResponse<IAdminClinicMemberView[]>>(
    `/admin/clinic/active/${member_id}?active_flag=${active_flag}`
  );

  return resp.data;
};

/**
 * 병원 회원 조회
 * GET /admin/clinic/{member_id}
 */
export const fetchClinicMember = async (member_id: string) => {
  const resp = await axiosClient.get<IResponse<IAdminClinicMemberView>>(
    `/admin/clinic/${member_id}`
  );

  return resp.data;
};

/**
 * 병원 회원 검색
 * POST /admin/clinic/search
 */
export const searchClinicMember = async (
  page: number,
  query: IAdminClinicMemberQuery
) => {
  const resp = await axiosClient.post<IPageResponse<IAdminClinicMemberView[]>>(
    `/admin/clinic/search`,
    {
      page,
      size: 15,
      query,
    }
  );

  return resp.data;
};

/**
 * 병원 회원 정보 수정
 * PATCH /admin/clinic/{member_id}
 */
export const updateClinicMember = async (member_id: string, params: any) => {
  const resp = await axiosClient.patch<IResponse<IAdminClinicMemberView>>(
    `/admin/clinic/${member_id}`,
    params
  );

  return resp.data;
};

/****************
 ** 공고 관리
 ****************/

 /**
 * 관리자 공고 삭제 
 * DELETE /job/${job_id}
 */

 export const deleteAdminJobPosting = async (job_id : number) => {
  const resp = await axiosClient.delete<IResponse<any>>(`/admin/job/${job_id}`);

  return resp.data;
 }


/****************
 ** 이력서 관리
 ****************/

/**
 * 관리자 이력서 삭제 
 * DELETE /resume/${resume_id}
 */

 export const deleteResume = async (resume_id:number) => {
  const resp = await axiosClient.delete<IResponse<any>>(`/admin/resume/${resume_id}`);

  return resp.data;
} 

/****************
 ** 온라인 교육
 ****************/

/**
 * 온라인 교육 강의 코스 조회
 * GET /admin/education/lecture/course
 */
export const fetchEducations = async (
  lecture_type: "LEGAL" | "DENTIN" | "HEALTH" | "ETC",
  page: number
) => {
  const resp = await axiosClient.get<IPageResponse<ILectureCourseView[]>>(
    `/admin/education/lecture/course?lecture_type=${lecture_type}&page=${page}&size=10&sort_property=REG_DATE&sort_direction=DESC`
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 코스 수정
 * PUT /admin/education/lecture/course/{course_id}
 */
export const updateCourse = async (
  course_id: number,
  params: ILectureCourseUpdateRequest
) => {
  const resp = await axiosClient.put<IResponse<ILectureCourseView>>(
    `/admin/education/lecture/course/${course_id}`,
    params
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 코스 삭제
 * DELETE /admin/education/lecture/course/{course_id}
 */
 export const deleteCourse = async (
  course_id: number,
) => {
  const resp = await axiosClient.delete<IResponse<any>>(
    `/admin/education/lecture/course/${course_id}`
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 코스 등록
 * POST /admin/education/lecture/course
 */
 export const addCourse = async (params: ILectureCourseCreateRequest) => {
  const resp = await axiosClient.post<IResponse<ILectureCourseView>>(
    `/admin/education/lecture/course`,
    params
  );
};

/**
 * 온라인 교육 강의 수정
 * PATCH /admin/education/lecture/{lecture_id}
 */
export const updateLecture = async (
  lecture_id: number,
  params: ILectureRequest
) => {
  const resp = await axiosClient.patch<IResponse<ILectureView>>(
    `/admin/education/lecture/${lecture_id}`,
    params
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 등록
 * POST /admin/education/lecture/course
 */
 export const addLecture = async (params: ILectureCreateRequest) => {
  const resp = await axiosClient.post<IResponse<ILectureView>>(
    `/admin/education/lecture`,
    params
  );
};

/**
 * 온라인 교육 강의 삭제
 * DELETE /admin/education/lecture/{lecture_id}
 */
 export const deleteLecture = async (
  lecture_id: number,
) => {
  const resp = await axiosClient.delete<IResponse<any>>(
    `/admin/education/lecture/${lecture_id}`
  );

  return resp.data;
};


/** 
 GET 온라인 교육 강의 공지사항 조회
*/
export const fetchEducationNotice = async (
  lecture_type: "LEGAL" | "DENTIN" | "HEALTH" | "ETC",
  page: number,
  size: number,
  sort_direction: "ASC" | "DESC"
) => {
  const resp = await axiosClient.get<IPageResponse<Array<IPageViewNoticeView>>>(
    `education/notice?notice_type=${lecture_type}&page=${page}&size=${size}&sort_property=REG_DATE&sort_direction=${sort_direction}`
  );

  return resp.data;
};

/** 
 POST 온라인 교육 강의 공지사항 등록
*/
export const addEducationNotice = async (params: ILectureCourseNoticeRequest) => {
  const resp = await axiosClient.post<IResponse<IAdminMainNoticeView>>(
    `education/notice`,
    params
  );
};

/** 
 PUT 온라인 교육 강의 공지사항 수정
*/

export const updateEducationNotice = async (
  notice_id: number,
  params: ILectureCourseNoticeRequest
) => {
  const resp = await axiosClient.put<IResponse<IAdminMainNoticeView>>(
    `education/notice/${notice_id}`,
    params
  );

  return resp.data;
};

/** 
 DELETE 온라인 교육 강의 공지사항 삭제
*/
export const deleteEducationNotice = async (notice_id: number) => {
  const resp = await axiosClient.delete<IResponse<any>>(
    `education/notice/${notice_id}`
  );

  return resp.data;
};



/****************
 ** 공고 관리
 ****************/

/****************
 ** 메인 공지사항 관리
 ****************/

/**
 * 메인 공지사항 리스트 조회
 * GET /admin/notice
 */
export const fetchMainNoticeList = async (page: number) => {
  const resp = await axiosClient.get<IPageResponse<IAdminMainNoticeView[]>>(
    `/admin/notice?page=${page}&size=15&sort_property=REG_DATE&sort_direction=DESC`
  );

  return resp.data;
};

/**
 * 메인 공지사항 등록
 * POST /admin/notice
 */
export const addMainNotice = async (params: IAdminMainNoticeRequest) => {
  const resp = await axiosClient.post<IResponse<IAdminMainNoticeView>>(
    `/admin/notice`,
    params
  );
};

/**
 * 메인 공지사항 상세 조회
 * GET /admin/notice
 */
export const fetchMainNotice = async (notice_id: string) => {
  const resp = await axiosClient.get<IResponse<IAdminMainNoticeView[]>>(
    `/admin/notice?notice_id=${notice_id}`
  );

  return resp.data;
};

/**
 * 메인 공지사항 수정
 * PUT /admin/notice
 */
export const updateMainNotice = async (params: IAdminMainNoticeRequest , notice_id:number) => {
  const resp = await axiosClient.put<IResponse<IAdminMainNoticeView[]>>(
    `/admin/notice/${notice_id}`,
    params
  );

  return resp.data;
};

/**
 * 메인 공지사항 삭제
 * DELETE /admin/notice
 */
export const deleteMainNotice = async (notice_id: number) => {
  const resp = await axiosClient.delete<IResponse<any>>(
    `/admin/notice/${notice_id}`
  );

  return resp.data;
};

/****************
 ** 배너 관리
 ****************/

/**
 * 배너 리스트 조회
 * GET /admin/notice
 */
export const fetchBannerList = async () => {
  const resp = await axiosClient.get<IResponse<IAdminMainBannerView[]>>(
    // `/admin/banner?banner_type=MAIN`
    `/admin/banner`
  );

  return resp.data;
};

/**
 * 배너 등록
 * POST /admin/banner
 */
 export const addBanner = async ( params: IAdminMainBannerRequest) => {
  const resp = await axiosClient.post<IResponse<IAdminMainBannerView>>(
    `/admin/banner`,
    params
  );

  return resp.data;
};


/**
 * 배너 수정 
 * GET /admin/notice
 */
 export const updateBanner = async (banner_id: number, params: IAdminMainBannerRequest) => {
  const resp = await axiosClient.put<IResponse<IAdminMainBannerView[]>>(
    `/admin/banner/${banner_id}`,
    params
  );

  return resp.data;
};

/**
 * 배너 삭제
 * POST /admin/banner/${id}
 */

 export const deleteAdminBanner = async (banner_id: number) => {
  const resp = await axiosClient.delete<IResponse<any>>(
    `/admin/banner/${banner_id}`
  );

  return resp.data;
};

/**
 * 관리자 배너 이미지 파일 업로드
 * POST /admin/file/upload
 */
 export const uploadAdminImage = async (params: FormData) => {
  const resp = await axiosClient.post<IUploadFileView>(
    `/admin/file/upload`,
    params
  );
  
  return resp.data;
};