import { NoticeOverview } from "components/Lecture";

import Paginator from "components/Paginator";
import useEducationNotices from "hooks/useEducationNotices";

const LectureNoticePage = () => {
  const {
    notices,
    page,
    totalPage,
    handler: { setPage },
  } = useEducationNotices();

  return (
    <section className="edu-board">
      <article className="board-head-box">
        <div className="head-tit-box">공지사항</div>
        <div className="head-option-box"></div>
      </article>

      <article className="board-list-box">
        <div className="table-box">
          {notices.map((notice) => (
            <NoticeOverview key={notice.notice_id} notice={notice} />
          ))}
        </div>
      </article>

      {totalPage > 1 && (
        <Paginator
          currentPage={page}
          totalPage={totalPage}
          changePage={(page) => setPage(page)}
        />
      )}
    </section>
  );
};

export default LectureNoticePage;
