import { useEffect, useReducer } from "react";

/** api폴더 구조 변경할 것,,, feature 별로, profile, resume... */
import { deleteClinicMember, updateClinic } from "api/clinic/rests";
import { IClinicMemberUpdateRequest } from "api/clinic/types";
import useProfile from "hooks/useProfile";
import { logout } from "utils/storage";

enum ACTIONS {
  UPDATE_INITIAL = "UPDATE_INITIAL",
  SET_VALUE = "SET_VALUE",
}

type State = IClinicMemberUpdateRequest;
type Action =
  | {
      type: ACTIONS.UPDATE_INITIAL;
      payload: Partial<State>;
    }
  | {
      type: ACTIONS.SET_VALUE;
      payload: {
        key: string;
        value: string;
      };
    };

const initialState: State = {
  clinic_name: "",
  homepage_url: "",
  phone_number: "", // TODO: 휴대폰 수정 hook 별도 분리 할것.
  email: "",
  address: "",
  extra_address: "",
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_INITIAL:
      return { ...state, ...action.payload };
    case ACTIONS.SET_VALUE:
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    default:
      throw new Error(`Unknown action type: ${action}`);
  }
};

const useClinicProfile = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const profile = useProfile();

  const _setValue = (key: string, newValue: string) =>
    dispatch({
      type: ACTIONS.SET_VALUE,
      payload: { key: key, value: newValue },
    });
  const _getValue = (key: keyof State) => state[key];
  // const _ = <T extends object, U extends keyof T>(key: U) => (obj: T) => obj[key];

  const updateProfile = async (key: keyof State) => {
    try {
      const res = await updateClinic({ [key]: _getValue(key) });
      return res;
    } catch (e) {
      window.alert("사용할 수 없는 내용입니다. 다른 내용을 입력해주세요");
      throw e;
    }
  };

  const deleteMember = async () => {
    try {
      await deleteClinicMember();
      logout();
      alert("회원 탈퇴가 완료되었습니다.");
      window.location.href = "/"; // 로그아웃 후 main 으로 이동
    } catch (_) {
      alert("오류로 인해 탈퇴처리가 실패되었습니다.");
    }
  };

  useEffect(() => {
    if (profile) {
      dispatch({
        type: ACTIONS.UPDATE_INITIAL,
        payload: profile,
      });
    }
  }, [profile]);

  return {
    state,
    handler: {
      setClinicName: (name: string) => _setValue("clinic_name", name),
      setHomepageUrl: (url: string) => _setValue("homepage_url", url),
      setEmail: (email: string) => _setValue("email", email),
      setAddress: (address: string) => _setValue("address", address),
      setExtraAddress: (extra_address: string) =>
        _setValue("extra_address", extra_address),
      setPhoneNumber: (number: string) => _setValue("phone_number", number),

      updateClinicName: () => updateProfile("clinic_name"),
      updateHomepageUrl: () => updateProfile("homepage_url"),
      updateEmail: () => updateProfile("email"),
      updateAddress: (data: string) => updateClinic({ address: data }),
      updateExtraAddress: () => updateProfile("extra_address"),
      updatePhoneNumber: () => updateProfile("phone_number"),
      deleteClinicMember: () => deleteMember(),
    },
  };
};

export default useClinicProfile;
