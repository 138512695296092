import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Layout from "layouts";
import {searchJobPostings, searchRecommendJobPostings} from "api/jobPosting/rests";
import { IJobOpeningOverview } from "api/jobPosting/types";
import { TabSelector } from "components/JobPostingSearch/Comps";
import OverviewList from "components/JobPostingSearch/OverviewList";
import {
  AreaSelector,
  KeywordSelector,
  OccupationSelector,
  SearchTypeSelector,
  QueryBox,
} from "components/ResumeList";
import Paginator from "components/Paginator";
import { SEARCH_PAGE_SIZE } from "utils/constants";

import MapSearchSection from "./MapSearchSection";
import TrainingDentist from "./TrainingDentist";
import useKeywords from "hooks/useKeywords";
import { Banner } from "components/commons";

enum SEARCH_TYPE {
  없음 = "없음",
  지역 = "지역",
  직종 = "직종",
  키워드 = "키워드",
}

const JobPostingSearchPage = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [queryType, setQueryType] = useState<SEARCH_TYPE>(SEARCH_TYPE.없음);
  const [queries, setQueries] = useState<string[]>([]);
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("DESC");
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [jobPostings, setJobPostings] = useState<IJobOpeningOverview[]>([]);
  const [commonData, setCommonData] = useState<any>(null);
  const [recommendData, setRecommendData] = useState<any>(null);
  const [recommendFlag, setRecommendFlag] = useState<boolean>(true);

  const addQuery = (query: string) => {
    if (query) setQueries([...queries, query]);
  };
  const clearQuery = async () => {
    await setQueries([]);
    await search();
  };
  const removeQuery = (query: string) =>
    setQueries((queries) => queries.filter((q) => q !== query));

  const search = useCallback(async () => {
    let region_list: string[] = [];
    let occupation_list: string[] = [];
    let keyword_list: string[] = [];

    queries.forEach((q) => {
      const [type, query] = q.split("|");
      if (type === "a") {
        region_list.push(query);
      } else if (type === "o") {
        occupation_list.push(query);
      } else {
        keyword_list.push(query);
      }
    });

    try {
      const data = await searchJobPostings(page, SEARCH_PAGE_SIZE, {
        search_type: (tab || "staff").toUpperCase(),
        region_list,
        occupation_list,
        keyword_list,
        sort_property: "UPDATE_DATE",
        sort_direction: sortDirection,
      })
      const data2 = await searchRecommendJobPostings(page, SEARCH_PAGE_SIZE, {
        search_type: (tab || "staff").toUpperCase(),
        region_list,
        occupation_list,
        keyword_list,
        sort_property: "UPDATE_DATE",
        sort_direction: sortDirection,
      });

      setCommonData(data)
      setRecommendData(data2)

      if(data2.data.element_list.length >= 9) {
        setTotalPage(data2.data.total_page);
        setTotalElements(data2.data.total_elements);
        setJobPostings(data2.data.element_list);
      } else {
        setTotalPage(data.data.total_page);
        setTotalElements(data.data.total_elements);
        setJobPostings(data.data.element_list);
        setRecommendFlag(false)
      }

    } catch (e) {
      setTotalPage(0);
      setTotalElements(0);
      setJobPostings([]);
    }
  }, [page, queries, tab, sortDirection]);

  useEffect(() => {
    if(commonData && recommendData) {

      setPage(1)
      setTotalPage(0);
      setTotalElements(0);
      setJobPostings([]);

      if(recommendFlag && recommendData.data.element_list.length >=9) {
        setTotalPage(recommendData.data.total_page);
        setTotalElements(recommendData.data.total_elements);
        setJobPostings(recommendData.data.element_list);
      } else {
        setTotalPage(commonData.data.total_page);
        setTotalElements(commonData.data.total_elements);
        setJobPostings(commonData.data.element_list);
      }
    }

  }, [recommendFlag]);

  useEffect(() => {
    (async () => search())();
  }, [page, search]);
  const keywords = useKeywords("JOB");

  return (
    <Layout>
      <TabSelector selectedTab={tab} />
      {tab === "map" ? (
        <MapSearchSection />
      ) : tab === "intern" ? (
        <TrainingDentist />
      ) : (
        <section className="info-search-form">
          <div className="inner wrap">
            <SearchTypeSelector
              hideOccupation={tab === "dentist"}
              searchType={queryType}
              addQuery={addQuery}
              onSelect={setQueryType}
            />

            {queryType === SEARCH_TYPE.지역 && (
              <AreaSelector
                selected={queries}
                onClose={() => setQueryType(SEARCH_TYPE.없음)}
                onSelect={(areaQ: string) => addQuery(`a|${areaQ}`)}
              />
            )}
            {queryType === SEARCH_TYPE.직종 && (
              <OccupationSelector
                selected={queries}
                onClose={() => setQueryType(SEARCH_TYPE.없음)}
                onSelect={(occupationQ: string) => addQuery(`o|${occupationQ}`)}
              />
            )}
            {queryType === SEARCH_TYPE.키워드 && (
              <KeywordSelector
                recommends={keywords}
                onClose={() => setQueryType(SEARCH_TYPE.없음)}
                onSelect={(keywordQ: string) => addQuery(`k|${keywordQ}`)}
              />
            )}

            <QueryBox
              isQueryTypeSelectActive={queryType !== SEARCH_TYPE.없음}
              queries={queries}
              onClear={clearQuery}
              onSearch={search}
              onRemove={removeQuery}
            />
            <div style={{ marginTop: 30 }}>
              <Banner type="RECRUIT" />
            </div>
            <OverviewList
              sortDirection={sortDirection}
              jobPostings={jobPostings}
              totalCount={totalElements}
              recommendFlag={recommendFlag}
              onChangeRecommend={(flag:boolean)=>setRecommendFlag(flag)}
              refreshJobPostings={search}
              onChangeSortDirection={(d) => setSortDirection(d)}
            />

            {totalPage > 1 && (
              <Paginator
                currentPage={page}
                totalPage={totalPage}
                changePage={(page) => setPage(page)}
              />
            )}
          </div>
        </section>
      )}
    </Layout>
  );
};
export default JobPostingSearchPage;
