import { useEffect, useState } from "react";

import { IMemberView } from "api/member/types";
import { fetchMember } from "api/member/rests";
import { IClinicMemberView } from "api/clinic/types";
import { fetchClinicMember } from "api/clinic/rests";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";

const useProfile = () => {
  const { isSignIn, userType } = useUserContext();
  const [profile, setProfile] = useState<IMemberView | IClinicMemberView | null>(null);

  useEffect(() => {
    if (!isSignIn) return;

    (async () => {
      const { data }: {data: IMemberView | IClinicMemberView} = userType === USER_TYPE.개인회원 ? await fetchMember() : await fetchClinicMember();
      setProfile(data);
    })();
  }, [isSignIn, userType]);
  
  return profile;
};
export default useProfile;