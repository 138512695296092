import axiosClient from "api/axiosClient";
import { IResponse } from "api/types";
import { BANNER_TYPES } from "utils/constants";
import { IBannerView, TBannerType } from "./types";

/**
 * 배너 정보 조회
 * GET /banner
 */
export const fetchBanner = async (
  bannerType: TBannerType
): Promise<IResponse<IBannerView[]>> => {
  const resp = await axiosClient.get(
    `/banner/list?banner_type=${BANNER_TYPES[bannerType]}`
  );
  return resp.data;
};

/**
 * 온라인 교육 배너 정보 조회
 * GET /education/banner
 */
export const fetchEducationBanner = async (
  bannerType: TBannerType
): Promise<IResponse<IBannerView[]>> => {
  const resp = await axiosClient.get(
    `/education/banner?banner_type=${bannerType}`
  );
  return resp.data;
};
