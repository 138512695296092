import { text } from "@fortawesome/fontawesome-svg-core";
import { ChangeEvent } from "react";

const isDigit = (text:string) => (!text || text.match(/^\d+$/) ? true : false);

const InputWithToggleFieldRow = ({
  label,
  value,
  toggleValue = false,
  placeholder = "",
  type = "text",
  numberOnly = false,
  onChange,
  onChangeToggle = () => {},
}: {
  label: string;
  placeholder?: string;
  type?: string;
  value: string;
  toggleValue?: boolean;
  numberOnly?: boolean;
  onChange: (newValule: string) => void;
  onChangeToggle?: (on: boolean) => void;
}) => (
  <div className="row">
    <div className="subject">{label}</div>
    <div className="con">
      <div className="tbox">
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            {
              const text = e.target.value;
              if(numberOnly){
                if(!isDigit(text.replace(/\D/g,''))) return;
                onChange(text.replace(/\D/g,''));
                return;
              }
            return onChange(text)
          }
          }
        />
      </div>
      <div className="toggle-btn-box">
        <label className="toggle">
          <input
            type="checkbox"
            checked={toggleValue}
            onChange={() => onChangeToggle(!toggleValue)}
          />
          <span className="toggle-txt toggle-on-txt">공개</span>
          <span className="toggle-txt toggle-off-txt">비공개</span>
          <span className="toggle-bg"></span>
        </label>
      </div>
    </div>
  </div>
);

export default InputWithToggleFieldRow;
