import { IResumeView } from "api/resume/types";

type TProps = Pick<IResumeView, "cover_letter">;

const CoverLetterBox = ({ cover_letter }: TProps) => (
  <div className="resume-data-group">
    <div className="data-tit-box">자기소개서</div>

    <div
      className="data-txt-box"
      dangerouslySetInnerHTML={{
        __html: cover_letter.replaceAll("\n", "<br/>"),
      }}
    ></div>
  </div>
);
export default CoverLetterBox;
