import { useEffect, useState } from "react";

import {
  IJobOpeningClinicOverview,
  IJobActionResumeView,
} from "api/clinic/types";
import { fetchJobPostingVolunteers, jumpJobPosting } from "api/clinic/rests";
import { Area, Clinic, DueDate, Status } from "components/JobPosting/Comps";
import Volunteer from "./Volunteer";
import { JOBPOSTING_FORM_PATH, JOBPOSTING_PATH } from "routes";
import { isOverdue, displayMinMaxMonth } from "utils/formatter";

interface IProps extends IJobOpeningClinicOverview {
  handleDelete: () => void;
}

const JobPosting = ({
  job_id,
  closing_date,
  city,
  title,
  action_count,
  clinic_name,
  registration_date,
  work_type_list,
  occupation_list,
  work_list,
  min_work_experience,
  max_work_experience,
  intern_flag,
  handleDelete,
}: IProps) => {
  const [showVolunteer, setShowVolunteer] = useState<boolean>(false);
  const [volunteers, setVolunteers] = useState<IJobActionResumeView[]>([]);
  const [retentionTime, setRetentionTime] = useState(0);

  const toggleShowVolunteer = () => setShowVolunteer(!showVolunteer);

  const jump = async () => {
    if (retentionTime > 0 && retentionTime < 5) {
      alert("채용공고 재점프는 5분 이후 가능합니다.");
    }
    if (retentionTime === 0 || retentionTime > 5) {
      await jumpJobPosting(job_id);
      setRetentionTime(1);
      alert("채용공고가 점프되었습니다.");
    }
  };

  useEffect(() => {
    if (!showVolunteer) return;
    (async () => {
      const {
        data: { element_list },
      } = await fetchJobPostingVolunteers(job_id, "APPLY");
      setVolunteers(element_list);
    })();
  }, [showVolunteer, job_id]);

  useEffect(() => {
    if (retentionTime === 0) return;

    const id = setInterval(() => {
      setRetentionTime((prev) => prev + 1);
    }, 1000 * 60);

    return () => clearInterval(id);
  }, [retentionTime]);

  return (
    <li>
      <div className="item-box">
        <Status isOverdue={isOverdue(closing_date)} />
        <DueDate startDate={registration_date} endDate={closing_date} />

        <a href={`${JOBPOSTING_PATH}/${job_id}`} className="name-box">
          {`${intern_flag ? "[실습치과] " : ""}${title}`}
        </a>

        <article className="my-summary-box">
          <div className="summary-box">
            <Clinic clinic={clinic_name} />
            <Area area={city} />
          </div>
        </article>

        <article className="my-information-box">
          <div className="information-box">
            <div className="information-txt">{work_type_list.join(", ")}</div>
            <div className="information-txt">{occupation_list.join(", ")}</div>
            <div className="information-txt">
              {displayMinMaxMonth(
                min_work_experience * 12,
                max_work_experience * 12
              )}
            </div>
            <div className="information-txt">{work_list.join(", ")}</div>
          </div>
        </article>

        <article className="my-menu-box m-other-type1">
          <div className="menu-box">
            <div className="menu-btn type-small type-4" onClick={jump}>
              <span className="txt">점프</span>
              <i className="ic ic-jump"></i>
            </div>
            <button
              className="menu-btn type-small type-4"
              onClick={toggleShowVolunteer}
            >
              <span className="txt">
                <span className="accent">{action_count}</span>명의 지원자
              </span>
              <i className="ic ic-arrow"></i>
            </button>

            <div className="right-icon">
              <a
                href={`${JOBPOSTING_FORM_PATH}?job_id=${job_id}&mode=edit${
                  intern_flag ? "?isIntern=on" : ""
                }`}
                className="menu-btn type-small type-7"
              >
                <span className="txt">수정</span>
              </a>
              <div
                className="menu-btn type-small type-7"
                onClick={handleDelete}
              >
                <span className="txt">삭제</span>
                <i className="ic ic-remove"></i>
              </div>
            </div>
          </div>
        </article>
      </div>

      {showVolunteer && (
        <div className="volunteer-box on">
          <div className="volunteer-list">
            {volunteers.map((volunteer) => (
              <Volunteer key={volunteer.resume_id} {...volunteer} />
            ))}
          </div>
        </div>
      )}
    </li>
  );
};
export default JobPosting;
