import { useEffect, useState } from "react";
import moment from "moment";

import { IResumeEditRequest } from "api/member/types";
import { IResumeWorkExperienceView } from "api/resume/types";
import { CareerFieldBox } from "components/ResumeForm/Comps";
import { CAREER_TYPE } from "utils/enums";
import { monthToYearMonth } from "utils/formatter";

type TProps = Pick<
  IResumeEditRequest,
  "work_experience_list" | "work_experience_month"
> & {
  onChangeValue: (
    key: "work_experience_list" | "work_experience_month",
    newValue: Array<IResumeWorkExperienceView> | Number
  ) => void;
};

const initialValue: IResumeWorkExperienceView = {
  company_name: "",
  work: "",
  start_date: "",
};

const useCareer = ({
  work_experience_list: initials,
  onChangeValue,
}: TProps) => {
  const [careers, setCareers] = useState<IResumeWorkExperienceView[]>(
    initials || []
  );
  const [careerType, setCareerType] = useState<CAREER_TYPE>(
    (initials || []).length > 0 ? CAREER_TYPE.경력 : CAREER_TYPE.신입
  );

  const updateValue = (value: IResumeWorkExperienceView[]) => {
    const days = value
      .filter((v) => !!v.start_date && !!v.end_date)
      .reduce(
        (total, v) =>
          total + moment(v.end_date).diff(moment(v.start_date), "days"),
        0
      );
    onChangeValue("work_experience_list", value);
    onChangeValue("work_experience_month", Math.floor(days / 30));
    setCareers(value);
  };

  useEffect(() => {
    setCareers(initials || []);
    setCareerType(
      (initials || []).length > 0 ? CAREER_TYPE.경력 : CAREER_TYPE.신입
    );
  }, [initials]);

  return {
    /**
     * 신입 | 경력
     */
    careerType,
    /**
     * 경력일 경우, 경력 리스트
     */
    careers,
    handler: {
      onNewb: () => {
        setCareerType(CAREER_TYPE.신입);
        updateValue([]);
      },
      onOldb: () => {
        setCareerType(CAREER_TYPE.경력);
        careers.length === 0 && updateValue([{ ...initialValue }]);
      },
      addCareer: () => {
        setCareers([...careers, { ...initialValue }]);
      },
      updateCareer: (idx: number, license: IResumeWorkExperienceView) => {
        const newCareers = [...careers];
        newCareers[idx] = license;
        updateValue(newCareers);
      },
      removeCareer: (idx: number) => {
        if (careers.length > 1) {
          updateValue([...careers.slice(0, idx), ...careers.slice(idx + 1)]);
        }
      },
    },
  };
};

const CareerSection = (props: TProps) => {
  const {
    careerType,
    careers,
    handler: { onNewb, onOldb, addCareer, updateCareer, removeCareer },
  } = useCareer(props);

  const { year, month } = monthToYearMonth(props.work_experience_month);

  return (
    <div className="form-group">
      <div className="form-tit-box">경력사항</div>

      <div className="form-tabs-select-box">
        <button
          className={`tabs-select-btn ${
            careerType === CAREER_TYPE.신입 ? "on" : ""
          }`}
          data-type="new"
          style={{
            backgroundColor: careerType === CAREER_TYPE.신입 ? "#000" : "#fff",
          }}
          onClick={onNewb}
        >
          신입
        </button>
        <button
          className={`tabs-select-btn ${
            careerType === CAREER_TYPE.경력 ? "on" : ""
          }`}
          data-type="old"
          style={{
            backgroundColor: careerType === CAREER_TYPE.경력 ? "#000" : "#fff",
          }}
          onClick={onOldb}
        >
          경력
        </button>
      </div>

      {careerType === CAREER_TYPE.경력 && (
        <div className="career-old-group" data-type="old">
          <div className="career-old-list">
            {careers.map((career, i) => (
              <CareerFieldBox
                key={i}
                career={career}
                onClickDelete={() => removeCareer(i)}
                onChange={(career) => {
                  updateCareer(i, career);
                }}
              />
            ))}

            <div className="row">
              <div className="item-box">
                <div className="subject type-primary">총 경력</div>
                <div className="con">
                  <div className="career-box type-flex-full">
                    <div className="flex-divide-box">
                      <div className="tbox smaller">
                        <input type="text" disabled={true} value={year} />
                      </div>
                      <div className="unit-box">년</div>

                      <div className="tbox smaller type-space-left">
                        <input type="text" value={month} disabled={true} />
                      </div>
                      <div className="unit-box">개월</div>
                    </div>

                    <button className="career-btn" onClick={addCareer}>
                      <span className="web">경력</span>추가+
                    </button>
                    {/* <a href="." className="career-btn type-2">
                      <span className="web">경력</span>삭제
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CareerSection;
