import { lectureBaseurl, resourcePath } from "utils/formatter";

import { TCourseType } from "api/education/types";
import useLecturesByCourse from "hooks/lecture/useLecturesByCourse";

const OverviewLectures = ({ courseType }: { courseType: TCourseType }) => {
  const { lectures } = useLecturesByCourse(courseType);

  return (
    <article className="edu-list-form">
      <div className="list-wrap with-swiper">
        <div className="swiper-container">
          <ul className="swiper-wrapper">
            {lectures.slice(0, 4).map((lecture) => (
              <li key={lecture.course_id} className="swiper-slide">
                <div className="item-wrap">
                  <a
                    href={`${lectureBaseurl(lecture.lecture_type)}/${
                      lecture.course_id
                    }`}
                    className="item-box"
                  >
                    {lecture.image_filename ? (
                      <div className="img-box">
                        <img
                          src={resourcePath(lecture.image_filename)}
                          alt="이미지"
                        />
                      </div>
                    ) : (
                      <div className="no-picture">
                        <div className="name">
                          {lecture.title.substring(0, 30)}
                          {lecture.title.length >= 30 && "..."}
                        </div>
                      </div>
                    )}
                    <div className="info-box">
                      <div className="subject-box">{lecture.title}</div>
                      <div className="con-box">{lecture.description}</div>

                      <div className="label-group">
                        <div className="label-list">
                          {lecture.lecture_type === "DENTIN" &&
                            lecture.score > 0 && (
                              <div className="label-box type-1">
                                이수점수 {lecture.score}점
                              </div>
                            )}
                          <div className="label-box type-2">
                            총 {lecture.lecture_count}강
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </article>
  );
};

export default OverviewLectures;
