import axiosClient from "api/axiosClient";
import { IResponse, IPageResponse } from "api/types";
import {
  IJobOpeningOverview,
  IJobOpeningLocationView,
  IJobOpeningQuery,
  IJobOpeningView,
  IGeolocation,
} from "./types";

/**
 * 채용 공고 목록 조회 (타입별)
 * GET /job
 */
export const fetchJobPostings = async (
  page: number,
  size: number,
  sort_property: "DISTANCE" | "UPDATE_DATE" | "CLOSING_DATE"
) => {
  const resp = await axiosClient.get<IPageResponse<Array<IJobOpeningOverview>>>(
    `/job?page=${page}&size=${size}&sort_property=${sort_property}&sort_direction=DESC`
  );
  return resp.data;
};

/**
 * 채용 공고 정보 조회
 * GET /job/{job_id}
 */
export const fetchJobPosting = async (job_id: number) => {
  const resp = await axiosClient.get<IResponse<IJobOpeningView>>(
    `/job/${job_id}`
  );
  return resp.data;
};

/**
 * 채용 공고 검색
 * POST /job/search
 */
export const searchJobPostings = async (
  page: number,
  size: number,
  params: IJobOpeningQuery
) => {
  const resp = await axiosClient.post<
    IPageResponse<Array<IJobOpeningOverview>>
  >(`/job/search`, {
    page,
    size,
    query: params,
  });
  return resp.data;
};

/**
 * 채용 공고 검색
 * POST /job/search
 */
export const searchRecommendJobPostings = async (
  page: number,
  size: number,
  params: IJobOpeningQuery
) => {
  try {
    const resp = await axiosClient.post<
      IPageResponse<Array<IJobOpeningOverview>>
    >(`/job/recommend_job`, {
      page,
      size,
      query: params,
    });

    return resp.data;
  } catch (e) {
    return {
      data: {
        total_page: 0,
        total_elements: 0,
        element_list: [] as IJobOpeningOverview[],
      },
      result: "FAIL",
      message: "실패",
    } as IPageResponse<Array<IJobOpeningOverview>>;
  }
};

/**
 * 채용 공고 추천
 * GET /job/recommend
 */
export const fetchRecommendJobPostings = async () => {
  const resp = await axiosClient.get<IResponse<Array<IJobOpeningOverview>>>(
    `/job/recommend`
  );
  return resp.data;
};
export const fetchRecommendJobPostingsWithLocation = async (
  data: IGeolocation
) => {
  const resp = await axiosClient.get<IResponse<Array<IJobOpeningOverview>>>(
    `/job/recommend?latitude=${data.latitude}&longitude=${
      data.longitude
    }&size=${5}`
  );
  return resp.data;
};
/**
 * 채용 공고 지도 검색
 * GET /job/location?latitude={}&longitude={}&search_distance={}
 */

export const searchJobPostingsByLocation = async (
  latitude: number,
  longitude: number,
  search_distance: number
) => {
  const resp = await axiosClient.get<IResponse<Array<IJobOpeningLocationView>>>(
    `/job/location?latitude=${latitude}&longitude=${longitude}&search_distance=${search_distance}&intern_flag=false`
  );
  return resp.data;
};

export const searchInternPostingsByLocation = async (
  latitude: number,
  longitude: number,
  search_distance: number,
  occupations?: string[]
) => {
  const resp = await axiosClient.get<IResponse<Array<IJobOpeningLocationView>>>(
    `/job/location?latitude=${latitude}&longitude=${longitude}&search_distance=${search_distance}&intern_flag=true${
      occupations && `&occupation_list=${occupations.join(",")}`
    }`
  );
  return resp.data;
};
