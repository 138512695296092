import { fetchDrive, fetchDrives, fetchNotices } from "api/notice/rests";
import { IDriveView, INoticeView } from "api/notice/types";
import Layout from "layouts";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MESSAGE_PATH, NOTICE_PATH, NOTICE_SUBPATH } from "routes";
import { displayDateTime } from "utils/formatter";

const Intern = () => {
  const navigate = useNavigate();
  const [notices, setNotices] = useState<INoticeView[]>([]);
  const [drives, setDrives] = useState<IDriveView[]>([]);

  useEffect(() => {
    (async () => {
      const {
        data: { element_list },
      } = await fetchNotices(1, "INTERN");
      const {
        data: { element_list: drive_list },
      } = await fetchDrives(1);
      setNotices(element_list);
      setDrives(drive_list);
    })();
  }, []);

  // 공지사항 API 처리
  return (
    <div>
      <Layout>
        <div>
          <section className="page-visual">
            <div className="visual-box visual-intern">
              <div className="inner wrap intern">
                <div className="page-tit-box intern">
                  실습 지원센터
                  <div className="page-sub-tit-box">
                    안녕하세요. 실습지원센터입니다. 여러분의 실습을
                    도와드립니다. <br />
                    실습치과를 찾고있나요? 실습생을 찾고 있나요? 지금 치과인에서
                    해결하세요!
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="inner wrap">
            {/* <div
              className="intern-page-banner-pc"
              style={{ width: "100vw", marginLeft: "calc(50% - 50vw)" }}
            >
              <img
                style={{ width: "100%" }}
                src="/img_page_visual_recruit-pc.svg"
                alt="img"
              />
            </div> */}
            {/* <div>
              <img
                src="/img_page_visual_recruit-mo.svg"
                alt="img"
                className="intern-page-banner-mo"
              />
            </div> */}
            <div
              className="intern-page-notice-wrap"
              style={{ marginTop: "50px" }}
            >
              <div className="intern-page-notice-title-wrap">
                <div className="intern-page-notice-title">공지사항</div>
                <a
                  className="intern-page-notice-more-btn"
                  href={`${NOTICE_PATH}/intern`}
                >
                  +
                </a>
              </div>
              {notices.map((notice) => (
                <div className="intern-page-notice-list">
                  <a href={`${NOTICE_PATH}/${notice.notice_id}`}>
                    {notice.title}
                  </a>
                </div>
              ))}
            </div>
            <div className="intern-page-btn-wrap-pc">
              <img
                src="/qna.png"
                style={{ cursor: "pointer" }}
                alt="1:1문의하기"
                onClick={() => navigate(`${MESSAGE_PATH}?qna=true`)}
              />
              <img
                src="/intern_suggestion.png"
                style={{ cursor: "pointer" }}
                alt="실습치과 제안하기"
                onClick={() => navigate(`${MESSAGE_PATH}?qna=true`)}
              />
            </div>
            <div className="intern-page-btn-wrap-mo">
              <img
                src="/qna.png"
                alt="1:1문의하기"
                onClick={() => navigate(`${MESSAGE_PATH}?qna=true`)}
              />
              <img
                src="/intern_suggestion.png"
                alt="실습치과 제안하기"
                onClick={() => navigate(`${MESSAGE_PATH}?qna=true`)}
              />
            </div>
            <div className="intern-page-notice-wrap">
              <div className="intern-page-notice-title-wrap">
                <div className="intern-page-notice-title">자료실</div>
                <a
                  href={`${NOTICE_PATH}/${NOTICE_SUBPATH.DRIVE}`}
                  className="intern-page-notice-more-btn"
                >
                  +
                </a>
              </div>
              <div className="intern-page-title-border" />
              <div className="intern-page-data-list-wrap">
                {drives.map((drive) => (
                  <a
                    style={{ width: "100%" }}
                    href={`${NOTICE_PATH}/${NOTICE_SUBPATH.DRIVE}/${drive.reference_id}`}
                  >
                    <div className="intern-page-data-space-between">
                      <div className="intern-page-data-txt">{drive.title}</div>
                      <div className="intern-page-data-date">
                        {displayDateTime(drive.reg_date)}
                      </div>
                    </div>
                    <div className="intern-page-data-sub-txt">
                      {drive.contents.substring(0, 30)}
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Intern;
