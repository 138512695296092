import { useEffect, useState } from "react";
import { ISalaryRequest } from "api/resume/types";

import { SALARY_TYPE, TAX_TYPE } from "utils/enums";

const SalaryFieldRow = ({
  salaryInfo,
  onChange,
}: {
  salaryInfo: ISalaryRequest;
  onChange: (salary: ISalaryRequest) => void;
}) => {
  const [salaryType, setSalaryType] = useState<string>(salaryInfo.salary_type);
  const [taxType, setTaxType] = useState<string>(salaryInfo.tax_type);
  const [salary, setSalary] = useState<number>(salaryInfo.salary);

  useEffect(() => {
    setSalaryType(
      salaryInfo.salary_type ? salaryInfo.salary_type : SALARY_TYPE.협의
    );
    setTaxType(salaryInfo.tax_type);
    setSalary(salaryInfo.salary);
  }, [salaryInfo]);

  useEffect(
    () =>
      onChange({
        salary_type: salaryType,
        tax_type: taxType,
        salary: salaryType === SALARY_TYPE.협의 ? 0 : salary,
      }),
    [salary, salaryType, taxType]
  );
  return (
    <div className="row last-border">
      <div className="item-box">
        <div className="subject">희망 연봉*</div>
        <div className="con">
          <div className="hope-box">
            <div className="sbox normal off">
              <select
                value={salaryType}
                onChange={(e) => setSalaryType(e.target.value)}
              >
                <option value={SALARY_TYPE.협의}>면접 시 협의</option>
                <option value={SALARY_TYPE.월급}>월급</option>
                <option value={SALARY_TYPE.시급}>시급</option>
                <option value={SALARY_TYPE.연봉}>연봉</option>
              </select>
            </div>

            {salaryType !== SALARY_TYPE.협의 && (
              <div className="additional-box">
                <div className="price-box">
                  <div className="tbox small">
                    <input
                      type="text"
                      placeholder="0"
                      value={salary}
                      onChange={(e) =>
                        setSalary(Number(e.target.value.replace(/[^0-9]/g, "")))
                      }
                    />
                  </div>
                  <div className="unit-box">원</div>
                </div>

                <div className="check-box">
                  <div className="rbox">
                    <label>
                      <input
                        type="radio"
                        name="hope"
                        checked={taxType === TAX_TYPE.세전}
                        onChange={() => setTaxType(TAX_TYPE.세전)}
                      />
                      <i></i>
                      <span className="txt">세전</span>
                    </label>
                  </div>
                  <div className="rbox">
                    <label>
                      <input
                        type="radio"
                        name="hope"
                        checked={taxType === TAX_TYPE.세후}
                        onChange={() => setTaxType(TAX_TYPE.세후)}
                      />
                      <i></i>
                      <span className="txt">세후</span>
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryFieldRow;
