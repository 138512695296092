import { useContext } from "react";
import { PageTitle } from "components/MyPage";

import { PreviewIncomeContext } from "pages/MyPage/PreviewIncomePage";

const FormBox = () => {
  const { state, handler } = useContext(PreviewIncomeContext);

  return (
    <div className="calculate-form-box">
      <article className="lnb-contents-tit">
        <PageTitle iconClass="ic-calculate" title="실수령액 계산기" />
      </article>

      <div className="form-list">
        <div className="form-box">
          <div className="form-tit-box">
            <div className="form-tit-txt type-ess">필수 입력</div>
          </div>
          <div className="form-con-box">
            <div className="row">
              <div className="item-layout1-list">
                <div className="item-layout1-box">
                  <article className="form-item-tit-box">
                    <div className="tit-txt">급여기준</div>
                  </article>

                  <div className="con-box">
                    <div className="radio-txt-box">
                      <label>
                        <input
                          type="radio"
                          name="type1"
                          checked={state.isAnnual}
                          onChange={handler.setAnnual}
                        />
                        <span className="txt">연봉</span>
                      </label>
                    </div>

                    <div className="radio-txt-box">
                      <label>
                        <input
                          type="radio"
                          name="type1"
                          checked={!state.isAnnual}
                          onChange={handler.setMonthly}
                        />
                        <span className="txt">월급</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="item-layout1-box">
                  <article className="form-item-tit-box">
                    <div className="tit-txt">퇴직금</div>
                  </article>

                  <div className="con-box">
                    <div className="radio-txt-box">
                      <label>
                        <input
                          type="radio"
                          name="type2"
                          checked={!state.includePension}
                          onChange={handler.excludePension}
                        />
                        <span className="txt">별도</span>
                      </label>
                    </div>

                    <div className="radio-txt-box">
                      <label>
                        <input
                          type="radio"
                          name="type2"
                          checked={state.includePension}
                          onChange={handler.includePension}
                        />
                        <span className="txt">포함</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <article className="form-item-tit-box">
                <div className="tit-txt">급여</div>
              </article>

              <div className="calculate-input-box">
                <div className="input-main-box">
                  <div className="input-box">
                    <div className="tbox full">
                      <input
                        type="text"
                        placeholder="0"
                        value={state.paycheck}
                        onChange={(e) => {
                          if (e.target.value.trim() === '') {
                            handler.setPaycheck(0)
                          } else {
                            handler.setPaycheck(parseInt(e.target.value))
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="unit-box">원</div>
                </div>

                <div className="input-option-box">
                  <button
                    className="input-option-btn"
                    onClick={() =>
                      handler.setPaycheck(state.paycheck + 10000000)
                    }
                  >
                    +1000만
                  </button>
                  <button
                    className="input-option-btn"
                    onClick={() =>
                      handler.setPaycheck(state.paycheck + 1000000)
                    }
                  >
                    +100만
                  </button>
                  <button
                    className="input-option-btn"
                    onClick={() => handler.setPaycheck(state.paycheck + 100000)}
                  >
                    +10만
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-box">
          <div className="form-tit-box">
            <div className="form-tit-txt">선택 입력</div>
          </div>
          <div className="form-con-box">
            <div className="row">
              <div className="item-layout1-list">
                <div className="item-layout1-box">
                  <article className="form-item-tit-box">
                    <div className="tit-txt">부양가족 수(본인포함)</div>
                  </article>

                  <div className="con-box">
                    <div className="spinner-box">
                      <article className="spinner">
                        <div
                          className="spinner-btn type-minus"
                          onClick={handler.decreaseFamilyCount}
                        >
                          -
                        </div>
                        <input
                          type="text"
                          placeholder="1"
                          value={state.familyCount}
                          onChange={(e) =>
                            handler.setFamilyCount(e.target.value)
                          }
                        />
                        <div
                          className="spinner-btn type-plus"
                          onClick={handler.increaseFamilyCount}
                        >
                          +
                        </div>
                      </article>

                      <div className="unit-box">명</div>
                    </div>
                  </div>
                </div>

                <div className="item-layout1-box">
                  <article className="form-item-tit-box">
                    <div className="tit-txt">20세 이하 자녀 수</div>
                  </article>

                  <div className="con-box">
                    <div className="spinner-box">
                      <article className="spinner">
                        <div
                          className="spinner-btn type-minus"
                          onClick={handler.decreaseChildUnder20}
                        >
                          -
                        </div>
                        <input
                          type="text"
                          placeholder="1"
                          value={state.childUnder20}
                          onChange={(e) =>
                            handler.setChildUnder20(e.target.value)
                          }
                        />
                        <div
                          className="spinner-btn type-plus"
                          onClick={handler.increaseChildUnder20}
                        >
                          +
                        </div>
                      </article>

                      <div className="unit-box">명</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormBox;
