import RecommendLectures from "components/Lecture/RecommendLectures";
import OverviewLectures from "components/Lecture/OverviewLectures";
import MainNotice from "components/Lecture/MainNotice";
import MainFaq from "components/Lecture/MainFaq";
import { LECTURE_PATH, LECTURE_SUBPATH } from "routes";
import { Banner } from "components/commons";

const LectureMainPage = () => {
  return (
    <section className="edu-home">
      <RecommendLectures />

      <div className="edu-banner-box">
        <Banner type="LECTURE_MAIN" />
      </div>

      <div className="edu-lecture-list">
        <div className="edu-lecture-item edu-lecture1">
          <article className="edu-tit-box">
            <div className="tit-box">
              <span className="txt">치과인 강좌</span>
            </div>
            <div className="option-box">
              <a
                href={`${LECTURE_PATH}/${LECTURE_SUBPATH.DENTAL_IN}`}
                className="more-btn"
              >
                더보기
              </a>
            </div>
          </article>

          <OverviewLectures courseType="DENTIN" />
        </div>
        <div className="edu-lecture-item edu-lecture2">
          <article className="edu-tit-box">
            <div className="tit-box">
              <span className="txt">구강보건교육</span>
            </div>
            <div className="option-box">
              <a
                href={`${LECTURE_PATH}/${LECTURE_SUBPATH.ORAL_HEALTH}`}
                className="more-btn"
              >
                더보기
              </a>
            </div>
          </article>
          <OverviewLectures courseType="HEALTH" />
        </div>
        <div className="edu-lecture-item edu-lecture3">
          <article className="edu-tit-box">
            <div className="tit-box">
              <span className="txt">기타교육</span>
            </div>
            <div className="option-box">
              <a
                href={`${LECTURE_PATH}/${LECTURE_SUBPATH.ETC}`}
                className="more-btn"
              >
                더보기
              </a>
            </div>
          </article>
          <OverviewLectures courseType="ETC" />
        </div>
      </div>

      <div className="edu-board-box">
        <MainNotice />

        <MainFaq />
      </div>
    </section>
  );
};

export default LectureMainPage;
