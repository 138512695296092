import { useLocation } from "react-router-dom";

import { LectureOverview } from "components/Lecture";
import Paginator from "components/Paginator";
import useLecturesByCourse from "hooks/lecture/useLecturesByCourse";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import axiosClient from "api/axiosClient";
import { IResponse } from "api/types";
import { ICommonCodeView, ICommonCodeViewWithSecondary } from "api/code/types";
import { Banner } from "components/commons";

interface DentinCate {
  common_code_list: ICommonCodeView[];
  common_code_secondary_list: ICommonCodeViewWithSecondary[];
}

const LectureListPage = () => {
  const { pathname } = useLocation();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });

  const isDentalinLeature = /dental-in/.test(pathname);
  const isOralLeature = /oral-health/.test(pathname);
  const [dentinCate, setDentinCate] = useState<DentinCate | null>(null);

  const {
    lectures,
    page,
    totalPage,
    mainCate,
    subCate,
    handler: { setPage, setMainCate, setSubCate },
  } = useLecturesByCourse(
    isDentalinLeature ? "DENTIN" : isOralLeature ? "HEALTH" : "ETC"
  );

  useEffect(() => {
    if (!isDentalinLeature) return;
    axiosClient
      .get<IResponse<DentinCate>>(
        "/education/lecture/course/category?search_type=DENTIN"
      )
      .then((res) => {
        setDentinCate(res.data.data);
      });
  }, []);

  return (
    <section className="edu-home">
      <div className="edu-banner-box type-top">
        <Banner type="LECTURE_LIST" />
      </div>

      {!isTabletOrMobile && isDentalinLeature && dentinCate && (
        <div className="lecture-category-box">
          <div className="row">
            <div className="sbox normal">
              <select
                onChange={(e) => {
                  setMainCate(parseInt(e.target.value));
                  setSubCate(undefined);
                }}
                value={mainCate?.toString()}
              >
                <option value={0}>1차 카테고리</option>
                {dentinCate.common_code_list.map((cate) => (
                  <option value={cate.code_id}>{cate.code_name}</option>
                ))}
              </select>
            </div>
            <div className="sbox normal">
              <select
                onChange={(e) => setSubCate(parseInt(e.target.value))}
                value={subCate?.toString()}
              >
                <option value={0} selected={!subCate}>
                  2차 카테고리
                </option>
                {dentinCate.common_code_secondary_list
                  .filter((c) => c.primary_code_id === mainCate)
                  .map((cate) => (
                    <option value={cate.secondary_code_id}>
                      {cate.secondary_code_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      )}

      <div className="edu-lecture-list">
        <div className="edu-lecture-item edu-lecture1">
          <article className="edu-tit-box">
            <div className="tit-box">
              <span className="txt">강좌 목록</span>
            </div>
            <div className="option-box"></div>
          </article>

          <article className="edu-list-form">
            <div className="list-wrap with-swiper">
              <ul className="swiper-container">
                {lectures.map((lecture) => (
                  <LectureOverview key={lecture.course_id} lecture={lecture} />
                ))}
              </ul>
            </div>
          </article>
        </div>
      </div>

      {totalPage > 1 && (
        <Paginator
          currentPage={page}
          totalPage={totalPage}
          changePage={(page) => setPage(page)}
        />
      )}
    </section>
  );
};
export default LectureListPage;
