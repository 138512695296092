import { useCallback, useEffect, useState } from "react";
import { Button, Table, Pagination } from "react-bootstrap";

import { deleteEducationNotice, fetchEducationNotice } from "api/admin/rests";
import { ILectureCourseNotice } from "api/education/types";

import CourseNotice from "./CourseNotice";

const LECTURE_TYPE = "LEGAL";
const CourseNoticeTable = () => {
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [notices, setNotices] = useState<ILectureCourseNotice[]>([]);
  const [showNotice, setShowNotice] = useState<boolean>(false);
  const [selectedNotice, setSelectedNotice] =
    useState<ILectureCourseNotice | null>(null);


  const getEducationNotices = useCallback(async () => {
    const {
      data: { element_list, total_page },
    } = await fetchEducationNotice(LECTURE_TYPE, page, 15, "DESC");

    setNotices(element_list);
    setTotalPage(total_page);
  }, [page]);

  const removeEducationNotice = async (noticeId : number) => {
  
    const data = await deleteEducationNotice(noticeId);
    console.log(data);

  }

  useEffect(() => {
    (async () => {
      getEducationNotices();
    })();
  }, [getEducationNotices]);


  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={() => setShowNotice(true)}>공지사항 등록</Button>
      </div>

      <div style={{ minHeight: "750px" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>공지사항 ID</th>
              <th>공지 유형</th>
              <th>제목</th>
              <th>view</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {notices.map((notice) => (
              <tr key={notice.notice_id}>
                <td>{notice.notice_id}</td>
                <td>{notice.notice_type}</td>
                <td>{notice.title}</td>
                <td>{notice.view_count}</td>
                
                <td style={{display: "flex", gap: "6px"}}>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      setSelectedNotice(notice);
                      setShowNotice(true);
                    }}
                  >
                    편집
                  </Button>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      removeEducationNotice(notice.notice_id);
                      getEducationNotices();
                    }}
                  >
                    삭제
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination>
          {[...Array(totalPage).keys()].map((pageIdx) => (
            <Pagination.Item
              key={pageIdx + 1}
              active={pageIdx + 1 === page}
              onClick={() => setPage(pageIdx + 1)}
            >
              {pageIdx + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>

      <CourseNotice
        show={showNotice}
        courseNotice={selectedNotice}
        onClose={() => {
          setSelectedNotice(null);
          setShowNotice(false);
          getEducationNotices();
        }}
      />
    </>
  );
};
export default CourseNoticeTable;
