import axiosClient from "../axiosClient";

import { IResponse, IPageResponse } from "api/types";
import {
  ILectureCourseOverview,
  TCourseType,
  IEducationFaqView,
  ILectureCourseQuery,
} from "./types";
import { INoticeView, TNoticeType } from "api/notice/types";

/**
 * 온라인 교육 추천 강의 코스 조회
 * GET /education/lecture/course/recommend
 */
export const fetchRecommendLectures = async () => {
  const resp = await axiosClient.get<IResponse<Array<ILectureCourseOverview>>>(
    `/education/lecture/course/recommend`
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 코스 유형별 조회
 * GET /education/lecture/course
 */
export const fetchLecturesByCourse = async (
  search_type: TCourseType,
  page: number,
  main_cate?: number,
  sub_cate?: number
) => {
  const code = sub_cate || main_cate;
  const secondary = Boolean(sub_cate);
  const resp = await axiosClient.get<
    IPageResponse<Array<ILectureCourseOverview>>
  >(
    `/education/lecture/course?search_type=${search_type}&page=${page}${
      code ? `&code=${code}` : ""
    }${
      secondary ? `&secondary=${secondary}` : ""
    }&size=16&sort_property=REG_DATE&sort_direction=DESC`
  );

  return resp.data;
};

/**
 * 온라인 교육 공지 정보 조회
 * GET /education/notice
 */
export const fetchEducationNotices = async (
  page: number,
  notice_type?: TNoticeType
) => {
  const resp = await axiosClient.get<IPageResponse<Array<INoticeView>>>(
    `/education/notice?notice_type=${
      notice_type || "NORMAL"
    }&page=${page}&size=15&sort_property=REG_DATE&sort_direction=DESC`
  );

  return resp.data;
};

/**
 * 온라인 교육 공지 정보 조회
 * GET /education/notice/{notice_id}
 */
export const fetchEducationNotice = async (notice_id: number) => {
  const resp = await axiosClient.get<IResponse<INoticeView>>(
    `/education/notice/${notice_id}`
  );

  return resp.data;
};

/**
 * 온라인 교육 FAQ 조회
 * GET /education/faq
 */
export const fetchEducationFaq = async () => {
  const resp = await axiosClient.get<IResponse<Array<IEducationFaqView>>>(
    `/education/faq`
  );

  return resp.data;
};

/**
 * 온라인 교육 강의 코스 검색
 * POST /education/lecture/course/search
 */
export const searchCourses = async (
  page: number,
  size: number,
  params: ILectureCourseQuery
) => {
  const resp = await axiosClient.post<
    IPageResponse<Array<ILectureCourseOverview>>
  >(`/education/lecture/course/search`, {
    page,
    size,
    query: params,
  });
  return resp.data;
};
