import classNames from "classnames";
import { useState } from "react";
import { Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import Flex from "./Flex";

import ReactEChartsCore from "echarts-for-react/lib/core";
import { LineChart } from "echarts/charts";
import * as echarts from "echarts/core";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { getColor, rgbaColor } from "./BasicChart";

const lectures = ["법정의무교육", "치과인강좌", "구강보건교육", "기타교육"];

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
]);

const getOptions = (yaxis: number[]) => ({
  color: getColor("gray-100"),
  tooltip: {
    trigger: "axis",
    padding: [7, 10],
    backgroundColor: getColor("gray-100"),
    borderColor: getColor("gray-100"),
    textStyle: { color: getColor("dark") },
    borderWidth: 1,
    formatter: (params: any) => {
      const { name, value } = params[0];
      return `${name} : ${value}`;
    },
    transitionDuration: 0,
  },
  xAxis: {
    type: "category",
    data: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23],
    boundaryGap: false,
    axisPointer: {
      lineStyle: {
        color: getColor("gray-300"),
        type: "dashed",
      },
    },
    splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: getColor("gray-300"),
        type: "dashed",
      },
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor("gray-400"),
      formatter: (value: any) => `${value}`,
      margin: 15,
    },
  },
  yAxis: {
    type: "value",
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getColor("gray-300"),
        type: "dashed",
      },
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor("gray-400"),
      margin: 15,
    },
    axisTick: { show: false },
    axisLine: { show: false },
  },
  series: [
    {
      type: "line",
      data: yaxis,
      lineStyle: { color: getColor("primary") },
      itemStyle: {
        borderColor: getColor("primary"),
        borderWidth: 2,
      },
      symbol: "circle",
      symbolSize: 10,
      smooth: false,
      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor("primary"), 0.2),
            },
            {
              offset: 1,
              color: rgbaColor(getColor("primary"), 0),
            },
          ],
        },
      },
    },
  ],
  grid: { right: 10, left: 0, bottom: 0, top: 10, containLabel: true },
});

const takeData = [
  [60, 80, 60, 80, 65, 130, 120, 100, 30, 40, 30, 70],
  [100, 70, 80, 50, 120, 100, 130, 140, 90, 100, 40, 50],
  [80, 50, 60, 40, 60, 120, 100, 130, 60, 80, 50, 60],
  [70, 80, 100, 70, 90, 60, 80, 130, 40, 60, 50, 80],
];

const Title = ({
  titleTag: TitleTag,
  className,
  breakPoint,
  children,
}: {
  titleTag: any;
  className: string;
  breakPoint: string;
  children: JSX.Element;
}) => (
  <TitleTag
    className={classNames(
      {
        "mb-0": !breakPoint,
        [`mb-${breakPoint}-0`]: !!breakPoint,
      },
      className
    )}
  >
    {children}
  </TitleTag>
);

const LectureTakeChart = () => {
  const [idx, setIdx] = useState<number>(0);
  return (
    <Card className="h-100">
      <Card.Header className="pb-0">
        <Row className="align-items-center">
          <Col>
            <h6>강좌별 수강 수</h6>
          </Col>
          <Col {...{ xs: "auto" }} className={`text-right`}>
            <Flex>
              <Form.Select
                size="sm"
                value={idx}
                onChange={(e) => {
                  setIdx(parseInt(e.target.value));
                }}
                className="me-2"
              >
                {lectures.map((item, index) => (
                  <option value={index} key={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Flex>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body>
        <ReactEChartsCore
          echarts={echarts}
          option={getOptions(takeData[idx])}
          style={{ height: "18.4375rem" }}
        />
      </Card.Body>
    </Card>
  );
};

export default LectureTakeChart;
