import { useState, useRef, useEffect } from "react";
import moment from "moment";
import { IJobOpeningOverview } from "api/jobPosting/types";
import { wishJobPosting, cancelWishJobPosting } from "api/member/rests";
import useJobPosting from "hooks/jobPosting/useJobPosting";
import { JOBPOSTING_PATH } from "routes";
import { useUserContext } from "contexts/UserContext";

import NoPicturePng from "assets/img/img_logo.svg";

import {
  displaySalaryInfo,
  resourcePath,
  displayWorkExperience,
} from "utils/formatter";
import { USER_TYPE } from "utils/enums";

const MAGIC_NUMBER = 40; // 팝업 위치 조정에 사용되는 고정 여유 값
const HoverOverview = ({
  jobId,
  // jobPosting,
  bodyFullHeight,
  showHoverBox,
  onClose,
}: {
  jobId: number;
  bodyFullHeight: number;
  showHoverBox: boolean;
  // jobPosting: IJobOpeningOverview;
  onClose: () => void;
}) => {
  const boxWrap = useRef<HTMLDivElement>(null);
  const { jobPosting } = useJobPosting(jobId);
  const [position, setPosition] = useState({
    left: -9999,
    top: -9999,
  }); // 깜빡거리는 현상이 발생하여 화면 바깥쪽에 멀리 위치시킴.
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    setIsDone(true);
  }, [position]);
  const setViewPosition = () => {
    const current = boxWrap.current;
    const parent = current?.parentElement;
    if (current && parent && showHoverBox) {
      const parentRect = parent.getBoundingClientRect();
      const parentTop = parentRect.top;
      const parentLeft = parentRect.left;
      const maximumBottom =
        parentTop + window.pageYOffset + current.clientHeight;
      const maximumRight = parentLeft + current.clientWidth;
      let top = 0;
      let left = 0;
      if (maximumBottom > bodyFullHeight) {
        // 세로축 최대 높이를 넘기게 된다면
        top = bodyFullHeight - maximumBottom - MAGIC_NUMBER;
      }
      if (maximumRight > window.innerWidth) {
        // 가로축 최대 높이를 넘기게 된다면
        left = window.innerWidth - maximumRight - MAGIC_NUMBER;
      }
      setPosition({ top, left });
    }
  };
  useEffect(() => {
    if (showHoverBox) {
      setViewPosition();
    } else {
      setPosition({ top: -9999, left: -9999 });
    }
  }, [showHoverBox, jobPosting]);
  if (!jobPosting) return null;
  return (
    <div
      className="hover-box"
      ref={boxWrap}
      style={{
        left: position.left,
        top: position.top,
        display: showHoverBox && isDone ? "block" : "none",
      }}
      //   style={{ display: "block" }}
      onMouseLeave={() => {
        onClose();
        setIsDone(false);
      }}
    >
      <div className="hover-wrap">
        <a
          className="hover-close-btn"
          role="button"
          onClick={() => {
            onClose();
            setIsDone(false);
          }}
        ></a>

        <div className="top-info-box">
          <div className="img-box">
            {jobPosting?.logo_image_filename === "" ? (
              <div className="no-picture">
                <div className="name">{jobPosting.clinic_name}</div>
              </div>
            ) : (
              <img
                src={
                  jobPosting?.logo_image_filename === ""
                    ? NoPicturePng
                    : resourcePath(jobPosting?.logo_image_filename || "")
                }
                alt="이미지"
              />
            )}
          </div>

          <div className="info-box">
            <div className="row">
              <div className="subject">병원명</div>
              <div className="con">{jobPosting?.clinic_name}</div>
            </div>

            <div className="row">
              <div className="subject">주소</div>
              <div className="con">{jobPosting?.address}</div>
            </div>

            <div className="row">
              <div className="subject">상세주소</div>
              <div className="con">{jobPosting?.extra_address}</div>
            </div>

            <div className="row">
              <div className="subject">홈페이지</div>
              <div className="con">{jobPosting?.homepage_url}</div>
            </div>

            <div className="row">
              <div className="subject">대표번호</div>
              <div className="con">{jobPosting?.contact_phone_number}</div>
            </div>
          </div>
        </div>

        <div className="bottom-info-box">
          <div className="data-list">
            <div className="data-box">
              <div className="tit-box">고용정보</div>
              <div className="list-box">
                <div className="row">
                  <div className="subject">모집직종</div>
                  <div className="con">
                    {(jobPosting?.occupationList || []).join(" | ")}
                  </div>
                </div>
                <div className="row">
                  <div className="subject">담당업무</div>
                  <div className="con">
                    {(jobPosting?.work_list || []).join(" | ")}
                  </div>
                </div>
                <div className="row">
                  <div className="subject">고용형태</div>
                  <div className="con">
                    {(jobPosting?.work_type_list || []).join(" | ")}
                  </div>
                </div>
                <div className="row">
                  <div className="subject">경력조건</div>
                  <div className="con">
                    {!!jobPosting &&
                      displayWorkExperience(
                        jobPosting.min_work_experience,
                        jobPosting.max_work_experience
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className="subject">학력조건</div>
                  <div className="con">{jobPosting?.last_education_type}</div>
                </div>
                <div className="row">
                  <div className="subject">급여조건</div>
                  <div className="con">
                    {jobPosting?.salary_info
                      ? displaySalaryInfo(jobPosting.salary_info)
                      : "면접시 협의"}
                  </div>
                </div>
              </div>
            </div>

            <div className="data-box">
              <div className="tit-box">근무정보</div>
              <div className="list-box">
                <div className="row">
                  <div className="subject">근무요일</div>
                  <div className="con">{jobPosting?.work_day_type}</div>
                </div>
                <div className="row">
                  <div className="subject">근무시간</div>
                  <div className="con">{jobPosting?.work_time}</div>
                </div>
                <div className="row">
                  <div className="subject">복리후생</div>
                  <div className="con">
                    {(jobPosting?.benefit_list || []).join(", ")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Overview = ({
  showCheckbox,
  isSelected,
  jobPosting,
  refreshJobPostings,
  handleAddSelectedJob,
  handleRemoveSelectedJob,
}: {
  showCheckbox: boolean;
  isSelected: boolean;
  jobPosting: IJobOpeningOverview;
  refreshJobPostings: () => void;
  handleAddSelectedJob: (jobId: number) => void;
  handleRemoveSelectedJob: (jobId: number) => void;
}) => {
  const { isSignIn, userType, occupation } = useUserContext();
  const [hoverJobId, setHoverJobId] = useState<number>(0);
  const [showHoverBox, setShowHoverBox] = useState<boolean>(false);
  const handleWish = async () => {
    try {
      jobPosting.wish_flag
        ? await cancelWishJobPosting(jobPosting.job_id)
        : await wishJobPosting(jobPosting.job_id);
      await refreshJobPostings();
    } catch (e: any) {
      alert("오류가 발생하였습니다.");
    }
  };

  const isOpeningAvailable =
    !jobPosting.occupation_list.includes("치과의사") ||
    (isSignIn &&
      (userType === USER_TYPE.병원회원 ||
        (userType === USER_TYPE.개인회원 && occupation === "치과의사")));

  return (
    <li>
      <div className={`item-wrap ${showHoverBox ? "show-hover" : ""}`}>
        {isOpeningAvailable && (
          <HoverOverview
            // jobPosting={jobPosting}
            jobId={hoverJobId}
            bodyFullHeight={document.body.scrollHeight}
            showHoverBox={showHoverBox}
            onClose={() => {
              // setHoverJobId(0);
              setShowHoverBox(false);
            }}
          />
        )}

        <a
          href={
            isOpeningAvailable ? `${JOBPOSTING_PATH}/${jobPosting.job_id}` : ""
          }
          className="item-box"
          onClick={(e) => {
            if (!isOpeningAvailable) {
              e.preventDefault();
              window.alert("치과의사만 접근 가능한 공고입니다");
            }
          }}
        >
          {jobPosting?.logo_image_filename === "" ? (
            <div className="no-picture">
              <div className="name">{jobPosting.clinic_name}</div>
            </div>
          ) : (
            <div className="img-box">
              <img
                src={resourcePath(jobPosting?.logo_image_filename || "")}
                alt="이미지"
                // onMouseOver={() => console.log("on")}
                // onMouseOut={() => setShowHoverBox(false)}
              />
            </div>
          )}
          <div className="info-box">
            {showCheckbox && (
              <article className="compare-btn-box">
                <button
                  type="button"
                  className={`compare-btn ${isSelected ? "on" : ""}`}
                  onClick={(e) => {
                    e.preventDefault();
                    isSelected
                      ? handleRemoveSelectedJob(jobPosting.job_id)
                      : handleAddSelectedJob(jobPosting.job_id);
                  }}
                >
                  <span className="txt">선택</span>
                  <i className="ic ic-chk"></i>
                </button>
              </article>
            )}

            <div className="top-box">
              <div className="subject-box">{jobPosting.clinic_name}</div>
              <div className="con-box">{jobPosting.title}</div>
            </div>
            <div className="bottom-box">
              <div className="day-box">
                <i className="ic ic-day"></i>
                <span className="txt">
                  D-{moment(jobPosting.closing_date).diff(moment(), "days")}
                </span>
              </div>

              <div className="option-box">
                <div className="option-btn-box">
                  <button
                    type="button"
                    className="option-btn"
                    onMouseOver={() => {
                      if (isSignIn) {
                        setHoverJobId(jobPosting.job_id);
                        setShowHoverBox(true);
                      }
                    }}
                    // onMouseOut={() => setShowHoverBox(false)}
                  >
                    <i className="ic ic-option1"></i>
                  </button>
                </div>

                {isSignIn && userType === USER_TYPE.개인회원 && (
                  <div className="like-box">
                    <article className="like-toggle-btn">
                      <button
                        type="button"
                        className={jobPosting.wish_flag ? "on" : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleWish();
                        }}
                      ></button>
                    </article>
                  </div>
                )}
              </div>
            </div>
          </div>
        </a>
      </div>
    </li>
  );
};
export default Overview;
