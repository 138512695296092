import { LECTURE_PATH, LECTURE_SUBPATH } from "routes";

import useEducationNotices from "hooks/useEducationNotices";

const MainNotice = () => {
  const { notices } = useEducationNotices();
  return (
    <div className="edu-board-item">
      <div className="tit-box">
        <div className="tit-txt">공지사항</div>
        <a
          href={`${LECTURE_PATH}/${LECTURE_SUBPATH.NOTICE}`}
          className="more-btn"
        >
          +
        </a>
      </div>

      <div className="list-box">
        {notices.slice(0, 3).map((notice) => (
          <a
            key={notice.notice_id}
            href={`${LECTURE_PATH}/${LECTURE_SUBPATH.NOTICE}/${notice.notice_id}`}
            className="board-item"
          >
            <div className="board-txt-box">{notice.title}</div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default MainNotice;
