import { useEffect } from "react";
import Layout from "../../layouts";

import RecruitList from "./RecruitList";
import RecommendRecruitList from "./RecommendRecruitList";
import { MyBoard } from "../../components/Recruit";
import { Banner } from "components/commons";

const RecruitPage = () => {
  return (
    <Layout>
      <section className="main-head">
        <div className="inner wrap">
          <RecommendRecruitList />
          <MyBoard />
        </div>
      </section>
      <div className="inner" style={{ marginTop: 30 }}>
        <Banner type="MAIN" />
      </div>
      <RecruitList />
    </Layout>
  );
};

export default RecruitPage;
