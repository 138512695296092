import { useEffect, useState } from "react";

import { fetchKeywords, TRecommendType } from "api/keyword/rests";

const useKeywords = (recommendType: TRecommendType) => {
  const [keywords, setKeywords] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await fetchKeywords(recommendType);
      setKeywords(data);
    })();
  }, [recommendType]);

  return keywords;
};
export default useKeywords;
