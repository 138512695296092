import { RESUME_PATH } from "routes";

const Tab = ({
  label,
  tab,
  isSelect = false,
}: {
  label: string;
  tab: string;
  isSelect?: boolean;
}) => (
  <a
    href={`${RESUME_PATH}?tab=${tab}`}
    className={`page-tabs-btn ${isSelect ? "on" : ""}`}
  >
    {label}
  </a>
);

const TabSelector = ({ selectedTab }: { selectedTab: string | null }) => (
  <section className="page-visual">
    <div className="visual-box visual-human">
      <div className="inner wrap">
        <div className="page-tit-box">인재정보</div>

        <div className="page-tabs-list">
          <Tab
            label="치과스탭"
            tab="staff"
            isSelect={selectedTab === "staff" || selectedTab === null}
          />
          <Tab
            label="치과의사"
            tab="dentist"
            isSelect={selectedTab === "dentist"}
          />
        </div>
      </div>
    </div>
  </section>
);
export default TabSelector;
