import classNames from "classnames";
import React from "react";

interface FlexOptions {
  justifyContent?: string;
  align?: string;
  alignItems?: string;
  alignContent?: string;
  inline?: string;
  wrap?: string;
  tag?: keyof JSX.IntrinsicElements;
  className?: string;
  children?: React.ReactNode;
  breakpoint?: string;
  direction?: string;
}

const Flex = ({
  justifyContent,
  align,
  alignItems,
  alignContent,
  inline,
  wrap,
  className,
  tag: Tag = "div",
  children,
  breakpoint,
  direction,
  ...rest
}: FlexOptions) => {
  return (
    <Tag
      className={classNames(
        {
          [`d-${breakpoint ? breakpoint + "-" : ""}flex`]: !inline,
          [`d-${breakpoint ? breakpoint + "-" : ""}inline-flex`]: inline,
          [`flex-${direction}`]: direction,
          [`justify-content-${justifyContent}`]: justifyContent,
          [`align-items-${alignItems}`]: alignItems,
          [`align-content-${alignContent}`]: alignContent,
          [`flex-${wrap}`]: wrap,
        },
        className
      )}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default Flex;
