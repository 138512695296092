import { useEffect, useState } from "react";

import { fetchRecommendLectures } from "api/education/rests";
import { ILectureCourseOverview } from "api/education/types";
import { lectureBaseurl, resourcePath } from "utils/formatter";
import {useUserContext} from "../../contexts/UserContext";
import {USER_TYPE} from "../../utils/enums";

const RecommendLectures = () => {
  const { userType } = useUserContext();
  const [lectures, setLectures] = useState<ILectureCourseOverview[]>([]);
  useEffect(() => {

    (async () => {
      const { data } = await fetchRecommendLectures();
      setLectures(data);
    })();
  }, []);

  return (
    <div className="edu-top-box">
      <article className="edu-tit-box">
        <div className="tit-box">
          <i className="ic ic-edu"></i>
          <span className="txt type-accent">추천 강의</span>
        </div>
        <div className="option-box">
          {userType === USER_TYPE.병원회원 ? <a className='btn btn-success' href='/lecture/duty'>법정의무교육 바로가기</a>:''}
        </div>
      </article>

      <article className="edu-list-form">
        <div className="list-wrap with-swiper">
          <div className="swiper-container">
            <ul className="swiper-wrapper">
              {lectures.slice(0, 4).map((lecture) => (
                <li key={lecture.course_id} className="swiper-slide">
                  <div className="item-wrap">
                    <a
                      href={`${lectureBaseurl(lecture.lecture_type)}/${
                        lecture.course_id
                      }`}
                      className="item-box"
                    >
                      {lecture.image_filename ? (
                        <div className="img-box">
                          <img
                            src={resourcePath(lecture.image_filename)}
                            alt="이미지"
                          />
                        </div>
                      ) : (
                        <div className="no-picture">
                          <div className="name">
                            {lecture.title.substring(0, 30)}
                            {lecture.title.length >= 30 && "..."}
                          </div>
                        </div>
                      )}
                      <div className="info-box">
                        <div className="subject-box">{lecture.title}</div>
                        <div className="con-box">{lecture.description}</div>

                        <div className="label-group">
                          <div className="label-list">
                            {lecture.lecture_type === "DENTIN" &&
                              lecture.score > 0 && (
                                <div className="label-box type-1">
                                  이수점수 {lecture.score}점
                                </div>
                              )}
                            <div className="label-box type-2">
                              총 {lecture.lecture_count}강
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </article>
    </div>
  );
};
export default RecommendLectures;
