import { useContext } from "react";

const GenderBox = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (newValule: string) => void;
}) => {
  return (
    <div className="form-box" data-type="guest" style={{ margin: "8px 0" }}>
      <div className="form-tit-box">대표자 성별</div>
      <div className="form-con-box">
        <div className="radio-box">
          <label>
            <input
              type="radio"
              name="gender"
              checked={value === "male"}
              onChange={(_) => onChange("male")}
            />
            <span className="txt">남</span>
          </label>
          <label>
            <input
              type="radio"
              name="gender"
              checked={value === "female"}
              onChange={(_) => onChange("female")}
            />
            <span className="txt">여</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default GenderBox;
