const Query = ({
  label,
  onClick = () => {},
}: {
  label: string;
  onClick?: () => void;
}) => (
  <li>
    <div className="item-box">
      <div className="item-txt">{label}</div>
      <a className="remove-btn" onClick={onClick}></a>
    </div>
  </li>
);

const QueryBox = ({
  isQueryTypeSelectActive,
  queries,
  onClear,
  onSearch,
  onRemove,
}: {
  isQueryTypeSelectActive: boolean;
  queries: string[];
  onClear: () => void;
  onSearch: () => void;
  onRemove: (query: string) => void;
}) => (
  <div
    className={`info-search-result-box ${
      isQueryTypeSelectActive ? "mobile-on" : ""
    }`}
  >
    <article className="info-search-result">
      <div className="search-result-wrap">
        <div className="result-preview">
          <div className="list-wrap">
            <ul>
              {queries.map((query, i) => (
                <Query
                  key={i}
                  label={query.substring(2)}
                  onClick={() => onRemove(query)}
                />
              ))}
            </ul>
          </div>
        </div>
        <div className="result-menu">
          <a className="result-menu-btn type-1" onClick={onClear}>
            <span className="txt">초기화</span>
            <i className="ic ic-reset"></i>
          </a>
          <a className="result-menu-btn type-2 web" onClick={onSearch}>
            <span className="txt">검색하기</span>
            <i className="ic ic-search"></i>
          </a>
        </div>
      </div>
    </article>
  </div>
);
export default QueryBox;
