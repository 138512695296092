import Layout from "layouts";

import LoginForm from "components/Login/LoginForm";
import { Marketing } from "components/Login/Comps";

const LoginPage = () => (
  <Layout>
    <section className="login">
      <div className="inner wrap">
        <div className="login-wrap">
          <LoginForm />

          <Marketing />
        </div>
      </div>
    </section>
  </Layout>
);

export default LoginPage;
