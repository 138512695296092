import { ChangeEvent } from "react";

const GroupNameInputField = ({
  value,
  onChange,
  onEnter,
}: {
  value: string;
  onChange: (name: string) => void;
  onEnter: () => void;
}) => (
  <input
    type="text"
    className="name-input-box"
    value={value}
    onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
    onKeyDown={(e) => {
      if (e.key === "Enter") {
        onEnter();
      }
    }}
  />
);
export default GroupNameInputField;
