import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { groupBy } from "lodash";
import NoMessage from "./Comps/NoMessage";

import { IChatMemberView, IChatMessageView } from "api/types";
import {
  fetchMessages as fetchMemberMessages,
  sendMessage as sendMemberMessage,
  sendMessageFromJobPosting,
} from "api/member/rests";
import {
  fetchMessages as fetchClinicMessages,
  sendMessage as sendClinicMessage,
  sendMessageFromResume,
} from "api/clinic/rests";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";

const ChatContent = ({
  chat,
  onUpdateChat,
}: {
  chat: IChatMemberView | null;
  onUpdateChat: () => void;
}) => {
  // const { memberId, userType } = useUserContext();
  const [params] = useSearchParams();
  const [showContents, setShowContents] = useState<boolean>(!!chat);
  // const [message, setMessage] = useState<string>("");

  const user_name = params.get("user_name");
  const user_type = params.get("user_type");
  const user_id = params.get("user_id");

  const target_id = params.get("target_id");
  const { memberId, userType } = useUserContext();
  const [messages, setMessages] = useState<IChatMessageView[]>([]);
  const [message, setMessage] = useState<string>("");
  const [composing, setComposing] = useState<boolean>(false);

  const groupedMessages = useMemo(
    () =>
      groupBy(messages, (message) =>
        moment(message.reg_date).format("YYYY년 MM월 DD일")
      ),
    [messages]
  );

  const sendMessage = async () => {
    const targetId = Number(target_id);
    if (chat) {
      if (userType === USER_TYPE.개인회원) {
        const { data } = await sendMemberMessage(
          chat.member_type,
          chat.member_id,
          chat.name,
          message
        );
        setMessages([...messages, data]);
      } else {
        const { data } = await sendClinicMessage(
          chat.member_type,
          chat.member_id,
          chat.name,
          message
        );
        setMessages([...messages, data]);
      }
    } else if (targetId > 0) {
      if (userType === USER_TYPE.개인회원) {
        const { data } = await sendMessageFromJobPosting(targetId, message);
        setMessages([...messages, data]);
      } else {
        const { data } = await sendMessageFromResume(targetId, message);
        setMessages([...messages, data]);
      }
    }
    setMessage("");
    onUpdateChat();
  };

  const fetchMessages = useCallback(async () => {
    if (!chat) return;

    if (userType === USER_TYPE.개인회원) {
      const { data } = await fetchMemberMessages(
        chat.member_type,
        chat.member_id
      );
      setMessages(data.reverse());
    } else {
      const { data } = await fetchClinicMessages(
        chat.member_type,
        chat.member_id
      );
      setMessages(data.reverse());
    }
    setShowContents(true);
  }, [chat]);

  useEffect(() => {
    if (chat) {
      (async () => await fetchMessages())();
      return;
    }
    if (user_id && user_type && user_name) {
      (async () => {
        if (userType === USER_TYPE.개인회원) {
          const { data } = await fetchMemberMessages(user_type, user_id);
          console.log(data);
          setMessages(data.reverse());
        } else {
          const { data } = await fetchClinicMessages(user_type, user_id);
          setMessages(data.reverse());
        }
        setShowContents(true);
      })();
      return;
    }
  }, [fetchMessages, user_id, user_type, user_name]);

  useEffect(() => {
    var element = document.getElementById("chat-contents-list");
    if (!element) return;
    element.scrollTop = element.scrollHeight - element.clientHeight;
  }, [messages]);

  return (
    <div
      className="chat-contents"
      style={{ display: showContents ? "flex" : "" }}
    >
      <div className="chat-mobile-box">
        <div className="close-btn" onClick={() => setShowContents(false)}></div>
        <div className="txt-box">{chat?.name}</div>
      </div>

      <div className="chat-contents-list scroll-effect" id="chat-contents-list">
        {messages.length === 0 ? (
          <NoMessage target_id={target_id} />
        ) : (
          <>
            {Object.keys(groupedMessages).map((dateKey, i) => (
              <Fragment key={i}>
                <div className="day-box">
                  <div className="date-txt">{dateKey}</div>
                </div>

                <div className="chat-msg-list">
                  {groupedMessages[dateKey].map((message) => (
                    <div
                      key={message.chat_id}
                      className={`chat-msg-box ${
                        message.from_member_id !== memberId
                          ? "receive-box"
                          : "send-box"
                      }`}
                    >
                      <div className="con-box">
                        {message.message.split("\n").map((row, i) => (
                          <Fragment key={i}>
                            {row}
                            <br />
                          </Fragment>
                        ))}
                      </div>

                      <div className="date-box">
                        {moment(message.reg_date).format(`YY.MM.DD`)}
                        <br />
                        {moment(message.reg_date).format(`hh:mm`)}
                      </div>
                    </div>
                  ))}
                </div>
              </Fragment>
            ))}
          </>
        )}
      </div>
      {target_id && (
        <div className="chat-input">
          <div className="input-box ">
            <textarea
              rows={1}
              // onkeydown="resize(this)"
              // onkeyup="resize(this)"
              className="scroll-effect"
              placeholder="메세지를 입력하세요."
              onCompositionEnd={() => setComposing(false)}
              onCompositionStart={() => setComposing(true)}
              value={message}
              onKeyDown={(e) => {
                const isEnter = e.key === "Enter";
                if (isEnter && !e.shiftKey && !composing) {
                  e.preventDefault();
                  sendMessage();
                }
              }}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>

          <button
            disabled={!message}
            className={`send-btn ${message ? "on" : ""}`}
            onClick={sendMessage}
          >
            전송
          </button>
        </div>
      )}
      {user_id && user_type && user_name && (
        <div className="chat-input">
          <div className="input-box ">
            <textarea
              rows={1}
              // onkeydown="resize(this)"
              // onkeyup="resize(this)"
              className="scroll-effect"
              placeholder="메세지를 입력하세요."
              onCompositionEnd={() => setComposing(false)}
              onCompositionStart={() => setComposing(true)}
              value={message}
              onKeyDown={(e) => {
                const isEnter = e.key === "Enter";
                if (isEnter && !e.shiftKey && !composing) {
                  e.preventDefault();
                  (async () => {
                    if (userType === USER_TYPE.개인회원) {
                      const { data } = await sendMemberMessage(
                        user_type,
                        user_id,
                        user_name,
                        message
                      );
                      setMessages([...messages, data]);
                    } else {
                      const { data } = await sendClinicMessage(
                        user_type,
                        user_id,
                        user_name,
                        message
                      );
                      setMessages([...messages, data]);
                    }
                    setMessage("");
                    onUpdateChat();
                  })();
                }
              }}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>

          <button
            disabled={!message}
            className={`send-btn ${message ? "on" : ""}`}
            onClick={async () => {
              if (userType === USER_TYPE.개인회원) {
                const { data } = await sendMemberMessage(
                  user_type,
                  user_id,
                  user_name,
                  message
                );
                setMessages([...messages, data]);
              } else {
                const { data } = await sendClinicMessage(
                  user_type,
                  user_id,
                  user_name,
                  message
                );
                setMessages([...messages, data]);
              }
              setMessage("");
              onUpdateChat();
            }}
          >
            전송
          </button>
        </div>
      )}
    </div>
  );
};
export default ChatContent;
