import { useEffect, useState, ChangeEvent } from "react";

import { GROUP_CODE_ID, ICommonCodeView } from "api/code/types";
import { fetchCodes } from "api/code/rests";
import { OCCUPATION } from "utils/enums";

const OccupationFieldRow = ({
  errorMessage,
  value,
  onChange,
}: {
  errorMessage?: string;
  value: string;
  onChange: (value: OCCUPATION) => void;
}) => {
  const [options, setOptions] = useState<ICommonCodeView[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await fetchCodes(GROUP_CODE_ID.회원직종);
      setOptions(data);
    })();
  }, []);

  return (
    <div className="row">
      <div className="category-box">
        <div className="sbox full off">
          <select
            className="job"
            value={value}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              onChange(e.target.value as OCCUPATION)
            }
          >
            <option value="">직종선택*</option>
            {options.map((op) => (
              <option key={op.code_id} value={op.code_name}>
                {op.description}
              </option>
            ))}
          </select>
        </div>
      </div>
      {errorMessage && <div className="input-msg-box">{errorMessage}</div>}
    </div>
  );
};

export default OccupationFieldRow;
