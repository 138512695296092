import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import {
  addLecture as _addLecture,
  updateLecture as _updateLecture,

} from "api/admin/rests";
import {
  ILectureRequest,
  ILectureView,
} from "api/education/types";

const INITIAL_LECTURE = {
  seq: 1,
  name: "",
  creator: "",
  description: "",
  video_id: "",
  play_time: 0,
  material_download_link: ''
};
const EtcLecture = ({
  lectureId,
  show,
  course: _course,
  onClose,
}: {
  lectureId: number;
  show: boolean;
  course: ILectureView | null;
  onClose: () => void;
}) => {
  const [course, setCourse] = useState<ILectureRequest>(
    _course || INITIAL_LECTURE
  );

  const addLecture = async () => {
    try {
      await _addLecture({
        ...course,
        course_id : lectureId,
      });
      onClose();
      setCourse(INITIAL_LECTURE);
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }
  };

  const updateLecture = async () => {
    if (!_course || !course) return;

    // const { course_id, lecture_list, recommend_flag, reg_date, ...params } =
    //   course;

    try {

      await _updateLecture(_course.lecture_id, course);

      alert("수정되었습니다.");
      onClose();
      setCourse(INITIAL_LECTURE);
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }
  };

  useEffect(() => {
    if (!_course){
      setCourse(INITIAL_LECTURE);

    }else{
      const { course_id, ...params } =
      _course;
      setCourse(params || INITIAL_LECTURE);

    }

  }, [_course]);

  return (
    <Modal size="lg" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{_course?.name || "강의 등록"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              제작자
            </Form.Label>
            <Col>
              <Form.Control
                value={course.creator}
                onChange={(e) =>
                  setCourse({ ...course, creator: e.target.value })
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              제목
            </Form.Label>
            <Col>
              <Form.Control
                value={course.name}
                onChange={(e) =>
                  setCourse({ ...course, name: e.target.value })
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              순서
            </Form.Label>
            <Col>
              <Form.Control
                value={course.seq}
                type="number"
                min={1}
                onChange={(e) =>
                  setCourse({ ...course, seq: Number(e.target.value) })
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              영상 아이디
            </Form.Label>
            <Col>
              <Form.Control
                value={course.video_id}
                onChange={(e) =>
                  setCourse({ ...course, video_id: e.target.value })
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              재생 시간
            </Form.Label>
            <Col>
              <Form.Control
                value={course.play_time}
                type="number"
                min={1}
                onChange={(e) =>
                  setCourse({ ...course, play_time:  Number(e.target.value) })
                }
              />
            </Col>
          </Form.Group>
          {/* <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              시작 일시
            </Form.Label>
            <Col>
              <DatePicker
                value={course.start_date}
                minDate={new Date()}
                onChange={(date) =>
                  setCourse({ ...course, start_date: `${date} 00:00:00` })
                }
              />
            </Col>
            <Form.Label column sm={3}>
              종료 일시
            </Form.Label>
            <Col>
              <DatePicker
                value={course.end_date}
                minDate={new Date()}
                onChange={(date) =>
                  setCourse({ ...course, end_date: `${date} 23:59:59` })
                }
              />
            </Col>
          </Form.Group> */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              설명
            </Form.Label>
            <Col>
              <Form.Control
                as="textarea"
                rows={3}
                value={course.description}
                onChange={(e) =>
                  setCourse({
                    ...course,
                    description: e.target.value,
                  })
                }
              />
            </Col>
          </Form.Group>

          {/* <div style={{ padding: "20px 16px 016px" }}>
            <h2>강의정보</h2>
            <Form.Group as={Row} className="mb-3">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  강의명
                </Form.Label>
                <Col>
                  <Form.Control
                    value={lecture.name}
                    onChange={(e) =>
                      setLecture({ ...lecture, name: e.target.value })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  강의 제작자
                </Form.Label>
                <Col>
                  <Form.Control
                    value={lecture.creator}
                    onChange={(e) =>
                      setLecture({ ...lecture, creator: e.target.value })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  영상 ID
                </Form.Label>
                <Col>
                  <Form.Control
                    value={lecture.video_id}
                    onChange={(e) =>
                      setLecture({ ...lecture, video_id: e.target.value })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  영상 재생 시간 (second)
                </Form.Label>
                <Col>
                  <Form.Control
                    value={lecture.play_time}
                    type="number"
                    onChange={(e) =>
                      setLecture({
                        ...lecture,
                        play_time: Number(e.target.value),
                      })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  강의 설명
                </Form.Label>
                <Col>
                  <Form.Control
                    value={lecture.description}
                    as="textarea"
                    rows={3}
                    onChange={(e) =>
                      setLecture({
                        ...lecture,
                        description: e.target.value,
                      })
                    }
                  />
                </Col>
              </Form.Group>
            </Form.Group>
          </div> */}

          <div
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "flex-end",
              marginTop: "50px",
            }}
          >
            <Button
              id="clinic-member-form"
              type="submit"
              variant="primary"
              onClick={() => (_course ? updateLecture() : addLecture())}
            >
              {_course ? "수정하기" : "등록하기"}
            </Button>
            <Button variant="secondary" onClick={onClose}>
              닫기
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default EtcLecture;
