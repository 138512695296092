export enum GROUP_CODE_ID {
  정책 = 1,
  회원 = 2,
  회원직종 = 3,
  이력서직종 = 4,
  이력서업무 = 5,
  근무형태 = 6,
  최종학력 = 7,
  근무요일 = 8,
  복리후생 = 9,
  제출서류 = 10,
  제출방법 = 11,
  알림수신방법 = 12,
  병원유형 = 13,
  회원직종_치과의사 = 14,
  치과인강좌분류 = 15,
  실습직종 = 16,
  실습업무 = 17,
  실습형태 = 18,
}

/**
 * 정책 조회
 * GET /policy
 */
export interface ICommonCodeView {
  /**
   * 코드 고유 번호
   */
  code_id: number;
  /**
   * 그룹 코드 ID
   */
  group_code_id: number;
  /**
   * 그룹 코드명
   */
  group_name: string;
  /**
   * 코드명
   */
  code_name: string;
  /**
   * 표시 순서
   */
  display_order: number;
  /**
   * 코드 설명
   */
  description: string;
}

export interface ICommonCodeViewWithSecondary {
  secondary_code_id: number;
  group_code_id: number;
  csecondary_code_name: string;
  secondary_code_name: string;
  display_order: number;
  description: string;
  primary_code_id: number;
}
