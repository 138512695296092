const RangeInfoRow = ({
  label,
  startValue = "",
  endValue = "",
}: {
  label: string;
  startValue?: string;
  endValue?: string;
}) => (
  <div className="row">
    <div className="subject">{label}</div>
    <div className="con">
      <div className="range-box">
        <div className="range-txt">{startValue}</div>
        <div className="range-unit">-</div>
        <div className="range-txt">{endValue}</div>
      </div>
    </div>
  </div>
);
export default RangeInfoRow;
