import { useContext, useState } from "react";
import { IJobOpeningOverview } from "api/jobPosting/types";
import Overview from "./Overview";
import JobPostingDiffConfirmPopup from "components/Popups/JobPostingDiffConfirmPopup";
import JobPostingDiffPopup from "components/Popups/JobPostingDiffPopup";
import { useMediaQuery } from "react-responsive";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";
import { fetchJobPostingCount } from "api/clinic/rests";
import { useNavigate } from "react-router-dom";
import { JOBPOSTING_FORM_PATH } from "routes";

const OverviewList = ({
                        sortDirection,
                        jobPostings,
                        totalCount,
                        recommendFlag,
                        refreshJobPostings,
                        onChangeRecommend,
                        onChangeSortDirection,
                      }: {
  sortDirection: "ASC" | "DESC";
  jobPostings: IJobOpeningOverview[];
  totalCount: number;
  recommendFlag:boolean;
  refreshJobPostings: () => void;
  onChangeRecommend:(flag:boolean)=> void;
  onChangeSortDirection: (sortDirection: "ASC" | "DESC") => void;
}) => {
  const { userType } = useUserContext();
  const [diffMode, setDiffMode] = useState<boolean>(false);
  const [selectedJob, setSelectedJob] = useState<number[]>([]);
  const [showDiffConfirmPopup, setShowDiffConfirmPopup] =
    useState<boolean>(false);
  const [showDiffPopup, setShowDiffPopup] = useState<boolean>(false);

  const offDiffMode = () => {
    setSelectedJob([]);
    setDiffMode(false);
  };

  const addSelectedJob = (jobId: number) => {
    if (selectedJob.length >= 3) {
      alert("공고 비교는 최대 3개까지 가능합니다.");
    } else {
      setSelectedJob([...selectedJob, jobId]);
    }
  };

  const navigate = useNavigate();

  const openingClick = async () => {
    try {
      const {
        data: { current_count, max_count },
      } = await fetchJobPostingCount(false);
      if (current_count >= max_count) {
        alert(`노출 가능한 공고는 최대 ${max_count}개입니다.`);
        return;
      } else {
        navigate(`${JOBPOSTING_FORM_PATH}`);
      }
    } catch (e) {}
  };

  const removeSelectedJob = (jobId: number) =>
    setSelectedJob(selectedJob.filter((j) => j !== jobId));

  return (
    <section className="main-recruit">
      <div className="inner wrap">
        <article className="list-layout1">
          <article className="list-head-box">
            <div className="head-txt-box">채용정보 {totalCount}건 </div>
            {userType === USER_TYPE.병원회원 && (
              <button onClick={openingClick} className="opening-write-btn">
                공고 등록📄
              </button>
            )}
            <div className="list-option-box">
              <div className="public-floating-box">
                <div className="toggle-btn-box big">
                  <label className="toggle">
                    <input
                      type="checkbox"
                      checked={recommendFlag}
                      onChange={(e) =>
                        onChangeRecommend(e.target.checked)
                      }                    />
                    <span className="toggle-txt toggle-on-txt">추천 공고</span>
                    <span className="toggle-txt toggle-off-txt">추천 공고</span>
                    <span className="toggle-bg"></span>
                  </label>
                </div>
              </div>
              <div className="sbox type-1">
                <select
                  value={sortDirection}
                  onChange={(e) =>
                    onChangeSortDirection(e.target.value as "ASC" | "DESC")
                  }
                >
                  <option value={"DESC"}>최신순</option>
                  <option value={"ASC"}>오래된순</option>
                </select>
              </div>
              <div
                className="compare-box"
                onClick={() => {
                  diffMode ? setShowDiffConfirmPopup(true) : setDiffMode(true);
                }}
              >
                <button className="compare-btn">공고 비교🗒</button>
              </div>
              {diffMode && (
                <div className="back-box" onClick={offDiffMode}>
                  <button className="back">돌아가기</button>
                </div>
              )}
            </div>
          </article>

          <div className="list-body-box">
            <div className="list-wrap">
              <ul>
                {jobPostings.map((jobPosting) => (
                  <Overview
                    key={jobPosting.job_id}
                    showCheckbox={diffMode}
                    isSelected={selectedJob.includes(jobPosting.job_id)}
                    jobPosting={jobPosting}
                    refreshJobPostings={refreshJobPostings}
                    handleAddSelectedJob={addSelectedJob}
                    handleRemoveSelectedJob={removeSelectedJob}
                  />
                ))}
              </ul>
            </div>
          </div>
        </article>
      </div>

      {showDiffConfirmPopup && (
        <JobPostingDiffConfirmPopup
          selectedJobId={selectedJob}
          onConfirm={() => {
            setShowDiffConfirmPopup(false);
            setShowDiffPopup(true);
          }}
          onClose={() => setShowDiffConfirmPopup(false)}
        />
      )}
      {showDiffPopup && (
        <JobPostingDiffPopup
          selectedJobId={selectedJob}
          onClose={() => setShowDiffPopup(false)}
        />
      )}
    </section>
  );
};
export default OverviewList;
