import {
  EmailOccupationBox,
  IdPasswordBox,
  PassAuthenticationBox,
  TermsBox,
} from "components/Signup";
import { MemberSignupContext, SignupContext } from "contexts/SignupContex";
import { USER_TYPE } from "utils/enums";
import useMemberSignup from "hooks/member/useMemberSignup";
import EventFollowIdBox from "components/Signup/EventFollowIdBox";
import ReferrerBox from "components/Signup/Comps/ReferrerFieldRow";
import ReferrerFieldRow from "components/Signup/Comps/ReferrerFieldRow";

const MemberSignup = () => {
  const { state, errors, handler } = useMemberSignup();

  return (
    <SignupContext.Provider value={{ ...state, errors, ...handler } as any}>
      <div className="join-form">
        <IdPasswordBox passwordVerify />

        <MemberSignupContext.Provider
          value={{ ...state, errors, ...handler } as any}
        >
          <PassAuthenticationBox
            onChange={(value) => handler.setAuthCode(value)}
          />
          <EmailOccupationBox />
          <ReferrerFieldRow />
        </MemberSignupContext.Provider>

        <TermsBox
          userType={USER_TYPE.개인회원}
          onChange={(value) => handler.setPolicyAgree(value)}
        />

        <EventFollowIdBox />
        <div className="form-box">
          <div className="submit-box">
            <button
              className="submit-btn"
              onClick={handler.signup}
              disabled={!errors}
            >
              회원가입 완료
            </button>
          </div>
        </div>
      </div>
    </SignupContext.Provider>
  );
};

export default MemberSignup;
