import ReactEChartsCore from "echarts-for-react/lib/core";

import { merge } from "lodash";

const hexToRgb = (hexValue: string) => {
  let hex;
  hexValue.indexOf("#") === 0
    ? (hex = hexValue.substring(1))
    : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

export const getColor = (name: string, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(`--bs-${name}`).trim();
};

export const rgbaColor = (color = "#2c7be5", alpha = 0.5) =>
  `rgba(${hexToRgb(color)},${alpha})`;

const getOption = () => ({
  color: getColor("primary"),
  tooltip: {
    trigger: "item",
    axisPointer: {
      type: "none",
    },
    padding: [7, 10],
    backgroundColor: getColor("gray-100"),
    borderColor: getColor("gray-100"),
    textStyle: { color: getColor("dark") },
    borderWidth: 1,
    transitionDuration: 0,
  },
  xAxis: {
    type: "category",
    show: false,
    boundaryGap: false,
  },
  yAxis: {
    show: false,
    type: "value",
    boundaryGap: false,
  },
  series: [
    {
      type: "bar",
      symbol: "none",
    },
  ],
  grid: { right: "0", left: "0", bottom: "0", top: "0" },
});

const BasicCharts = ({ echarts, options, ...rest }: any) => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={merge(getOption(), options)}
      {...rest}
    />
  );
};

export default BasicCharts;
