import { IResumeOverview } from "api/resume/types";
import ResumeOverview from "./ResumeOverview";

const ResumeOverviewList = ({
  resumes,
  totalCount,
  refreshResumes,
}: {
  resumes: IResumeOverview[];
  totalCount: number;
  refreshResumes: () => void;
}) => (
  <section className="main-recruit">
    <div className="inner wrap">
      <article className="list-layout3">
        <article className="list-head-box">
          <div className="head-txt-box">인재정보 {totalCount}건</div>
          <div className="list-option-box"></div>
        </article>

        <div className="list-body-box">
          <div className="list-wrap">
            <ul>
              {resumes.map((resume) => (
                <ResumeOverview
                  key={resume.resume_id}
                  resume={resume}
                  refreshResumes={refreshResumes}
                />
              ))}
            </ul>
          </div>
        </div>
      </article>
    </div>
  </section>
);
export default ResumeOverviewList;
