import { IJobOpeningOverview } from "api/jobPosting/types";
import NoPicturePng from "assets/img/img_logo.svg";
import { resourcePath, displayWorkExperienceByYear } from "utils/formatter";

interface ItemType {
  item: IJobOpeningOverview;
}

const MapItem = ({ item }: ItemType) => {
  return (
    <div className="item-box">
      <div className="item-wrap">
        <div className="img-box">
          {item?.logo_image_filename === "" ? (
            <div className="no-picture">
              <div className="name">{item.clinic_name}</div>
            </div>
          ) : (
            <img
              src={
                item?.logo_image_filename === ""
                  ? NoPicturePng
                  : resourcePath(item?.logo_image_filename || "")
              }
              alt="이미지"
            />
          )}
        </div>
        <div className="info-box">
          <div className="corp-box">{item.clinic_name}</div>
          <div className="subject-box">{item.title}</div>
          <div className="item-option-box">
            <div className="item-row">
              <div className="option-box">
                <i className="ic ic-place"></i>
                <span className="txt">{item.city}</span>
              </div>
            </div>
            <div className="item-row">
              <div className="option-box">
                <i className="ic ic-calendar"></i>
                <span className="txt">
                  {displayWorkExperienceByYear(
                    item.min_work_experience || 0,
                    item.max_work_experience || 0
                  )}
                </span>
              </div>
              <div className="option-box">
                <i className="ic ic-document"></i>
                <span
                  className="txt"
                  style={{
                    display: "inline-block",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: 105,
                  }}
                >
                  {(item.occupation_list || []).join(", ")}
                </span>
              </div>
            </div>
          </div>

          <div className="date-box">
            <div className="date-item">
              <div className="date-label">마감일</div>
              <div className="date-txt">
                {item.closing_date.replaceAll("-", ".")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapItem;
