import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import moment, { Moment } from "moment";

import { useUserContext } from "contexts/UserContext";
import { LectureTable } from "components/Lecture";
import LectureCompletePopup from "components/Popups/LectureCompletePopup";
import useLecture from "hooks/lecture/useLecture";
import { USER_TYPE } from "utils/enums";
import { lectureBaseurl } from "utils/formatter";
import { startLecture as startMemberLecture } from "api/member/rests";
import { startLecture as startClinicLecture } from "api/clinic/rests";
import { axiosFile } from "utils/axiosFile";
import { Banner } from "components/commons";

const LecturePlayPage = () => {
  const { userType } = useUserContext();
  const navigate = useNavigate();
  const { courseId, lectureId } = useParams();
  const [params] = useSearchParams();
  const nextLectureId = params.get("next");

  const [retentionTime, setRetentionTime] = useState(0);
  const [acceptable, setAcceptable] = useState<boolean>(false);
  const [completeTime, setCompleteTime] = useState<Moment | null>(null);
  // const [showCompletePopup, setShowCompletePopup] = useState<boolean>(false);

  const { lecture_list, lecture_type } = useLecture(Number(courseId)) || {
    lecture_type: "",
    lecture_list: [],
  };
  const afterNextLecture =
    lecture_list[
      lecture_list.findIndex(
        (list) => list.lecture_id.toString() === nextLectureId
      ) + 1
    ];
  const subLecture = lecture_list.find(
    (lecture) => lecture.lecture_id === Number(lectureId)
  );

  const startLecture = useCallback(async () => {
    if (subLecture) {
      userType === USER_TYPE.개인회원
        ? await startMemberLecture(
            lecture_type,
            subLecture.lecture_id,
            subLecture.collect_flag
          )
        : await startClinicLecture(
            lecture_type,
            subLecture.lecture_id,
            subLecture.collect_flag
          );
    }
  }, [userType, subLecture, lecture_type]);

  const handleComplete = async () => {
    if (acceptable) {
      const now = moment();
      setCompleteTime(now);
    }
  };

  useEffect(() => {
    if (!subLecture) return;
    if (retentionTime > subLecture?.play_time * 0.9) {
      setAcceptable(true);
    }
    // if (retentionTime > 10 * 0.9) {
    //   setAcceptable(true);
    // }
  }, [subLecture, retentionTime]);

  useEffect(() => {
    if (subLecture && subLecture.take_type === "NONE") {
      startLecture();
    }
  }, [subLecture, startLecture]);

  useEffect(() => {
    if (!subLecture) return;

    const id = setInterval(() => {
      !completeTime && setRetentionTime((prev) => prev + 10);
    }, 1000 * 10); // TODO: 10s 단위로 체크

    return () => clearInterval(id);
  }, [subLecture, completeTime]);

  return (
    <section className="edu-detail">
      <div className="detail-group">
        <div className="tit-box">
          <button
            className="back-btn"
            onClick={() =>
              navigate(`${lectureBaseurl(lecture_type)}/${courseId}`)
            }
          >
            <i className="ic ic-back"></i>
          </button>

          <div className="tit-txt">{subLecture?.name}</div>
          {subLecture?.material_download_link && (
            <button
              className="material-link"
              onClick={() =>
                axiosFile(
                  subLecture.material_download_link as string,
                  subLecture.material_download_filename as string
                )
              }
            >
              강의 자료 🗂
            </button>
          )}
        </div>

        <div className="video-box" style={{ height: "538px" }}>
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${subLecture?.video_id}`}
            title="YouTube video player"
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={false}
          ></iframe>
        </div>

        <div className="con-box">{subLecture?.description}</div>

        <div className="menu-box">
          <article className="lecture-menu-box">
            <div
              className={`lecture-menu-btn ${acceptable ? "on" : ""}`}
              onClick={handleComplete}
            >
              <span className="txt">수강완료 하기</span>
              <i className="ic ic-lock"></i>
            </div>

            {/* <a href="#none" className="lecture-menu-btn on">
                <span className="txt">수강완료 하기</span>
                <i className="ic ic-lock"></i>
              </a> */}
            <div style={{ marginTop: 50 }}>
              <Banner type="LECTURE_DETAIL" />
            </div>
          </article>
        </div>
      </div>

      <div className="detail-lecture">
        <LectureTable
          hideIssueButton={true}
          lectureType={lecture_type}
          lectureBaseUrl={`${lectureBaseurl(lecture_type)}/${courseId}`}
          lectures={lecture_list}
        />
      </div>

      {!!completeTime && (
        <LectureCompletePopup
          lectureId={Number(lectureId)}
          courseUrl={`${lectureBaseurl(lecture_type)}/${courseId}`}
          nextLectureUrl={
            nextLectureId
              ? `${lectureBaseurl(lecture_type)}/${courseId}/${nextLectureId}${
                  afterNextLecture ? "?next=" + afterNextLecture.lecture_id : ""
                }`
              : undefined
          }
          retentionTime={retentionTime}
          completeStrTime={completeTime.format("YYYY-MM-DD HH:mm:ss")}
          onClose={() => setCompleteTime(null)}
        />
      )}
    </section>
  );
};
export default LecturePlayPage;
