import { useEffect, useState } from "react";

import PhotoIdBox from "components/PhotoIdBox";

const PhotoForm = ({
  photos,
  onUpload,
  isResume,
}: {
  photos: string[];
  onUpload: (filename: string[]) => void;
  isResume?: boolean;
}) => {
  const [files, setFiles] = useState<Array<string | undefined>>(photos);

  useEffect(
    () => setFiles([...Array(6).keys()].map((i) => photos[i] || undefined)),
    [photos]
  );

  const addFile = (filename: string) => {
    onUpload([...photos, filename]);
  };

  const removeFile = (filename: string) => {
    onUpload(photos.filter((p) => p !== filename));
  };

  return (
    <article className="job-register-photo">
      <div className="list-wrap">
        <ul>
          {files.map((file, i) => (
            <li>
              <PhotoIdBox
                label="사진추가"
                popupTitle="사진등록"
                isResume={isResume}
                photoUrl={file}
                onChange={addFile}
                onRemove={removeFile}
              />
            </li>
          ))}
        </ul>
      </div>
    </article>
  );
};
export default PhotoForm;
