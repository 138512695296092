import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import { fetchClinicMember, updateClinicMember } from "api/admin/rests";
import { IAdminClinicMemberView } from "api/admin/types";

const ClinicMember = ({
  memberId,
  onClose,
}: {
  memberId: string;
  onClose: () => void;
}) => {
  const [member, setMember] = useState<IAdminClinicMemberView | null>(null);

  const updateMember = async () => {
    console.log(member);
    if (!member) return;
    try {
      await updateClinicMember(member.member_id, member);
      alert("수정되었습니다.");
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }
  };

  useEffect(() => {
    if (!memberId) return;
    (async () => {
      const { data } = await fetchClinicMember(memberId);
      setMember(data);
    })();
  }, [memberId]);

  return (
    <Modal show={!!memberId} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{member?.clinic_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {member ? (
          <Form
            id="clinic-member-form"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              console.log(e);
            }}
          >
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                병원명
              </Form.Label>
              <Col>
                <Form.Control
                  type="clinic_name"
                  value={member.clinic_name}
                  onChange={(e) =>
                    setMember({ ...member, clinic_name: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                홈페이지
              </Form.Label>
              <Col>
                <Form.Control
                  type="homepage_url"
                  value={member.homepage_url}
                  onChange={(e) =>
                    setMember({ ...member, homepage_url: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                휴대폰
              </Form.Label>
              <Col>
                <Form.Control
                  type="phone_number"
                  value={member.phone_number}
                  onChange={(e) =>
                    setMember({ ...member, phone_number: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                이메일
              </Form.Label>
              <Col>
                <Form.Control
                  type="email"
                  value={member.email}
                  onChange={(e) =>
                    setMember({ ...member, email: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                주소
              </Form.Label>
              <Col>
                <Form.Control
                  type="address"
                  value={member.address}
                  onChange={(e) =>
                    setMember({ ...member, address: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <div
              style={{
                display: "flex",
                gap: "8px",
                justifyContent: "flex-end",
                marginTop: "50px",
              }}
            >
              <Button
                id="clinic-member-form"
                type="submit"
                variant="primary"
                onClick={updateMember}
              >
                수정하기
              </Button>
              <Button variant="secondary" onClick={onClose}>
                닫기
              </Button>
            </div>
          </Form>
        ) : (
          <>선택된 회원이 없습니다.</>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default ClinicMember;
