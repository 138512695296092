import { USER_TYPE } from "utils/enums";
import UserTypeButton from "./UserTypeButton";

const UserTypeSelector = ({
  label = "로그인",
  userType,
  onChange,
}: {
  userType: USER_TYPE;
  label?: string;
  onChange: (userType: USER_TYPE) => void;
}) => (
  <article className="login-type-box">
    <UserTypeButton
      userType={USER_TYPE.개인회원}
      label="개인회원"
      baseLabel={label}
      isActive={userType === USER_TYPE.개인회원}
      onClick={() => onChange(USER_TYPE.개인회원)}
    />
    <UserTypeButton
      userType={USER_TYPE.병원회원}
      label="병원회원"
      baseLabel={label}
      isActive={userType === USER_TYPE.병원회원}
      onClick={() => onChange(USER_TYPE.병원회원)}
    />
  </article>
);

export default Object.assign(UserTypeSelector, {
  USER_TYPE,
});
