import { sendQnaClinicMessage, sendQnaMemberMessage } from "api/member/rests";
import { IQnaChatView } from "api/types";
import { useUserContext } from "contexts/UserContext";
import { groupBy } from "lodash";
import moment from "moment";
import { Fragment, useEffect, useMemo, useState } from "react";
import { USER_TYPE } from "utils/enums";
import { NoMessage } from "./Comps";

const QnaContent = ({
  qnas,
  setQnas,
}: {
  qnas: IQnaChatView[];
  setQnas: (qnas: IQnaChatView[]) => void;
}) => {
  const [showContents, setShowContents] = useState<boolean>(true);

  const { memberId, userType } = useUserContext();
  const [message, setMessage] = useState<string>("");
  const [composing, setComposing] = useState<boolean>(false);
  const isMember = userType === USER_TYPE.개인회원;

  const groupedQnas = useMemo(
    () =>
      groupBy(qnas, (qna) => moment(qna.reg_date).format("YYYY년 MM월 DD일")),
    [qnas]
  );

  const sendMessage = async () => {
    if (!message) return;

    isMember
      ? await sendQnaMemberMessage(message)
      : await sendQnaClinicMessage(message);
    setQnas([
      ...qnas,
      {
        id: 0,
        from_admin: false,
        member_type: isMember ? "MEMBER" : "CLINIC",
        member_id: isMember ? memberId : "",
        clinic_member_id: !isMember ? memberId : "",
        message: message,
        read_flag: false,
        is_qna: true,
        reg_date: moment().toString(),
      },
    ]);
    setMessage("");
  };

  useEffect(() => {
    var element = document.getElementById("chat-contents-list");
    if (!element) return;
    element.scrollTop = element.scrollHeight - element.clientHeight;
  }, [qnas]);

  return (
    <div
      className="chat-contents"
      style={{ display: showContents ? "flex" : "" }}
    >
      <div className="chat-mobile-box">
        <div className="close-btn" onClick={() => setShowContents(false)}></div>
        <div className="txt-box">1:1 문의</div>
      </div>

      <div className="chat-contents-list scroll-effect" id="chat-contents-list">
        {qnas.length === 0 ? (
          <NoMessage />
        ) : (
          <>
            {Object.keys(groupedQnas).map((dateKey, i) => (
              <Fragment key={i}>
                <div className="day-box">
                  <div className="date-txt">{dateKey}</div>
                </div>

                <div className="chat-msg-list">
                  {groupedQnas[dateKey].map((qna) => (
                    <div
                      key={qna.id}
                      className={`chat-msg-box ${
                        qna.from_admin ? "receive-box" : "send-box"
                      }`}
                    >
                      <div className="con-box">
                        {qna.message.split("\n").map((row, i) => (
                          <Fragment key={i}>
                            {row}
                            <br />
                          </Fragment>
                        ))}
                      </div>

                      <div className="date-box">
                        {moment(qna.reg_date).format(`YY.MM.DD`)}
                        <br />
                        {moment(qna.reg_date).format(`hh:mm`)}
                      </div>
                    </div>
                  ))}
                </div>
              </Fragment>
            ))}
          </>
        )}
      </div>
      {
        <div className="chat-input">
          <div className="input-box ">
            <textarea
              rows={1}
              // onkeydown="resize(this)"
              // onkeyup="resize(this)"
              className="scroll-effect"
              placeholder="메세지를 입력하세요."
              value={message}
              onCompositionStart={() => setComposing(true)}
              onCompositionEnd={() => setComposing(false)}
              onKeyDown={(e) => {
                const isEnter = e.key === "Enter";
                if (isEnter && !e.shiftKey && !composing) {
                  e.preventDefault();
                  sendMessage();
                }
              }}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>

          <button
            disabled={!message}
            className={`send-btn ${message ? "on" : ""}`}
            onClick={() => sendMessage()}
          >
            전송
          </button>
        </div>
      }
    </div>
  );
};

export default QnaContent;
