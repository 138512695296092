import { useCallback, useEffect, useState } from "react";
import { sortBy } from "lodash";
import { PageTitle } from "components/MyPage";

import { IJobActionResumeView } from "api/clinic/types";
import { fetchJobPostingHistory } from "api/clinic/rests";
import HeadBox from "components/MyPage/HeadBox";
import VolunteerBox from "components/Volunteer/VolunteerBox";

enum WISH_STATUS {
  전체 = "전체",
  찜한 = "찜한",
}

const VolunteerPage = () => {
  const [page, setPage] = useState<number>(1);
  // const [totalElements, setTotalElements] = useState<number>(0);
  // const [totalPage, setTotalPage] = useState<number>(0);
  const [histories, setHistories] = useState<IJobActionResumeView[]>([]);
  const [wishFilter, setWishFilter] = useState<WISH_STATUS>(WISH_STATUS.전체);

  const FilterComp = () => (
    <div className="sbox">
      <select
        value={wishFilter}
        onChange={(e) => setWishFilter(e.target.value as WISH_STATUS)}
      >
        <option value={WISH_STATUS.전체}>전체 이력서</option>
        <option value={WISH_STATUS.찜한}>찜한 이력서</option>
      </select>
    </div>
  );

  const fetch = useCallback(async () => {
    const {
      data: { element_list },
    } = await fetchJobPostingHistory("APPLY", page);
    setHistories(element_list);
    // setTotalPage(total_page);
    // setTotalElements(total_elements);
  }, [page]);

  useEffect(() => {
    (async () => await fetch())();
  }, [fetch]);

  return (
    <section className="my-corp-job">
      <article className="lnb-contents-tit">
        <PageTitle iconClass="ic-volunteer" title="지원자 현황" />
      </article>

      <HeadBox count={histories.length} filterComp={<FilterComp />} />

      <article className="my-volunteer-list">
        <div className="volunteer-box">
          <div className="volunteer-list">
            {sortBy(histories, ["reg_date", "resume_id"])
              .filter((h) =>
                wishFilter === WISH_STATUS.찜한 ? !!h.wish_flag : true
              )
              .map((volunteer) => (
                <VolunteerBox
                  key={volunteer.resume_id}
                  volunteer={volunteer}
                  handleRefresh={fetch}
                />
              ))}
          </div>
        </div>
      </article>
    </section>
  );
};

export default VolunteerPage;
