import axiosClient from "../axiosClient";

import { IResponse, IPageResponse } from "api/types";
import {
  IResumeView,
  IResultResponsePageViewResumeOverview,
  IResumeCountView,
  IResumeQuery,
  IResumeOverview,
} from "./types";
import { PAGE_SIZE } from "utils/constants";
import { SORT_DIRECTION, SORT_PROPERTY } from "utils/enums";

/**
 * 인재 목록 조회 - 유형(치과스텝, 치과의사)별 API
 * GET /resume
 */
export const fetchResumes = async (
  search_type: string = "STAFF",
  page: number = 1,
  sort_property: SORT_PROPERTY = SORT_PROPERTY.UPDATE_DATE,
  sort_direction: SORT_DIRECTION = SORT_DIRECTION.ASC
) => {
  const resp = await axiosClient.get<
    IResponse<IResultResponsePageViewResumeOverview>
  >(
    `/resume?search_type=${search_type}&page=${page}&size=${PAGE_SIZE}&sort_property=${sort_property}&sort_direction=${sort_direction}`
  );

  return resp.data;
};

/**
 * 이력서 개수 조회
 * GET /resume/count
 */
export const getResumeCount = async (city: string) => {
  const resp = await axiosClient.get<IResponse<IResumeCountView>>(
    `/resume/count?city=${city}`
  );

  return resp.data;
};

/**
 * 인재 검색
 * POST /resume/search
 */
export const searchResumes = async (
  page: number,
  size: number,
  params: IResumeQuery
) => {
  const resp = await axiosClient.post<IPageResponse<Array<IResumeOverview>>>(
    "/resume/search",
    {
      page,
      size,
      query: params,
    }
  );

  return resp.data;
};

/**
 * 인제 정보 조회
 * GET /resume/{resume_id}
 */
export const fetchResume = async (resume_id: number) => {
  const resp = await axiosClient.get<IResponse<IResumeView>>(
    `/resume/${resume_id}`
  );

  return resp.data;
};
