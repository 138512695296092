import { IResumeWorkExperienceView } from "api/resume/types";
import moment from 'moment';
import DatePicker from "components/DatePicker";
import { InputFieldRow } from "components/FormFields";

const CareerFieldBox = ({
  career,
  onClickDelete,
  onChange,
}: {
  career: IResumeWorkExperienceView;
  onClickDelete: () => void;
  onChange: (value: IResumeWorkExperienceView) => void;
}) => {
  return (
    <div className="career-item-box">
      <InputFieldRow
        label="근무처"
        value={career.company_name}
        placeholder="근무처를 입력해주세요"
        withItemBox={true}
        onChange={(name: string) =>
          onChange({
            ...career,
            company_name: name,
          })
        }
      />

      <InputFieldRow
        label="담당업무"
        value={career.work}
        placeholder="담당업무를 입력해주세요"
        withItemBox={true}
        onChange={(work: string) =>
          onChange({
            ...career,
            work,
          })
        }
      />
      <div className="row">
        <div className="item-box">
          <div className="subject">근무기간</div>

          <div className="con">
            <div className="career-box">
              <div className="mobile-row-box">
                <DatePicker
                  value={career.start_date}
                  maxDate={career.end_date ? moment(career.end_date).toDate() : null}
                  onChange={(date) =>
                    onChange({
                      ...career,
                      start_date: date,
                    })
                  }
                />

                <div className="year-range-box">-</div>

                <DatePicker
                  value={career.end_date}
                  minDate={career.start_date ? moment(career.start_date).toDate() : new Date()}
                  onChange={(date) =>
                    onChange({
                      ...career,
                      end_date: date,
                    })
                  }
                />
              </div>

              <div className="mobile-row-box">
                <div className="sbox off type-support m-other-type">
                  <select
                  style={{width: "60%"}}
                    value={career.work_flag ? "재직" : "퇴사"}
                    onChange={(e) =>
                      onChange({
                        ...career,
                        work_flag: e.target.value === "재직",
                      })
                    }
                  >
                    <option value="퇴사">퇴사</option>
                    <option value="재직">재직</option>
                  </select>
                  <button className="career-btn type-2" onClick={onClickDelete}>
                <span className="web">경력</span>삭제
              </button>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerFieldBox;
