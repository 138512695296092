import { useEffect, useState, ChangeEvent } from "react";

import { GROUP_CODE_ID, ICommonCodeView } from "api/code/types";
import { fetchCodes } from "api/code/rests";

const ClinicCategoryFieldRow = ({
  errorMessage,
  value,
  onChange,
}: {
  errorMessage?: string;
  value: string;
  onChange: (value: string) => void;
}) => {
  const [options, setOptions] = useState<ICommonCodeView[]>([
    {
      code_id: 66,
      group_code_id: 13,
      group_name: "병원유형",
      code_name: "기타",
      display_order: 6,
      description: "기타",
    },
    {
      code_id: 64,
      group_code_id: 13,
      group_name: "병원유형",
      code_name: "종합병원",
      display_order: 3,
      description: "종합병원",
    },
    {
      code_id: 65,
      group_code_id: 13,
      group_name: "병원유형",
      code_name: "치과기공소",
      display_order: 4,
      description: "치과기공소",
    },
    {
      code_id: 63,
      group_code_id: 13,
      group_name: "병원유형",
      code_name: "치과병원",
      display_order: 2,
      description: "치과병원",
    },
    {
      code_id: 62,
      group_code_id: 13,
      group_name: "병원유형",
      code_name: "치과의원",
      display_order: 1,
      description: "치과의원",
    },
    {
      code_id: 181,
      group_code_id: 13,
      group_name: "병원유형",
      code_name: "보건소",
      display_order: 5,
      description: "보건소",
    },
  ]);

  useEffect(() => {
    (async () => {
      const { data } = await fetchCodes(GROUP_CODE_ID.병원유형);
      setOptions(data);
    })();
  }, []);

  return (
    <div className="row">
      <div className="category-box">
        <div className="sbox full off">
          <select
            value={value}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              onChange(e.target.value)
            }
          >
            <option value="">병원분류</option>
            {options.map((op) => (
              <option key={op.code_id} value={op.code_name}>
                {op.description}
              </option>
            ))}
          </select>
        </div>
      </div>
      {errorMessage && <div className="input-msg-box">{errorMessage}</div>}
    </div>
  );
};

export default ClinicCategoryFieldRow;
