import Layout from "layouts";
import { MAIN_PATH, LECTURE_PATH } from "routes";

import logo from "assets/img/img_intro_logo.svg";
import RecruitImg from "assets/img/img_intro_item1.svg";
import LectureImg from "assets/img/img_intro_item2.svg";
import { useState } from "react";

const Logo = () => (
  <>
    <div className="logo-box">
      <img src={logo} alt="이미지" />
    </div>
    <div className="sub-box">
      구인구직부터 온라인 교육까지, <b>치과인!</b>
    </div>
  </>
);

const RecruitLink = () => (
  <a href={`${MAIN_PATH}`} className="intro-btn type-1">
    <div className="intro-txt">
      치과인
      <br />
      <b>구인구직</b>
    </div>

    <div className="intro-img">
      <img src={RecruitImg} alt="이미지" />
    </div>
  </a>
);

const LectureLink = () => (
  <a href={`${LECTURE_PATH}`} className="intro-btn type-2">
    <div className="intro-txt">
      치과인
      <br />
      <b>교육</b>
    </div>

    <div className="intro-img">
      <img src={LectureImg} alt="이미지" />
    </div>
  </a>
);

const IntroPage = () => {
  const [show, setShow] = useState<boolean>(true);

  const handleClose = () => setShow(false);

  return (
    <Layout extraClass="top-full" excludeHeader={true}>
      <section className="intro">
        <div className="wrap">
          <Logo />

          <div className="intro-menu-list">
            <RecruitLink />
            <LectureLink />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IntroPage;
