import { Breadcrumb, Tab, Tabs, Table } from "react-bootstrap";

import ClinicMemberTable from "components/Admin/ClinicMemberTable";
import AdminMemberTable from "components/Admin/AdminMemberTable";

const AdminUserPage = () => (
  <>
    <Breadcrumb>
      <Breadcrumb.Item>회원관리</Breadcrumb.Item>
    </Breadcrumb>

    <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="개인회원">
        <AdminMemberTable />
      </Tab>
      <Tab eventKey="profile" title="병원회원">
        <ClinicMemberTable />
      </Tab>
    </Tabs>
  </>
);
export default AdminUserPage;
