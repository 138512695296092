import {useCallback, useEffect, useRef, useState} from "react";

import {
  ILectureCourseCompleteOverview,
  ILectureCourseTakeOverview,
  ILectureDutyNotice, ILectureEmployee,
  ILectureEmployeeView,
  ILegalLectureTakeView
} from "api/education/types";
import {
  fetchCourseEmployees,
  fetchLegalLectureNotice, fetchMyCompleteLegalLecture,
  fetchMyLecture as fetchClinicLecture,
  fetchMyLegalLecture as fetchClinicLegalLecture
} from "api/clinic/rests";
import {fetchMyLecture as fetchMemberLecture, fetchMyLegalLecture as fetchMemberLegalLecture} from "api/member/rests";
import { useUserContext } from "contexts/UserContext";
import { USER_TYPE } from "utils/enums";
import {useNavigate, useSearchParams} from "react-router-dom";
import {LECTURE_PATH, LECTURE_SUBPATH, LOGIN_PATH, MAIN_PATH} from "routes";
import CertificationLegal from "../components/Lecture/CertificationLegal";
import PrintPopup from "../components/Popups/PrintPopup";
import {takeTypeToLabel} from "./DutyLecturePage";
import {useReactToPrint} from "react-to-print";
import Html2Pdf from "html2pdf.js";
import {fetchRecommendJobPostings, fetchRecommendJobPostingsWithLocation} from "../api/jobPosting/rests";
import {displayDate} from "../utils/formatter";


const DutyLectureCourseListPage = () => {

  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { isSignIn, userType } = useUserContext();
  const [lectures, setLectures] = useState<ILegalLectureTakeView[]>([]);
  const [mylectures, setMyLectures] = useState<ILectureCourseCompleteOverview[]>([]);

  const [selectLectures, setSelectLectures] = useState<ILectureCourseCompleteOverview[]>([]);
  const [lectureName, setLectureName] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [courseId, setCourseId] = useState<number>(-1);
  const [showPrintPopup, setShowPrintPopup] = useState<boolean>(false);
  const [pdfDown, setPdfDown] = useState<boolean>(false);
  const [employeeList, setEmployeeList] = useState<ILectureEmployee[]>([]);
  const printArea = useRef<HTMLDivElement | null>(null);
  const lectureId = Number(params.get("lectureId"))

  useEffect(() => {
    if (!isSignIn) navigate(LOGIN_PATH);
    if (userType === USER_TYPE.개인회원) {
      alert("개인회원은 접근 할 수 없습니다.");
      navigate(MAIN_PATH);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await fetchClinicLegalLecture();
      setLectures(data)
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await fetchMyCompleteLegalLecture();
      setMyLectures(data.filter((d) =>
        d.complete_date !== null
      ))
    })();
  }, []);
  useEffect(() => {
    if(lectureId !== 0) {
      setSelectLectures(mylectures.filter((d)=>d.lecture_id === lectureId))
    } else {
      setSelectLectures(mylectures)
    }
  }, [mylectures]);

  useEffect(() => {
    if(pdfDown) {
      downloadPdf()
      setEmployeeList([]);
      setCourseId(-1)
      setPdfDown(false);
    }
  }, [pdfDown]);

  const selectLecture = (value: string) => {
    if(value == 'all') {
      setSelectLectures(mylectures)
      return;
    }
    setSelectLectures(mylectures.filter((d)=>d.name === value))
  }

  const downloadPdf = useReactToPrint({
    content: () => printArea.current,
    print: async (printIframe: any) => {
      try {
        // PDF 생성 작업
        const document = printIframe.contentDocument;
        if (document) {
          const html = document.getElementsByTagName("html")[0];
          const opt = {
            filename: `${lectureName}.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
          };
          const exporter = new Html2Pdf(html, opt);
          await exporter.getPdf(true);
          // PDF 다운로드 작업이 완료된 후 상태 업데이트
        }
      } catch (error) {
        console.error("PDF 다운로드 오류:", error);
      }
    },
  });



  return (
    <section className="edu-law-list">
      <div className="edu-law-tit-box">
        <div className="tit-txt">수강 목록</div>
        <div className="sbox type-1 mt-2">
          <select style={{maxWidth:'250px', marginTop:'10px'}} onChange={(e)=>{
            selectLecture(e.target.value)
          }}>
            <option value="all">전체</option>
            {lectures
              .map((cate) => (
                <option value={cate.name} selected={cate.lecture_id === lectureId}>
                  {cate.name}
                </option>
              ))}
          </select>
        </div>
        <div className="web-table-box mt-3">
          <table>
            <colgroup>
              <col style={{ width: "auto" }} />
              <col style={{ width: "200px" }} />
              <col style={{ width: "200px" }} />
            </colgroup>
            <thead>
            <tr>
              <th className="center">강의명</th>
              <th className="center">수강날짜</th>
              <th className="center">교육결과</th>
            </tr>
            </thead>
            <tbody>
            {selectLectures.map((lecture) => {
              return (
                <tr>
                  <td>
                    <div className="message-box center">
                      <div className="message-txt type-1">{lecture.name}</div>
                    </div>
                  </td>
                  <td className="center">
                    <div className="message-txt type-1">{displayDate(lecture.complete_date)}</div>

                  </td>
                  <td className="center">
                    <div className="edu-finish-box" style={{border:0, boxShadow:'initial'}}>
                      <div className="finish-body-box">
                        <div className="menu-box" style={{padding:0}}>
                          <div
                            className="menu-btn type-1 on web"
                            onClick={() =>{

                              setShowPrintPopup(true)
                              let arr = lecture.complete_member?lecture.complete_member.split(',').map(member => ({
                                name: member,
                                phoneNumber: '',
                              })):[]
                              setCourseId(lecture.course_id)
                              setStartDate(lecture.start_date)
                              setEndDate(lecture.complete_date)
                              setEmployeeList(arr)
                              setLectureName(lecture.name)
                            }}
                          >
                            <span className="txt">인쇄하기</span>
                            <i className="ic ic-print"></i>
                          </div>
                          <div className="menu-btn type-2"
                               onClick={()=>{

                                 setPdfDown(true)
                                 let arr = lecture.complete_member?lecture.complete_member.split(',').map(member => ({
                                   name: member,
                                   phoneNumber: '',
                                 })):[]
                                 setCourseId(lecture.course_id)
                                 setStartDate(lecture.start_date)
                                 setEndDate(lecture.complete_date)
                                 setEmployeeList(arr)
                                 setLectureName(lecture.name)
                               }}
                          >
                            <span className="txt">pdf 저장</span>
                            <i className="ic ic-pdf"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
            {selectLectures.length  == 0 && (
              <tr >
                <td colSpan={3} className="center">수강 완료 이력이 없습니다.</td>
              </tr>
            )}
            </tbody>
          </table>
        </div>

        <div className="mobile-table-box">
          <div className="item-list">
            {selectLectures.map((lecture) => {
              return (
                <div className="item-box">
                  <div className="item-top-box">
                    <div className="message-box">
                      <div className="message-txt type-1">{lecture.name}</div>
                    </div>
                  </div>
                  <div className="item-mid-box">
                    <div className="message-box">
                      <div className="message-txt type-1">{displayDate(lecture.complete_date)}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

      </div>
      {showPrintPopup && (
        <PrintPopup title={lectureName} handleClose={() => setShowPrintPopup(false)}>
          <CertificationLegal
            courseId={Number(courseId)}
            lectureName={lectureName}
            startDate={startDate}
            endDate={endDate}
            employees={employeeList}
          />
        </PrintPopup>
      )}
      <div style={{ display: "none" }}>
        <div ref={printArea}>
          <CertificationLegal
            courseId={Number(courseId)}
            lectureName={lectureName}
            startDate={startDate}
            endDate={endDate}
            employees={employeeList}
          />
        </div>
      </div>
    </section>
  );
};
export default DutyLectureCourseListPage;
