import { useSearchParams } from "react-router-dom";
import SideMenu from "components/MyPage/SideMenu";
import Layout from "layouts";

import { Outlet } from "react-router-dom";
import WorkAreaPopup from "components/Popups/WorkAreaPopup";
import { useState } from "react";
import { useUserContext } from "contexts/UserContext";
import LoginPage from "pages/LoginPage";

const MypageLayout = () => {
  const [searchParams] = useSearchParams();
  const [showAreaPopup, setShowAreaPopup] = useState<boolean>(
    searchParams.get("area-popup") === "on"
  );

  const { isSignIn } = useUserContext();

  if (!isSignIn) return <LoginPage />;

  return (
    <>
      <Layout>
        <section className="lnb-layout">
          <div className="inner wrap">
            <SideMenu />
            <div className="lnb-contents">
              <Outlet />
            </div>
          </div>
        </section>
      </Layout>

      {showAreaPopup && (
        <WorkAreaPopup onClose={() => setShowAreaPopup(false)} />
      )}
    </>
  );
};

export default MypageLayout;
