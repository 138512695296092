import { Banner } from "components/commons";
import useEducationFaqs from "hooks/useEducationFaqs";

const LectureFaqPage = () => {
  const faqs = useEducationFaqs();

  return (
    <section className="edu-faq">
      <div className="edu-tit-box">
        <div className="sub-box">FAQ</div>

        <div className="main-box">치과인 온라인교육 FAQ</div>
      </div>

      <div className="faq-form-box">
        {faqs.map((faq) => (
          <div key={faq.faq_id} className="faq-box">
            <div className="faq-head-box">
              <div className="q-box">Q.</div>
              <div className="head-txt">{faq.question}</div>
            </div>
            <div className="faq-body-box">
              <div className="a-box">A.</div>
              <div className="body-txt">{faq.answer}</div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: 30 }}>
        <Banner type="FAQ" />
      </div>
    </section>
  );
};

export default LectureFaqPage;
