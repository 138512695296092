import useWorkArea, { useWorkAreaOptions } from "hooks/useWorkArea";

import { WorkAreaFieldRow } from "./Comps";

const WorkArea = () => {
  const {
    workAreas,
    handler: { getArea },
  } = useWorkAreaOptions();
  const {
    firstSeq,
    secondSeq,
    handler: { setFirstSeq, setSecondSeq, updateFirstSeq, updateSecondSeq },
  } = useWorkArea();

  return (
    <div className="my-form-box">
      <div className="my-form-tit-box">
        <div className="tit-txt">희망 근무지역</div>
      </div>
      <div className="my-form-con-box">
        <WorkAreaFieldRow
          label="1순위"
          workAreas={workAreas}
          value={firstSeq}
          onSelect={(id: string) => {
            const area = getArea(id);
            setFirstSeq(area);
          }}
          onConfirm={updateFirstSeq}
        />
        <WorkAreaFieldRow
          label="2순위"
          workAreas={workAreas}
          value={secondSeq}
          onSelect={(id: string) => {
            const area = getArea(id);
            setSecondSeq(area);
          }}
          onConfirm={updateSecondSeq}
        />
      </div>
    </div>
  );
};

export default WorkArea;
