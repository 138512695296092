import {useCallback, useEffect, useRef, useState} from "react";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "react-responsive";
import {fetchRecommendJobPostings, fetchRecommendJobPostingsWithLocation} from "api/jobPosting/rests";
import {IGeolocation, IJobOpeningOverview} from "api/jobPosting/types";
import { JOBPOSTING_PATH } from "routes";

import useGeolocation from "react-hook-geolocation";
import axios from "axios";
import {useUserContext} from "../../contexts/UserContext";
import {USER_TYPE} from "../../utils/enums";

const RecommendRecruitList = () => {
  const geolocation = useGeolocation();
  const { userType} = useUserContext();
  const container = useRef<HTMLDivElement>(null);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [jobPostings, setJobPostings] = useState<IJobOpeningOverview[]>([]);
  const [currentGeolocation, setGeolocation] = useState<IGeolocation|null>(null);

  const getPostings = useCallback(async() => {
    if(!currentGeolocation){
      return;
    }
    if(userType) {
      const { data } = await fetchRecommendJobPostings();
      setJobPostings(data);
      return;
    }
    const { data } = await fetchRecommendJobPostingsWithLocation(currentGeolocation);
    setJobPostings(data);

  }, [currentGeolocation]);

  useEffect(() => {
      (async()=>{
        await getPostings()
      })()
  }, [getPostings]);

  useEffect(() => {
    if(geolocation.error) {
     axios.get('https://geolocation-db.com/json/')
        .then((res) => {
          if(res.status == 200) {
            let data:IGeolocation = {latitude:res.data.latitude,longitude:res.data.longitude}
            setGeolocation(data);
          }
        })
    } else {
      setGeolocation(geolocation);
    }
  }, [geolocation]);

  return (
    <div className="announcement-form">
      <div className="announcement-item-list">
        <div className="swiper-button-next recommend-recruit-swiper-button-next"></div>
        <div className="swiper-container" ref={container}>
          {jobPostings.length > 0 && (
            <Swiper
              wrapperTag="ul"
              autoplay={true}
              loop={true}
              spaceBetween={isTabletOrMobile ? 10 : 20}
              slidesPerView={isTabletOrMobile ? 2 : 4}
              navigation={{ nextEl: ".recommend-recruit-swiper-button-next" }}
            >
              <ul className="swiper-wrapper">
                {jobPostings.map((jobPosting, i) => (
                  <SwiperSlide tag="li" className="swiper-slide" key={i}>
                    <a
                      href={`${JOBPOSTING_PATH}/${jobPosting.job_id}`}
                      className="item-box"
                    >
                      <div className="top-box">
                        <div className="label-box">
                          <div className="label-txt">추천</div>
                        </div>
                        <div className="subject-box">{jobPosting.title}</div>
                      </div>

                      <div className="bottom-box">
                        <div className="day-box">
                          <i className="ic ic-day"></i>
                          <span className="txt">
                            D-
                            {moment(jobPosting.closing_date).diff(
                              moment(),
                              "days"
                            )}
                          </span>
                        </div>
                        <div className="corp-box">{jobPosting.clinic_name}</div>
                        <div className="region-box">{jobPosting.city}</div>
                      </div>
                    </a>
                  </SwiperSlide>
                ))}
              </ul>
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecommendRecruitList;
