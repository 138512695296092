import { useReducer } from "react";

import {IMasterLoginRequest, IMemberLoginRequest} from "api/member/types";
import { MAIN_PATH } from "routes";

import {loginClinic, loginMasterClinic} from "api/clinic/rests";
import {loginMasterMember, loginMember} from "api/member/rests";
import { getFcmToken, getMemberId, setMemberId } from "utils/storage";
import { USER_TYPE } from "utils/enums";
import { loadFCM } from "utils/appBridge";

import browser from "browser-detect";

export enum LOGIN_ACTIONS {
  SET_VALUE = "SET_VALUE",
  SET_FAILED = "SET_FAILED",
}

export type LoginState = IMemberLoginRequest & {
  userType: USER_TYPE;
  isSaveMemberId: boolean;
  isFailed?: boolean;
};
export type LoginAction =
  | {
      type: LOGIN_ACTIONS.SET_VALUE;
      payload: {
        key: string;
        value: string | boolean;
      };
    }
  | {
      type: LOGIN_ACTIONS.SET_FAILED;
      payload: boolean;
    };

const initialLoginState: LoginState = {
  userType: USER_TYPE.개인회원,
  member_id: getMemberId() || "",
  password: "",
  isSaveMemberId: !!getMemberId(),
};

const loginReducer = (state: LoginState, action: LoginAction) => {
  switch (action.type) {
    case LOGIN_ACTIONS.SET_VALUE:
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    case LOGIN_ACTIONS.SET_FAILED:
      return { ...state, isFailed: action.payload };

    default:
      throw new Error("Unknown action type");
  }
};

const useLogin = () => {
  const [state, dispatch] = useReducer(loginReducer, initialLoginState);

  const _set = (key: keyof LoginState, newValue: boolean | string) =>
    dispatch({
      type: LOGIN_ACTIONS.SET_VALUE,
      payload: { key: key, value: newValue },
    });

  const login = async (master:boolean) => {
    const { userType, member_id, password } = state;
    loadFCM();
    const fcmToken = getFcmToken();
    const params: IMemberLoginRequest = { member_id, password };
    if (fcmToken) params.fcm_token = fcmToken;

    params.last_login_device = browser().mobile ? "M" : "W";
    try {
      if (userType === USER_TYPE.개인회원) {
        if(master) {
          await loginMasterMember(params);
        } else {
          await loginMember(params);
        }
      } else {
        if(master) {
          await loginMasterClinic(params);
        } else {
          await loginClinic(params);
        }
      }

      if (state.isSaveMemberId && !master) {
        setMemberId(member_id);
      } else {
        setMemberId("");
      }
      window.location.href = `${MAIN_PATH}`;
    } catch (e: any) {
      console.log(e);
      const data = e.response.data || null;
      if (data) {
        if (/blocked/.test(data.data))
          alert(
            "5회 이상 로그인에 실패하였습니다.\n10분 후 다시 시도해주세요."
          );
      }
      setMemberId("");
      dispatch({
        type: LOGIN_ACTIONS.SET_FAILED,
        payload: true,
      });
    }
  };

  return {
    state,
    handler: {
      login,
      toggleSaveMemberId: () => _set("isSaveMemberId", !state.isSaveMemberId),
      setMemberId: (memberId: string) => _set("member_id", memberId),
      setPassword: (password: string) => _set("password", password),
      setUserType: (userType: USER_TYPE) => _set("userType", userType),
      resetFailedState: () => _set("isFailed", false),
    },
  };
};
export default useLogin;
