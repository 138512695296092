import { fetchJobPostingCount } from "api/clinic/rests";
import { useUserContext } from "contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { JOBPOSTING_FORM_PATH, JOBPOSTING_PATH } from "routes";
import { USER_TYPE } from "utils/enums";

const Tab = ({
  label,
  tab,
  isSelect = false,
}: {
  label: string | JSX.Element;
  tab: string;
  isSelect?: boolean;
}) => (
  <a
    href={`${JOBPOSTING_PATH}?tab=${tab}`}
    className={`page-tabs-btn type-mobile-small ${isSelect ? "on" : ""}`}
  >
    {label}
  </a>
);

const TabSelector = ({ selectedTab }: { selectedTab: string | null }) => {
  const { userType } = useUserContext();

  const navigate = useNavigate();
  const handleClick = async () => {
    try {
      const {
        data: { current_count, max_count },
      } = await fetchJobPostingCount(selectedTab === "intern");
      if (current_count >= max_count) {
        alert(`노출 가능한 공고는 최대 ${max_count}개입니다.`);
        return;
      } else {
        navigate(`${JOBPOSTING_FORM_PATH}`);
      }
    } catch (e) {}
  };

  return (
    <section className="page-visual">
      <div className="visual-box visual-recruit">
        <div className="inner wrap">
          <div className="page-tit-box">채용정보</div>

          <div className="page-tabs-list">
            <Tab
              label="치과스탭"
              tab="staff"
              isSelect={selectedTab === "staff" || selectedTab === null}
            />
            <Tab
              label="치과의사"
              tab="dentist"
              isSelect={selectedTab === "dentist"}
            />
            <Tab label="지도검색" tab="map" isSelect={selectedTab === "map"} />

            <Tab
              label={<>실습치과찾기</>}
              tab="intern"
              isSelect={selectedTab === "intern"}
            />

            {userType === USER_TYPE.병원회원 ? (
              <button
                className="page-tabs-btn posting-write-btn"
                onClick={handleClick}
              >
                공고 등록 📄
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default TabSelector;
