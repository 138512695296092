import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import { INoticeView } from "api/notice/types";
import { fetchEducationNotice } from "api/education/rests";
import { Banner } from "components/commons";
import { axiosFile } from "utils/axiosFile";

const LectureNoticeDetailPage = () => {
  const { noticeId } = useParams();

  const [notice, setNotice] = useState<INoticeView | null>(null);
  console.log(notice);

  useEffect(() => {
    if (noticeId === undefined) return;

    (async () => {
      const { data } = await fetchEducationNotice(Number(noticeId));
      setNotice(data);
    })();
  }, [noticeId]);

  return (
    <section className="edu-board">
      <article className="board-view-head">
        <div className="view-tit-box">
          <div className="tit-box">{notice?.title}</div>
        </div>

        <div className="view-sub-box">
          <div className="sub-box">운영자</div>
          <div className="sub-box">
            {moment(notice?.upd_date || notice?.reg_date).format(
              `YYYY-MM-DD HH:mm:ss`
            )}
          </div>
        </div>
      </article>

      <article className="board-view-body">
        <div className="contents-box">
          {notice?.contents && (
            <div
              className="data-txt-box"
              dangerouslySetInnerHTML={{
                __html: notice.contents.replaceAll("\n", "<br/>"),
              }}
            ></div>
          )}
        </div>
        {notice?.file_download_link && (
          <button
            className="material-link"
            onClick={() =>
              axiosFile(
                notice.file_download_link as string,
                notice.file_download_filename as string
              )
            }
          >
            파일 자료 🗂
          </button>
        )}
      </article>
      <div style={{ marginTop: 30 }}>
        <Banner type="LECTURE_NOTICE" />
      </div>
    </section>
  );
};
export default LectureNoticeDetailPage;
