import UserContext from "contexts/UserContext";
import Routes from "routes";
import SwiperCore, { Autoplay, Grid, Navigation } from "swiper";

import { useMemo } from "react";

import { getAuth } from "utils/storage";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

SwiperCore.use([Autoplay, Grid, Navigation]);
const app = initializeApp({
  apiKey: "AIzaSyA12NCDHN36PPUWENGQFWbq4oTwMZiWy_I",
  authDomain: "kda-dent-in.firebaseapp.com",
  projectId: "kda-dent-in",
  storageBucket: "kda-dent-in.appspot.com",
  messagingSenderId: "490268167781",
  appId: "1:490268167781:web:5fb7884ca99bc5c3d8408c",
  measurementId: "G-GSJC0LSLL0",
});

const App = () => {
  const {
    member_id: memberId = "",
    name: name = "",
    user_type,
    occupation,
    clinic_type,
  } = getAuth();

  getAnalytics(app);
  /** TODO isMemberType 로직 채워넣기 */
  const value = useMemo(
    () => ({
      memberId,
      name,
      userType: user_type,
      isSignIn: !!memberId,
      occupation: occupation,
      clinicType:clinic_type,
    }),
    [memberId, user_type, memberId, occupation]
  );

  return (
    <UserContext.Provider value={value}>
      <Routes />
    </UserContext.Provider>
  );
};

export default App;
