import { useEffect, useState, useCallback } from "react";
import moment from "moment";

import { fetchJobPostingHistory, cancelApply } from "api/member/rests";
import { IJobActionOpeningView } from "api/member/types";
import Paginator from "components/Paginator";
import { JOBPOSTING_PATH } from "routes";
import { displayDate, isOverdue } from "utils/formatter";

const ApplyHistoryPage = () => {
  const [page, setPage] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [histories, setHistories] = useState<IJobActionOpeningView[]>([]);

  const fetch = useCallback(async () => {
    const {
      data: { total_page, total_elements, element_list },
    } = await fetchJobPostingHistory("APPLY", page);
    setHistories(element_list);
    setTotalPage(total_page);
    setTotalElements(total_elements);
  }, [page]);

  const cancel = async (jobId: number, resumeId: number) => {
    try {
      await cancelApply(jobId, resumeId);
      await fetch();
    } catch (e) {
      alert("오류가 발생하였습니다.");
    }
  };

  useEffect(() => {
    (async () => await fetch())();
  }, [page]);

  return (
    <section className="my-resume-group">
      <article className="lnb-contents-tit">
        <div className="tit-box">
          <i className="ic ic-apply"></i>
          <span className="txt">지원내역</span>
        </div>
      </article>

      <article className="my-group-head-box">
        <div className="group-head-box">
          <div className="left-box">
            <div className="cnt-box">총 {totalElements}건</div>
          </div>
        </div>
      </article>

      <div className="group-data-body-box">
        <div className="list-wrap">
          <ul>
            {histories.map((history) => (
              <History
                key={history.job_id}
                history={history}
                onCancel={cancel}
              />
            ))}
          </ul>
        </div>
      </div>

      {totalPage > 1 && (
        <Paginator
          currentPage={page}
          totalPage={totalPage}
          changePage={(page) => setPage(page)}
        />
      )}
    </section>
  );
};
export default ApplyHistoryPage;

const History = ({
  history,
  onCancel,
}: {
  history: IJobActionOpeningView;
  onCancel: (jobId: number, resumeId: number) => void;
}) => {
  return (
    <li>
      <div className="item-box">
        <div className="date-box">
          <span className="subject type-1">지원일</span>{" "}
          <span className="con">{displayDate(history.action_date)}</span>
        </div>

        <div className="name-state-box">
          <div className="mobile state-box">
            <div className="state-type-box type-1">채용중</div>
          </div>
          <a href={`${JOBPOSTING_PATH}/${history.job_id}`} className="name-box">
            {`${history.intern_flag ? "[실습치과] " : ""}${history.title}`}
          </a>
        </div>

        <article className="my-summary-box m-type2">
          <div className="summary-box">
            <div className="summary-item-box">
              <div className="row">
                <div className="summary-txt-box type-5">
                  {history.clinic_name}
                </div>
              </div>
            </div>
          </div>
        </article>

        <div className="state-box">
          <div
            className={`state-type-box ${
              isOverdue(history.closing_date) ? "type-2" : "type-1"
            } web`}
          >
            {isOverdue(history.closing_date) ? "마감" : "채용중"}
          </div>
          {history.read_flag && <div className="state-read-box">읽음</div>}
        </div>

        <article className="my-menu-box">
          <div className="menu-box">
            <a
              href={`${JOBPOSTING_PATH}/${history.job_id}`}
              className="menu-btn type-1"
            >
              <span className="txt web">공고보기</span>
              <span className="txt mobile">상세</span>
            </a>
            <div
              className="menu-btn type-2"
              onClick={() => onCancel(history.job_id, history.resume_id)}
            >
              <span className="txt">취소</span>
            </div>
          </div>
        </article>
      </div>
    </li>
  );
};
