import classNames from "classnames";
import { Badge, Card, Col, Form, ProgressBar, Row } from "react-bootstrap";
import Flex from "./Flex";

interface lectureItem {
  id: number;
  title: string;
  progress: number;
  duration: string;
  color: string;
}

interface lectureRow {
  lecture: lectureItem;
  isLast: boolean;
}

const Lecture = ({ lecture, isLast }: lectureRow) => {
  const { color, progress, duration, title } = lecture;
  return (
    <Row
      className={classNames("align-items-center py-2", {
        "border-bottom border-200 ": !isLast,
      })}
    >
      <Col className="py-1">
        <Flex className="align-items-center">
          <div className="avatar avatar-xl me-3">
            <div className={`avatar-name rounded-circle bg-soft-${color}`}>
              <span className={`fs-0 text-${color.split("-")[1] || color}`}>
                {title[0]}
              </span>
            </div>
          </div>
          <Flex className="position-relative">
            <Flex tag="h6" align="center" className="mb-0">
              <span className="text-800 stretched-link">
                {title}
              </span>
              <Badge pill bg="200" className="ms-2 text-primary">
                {progress}%
              </Badge>
            </Flex>
          </Flex>
        </Flex>
      </Col>
      <Col>
        <Row className="justify-content-end align-items-center">
          <Col xs="auto">
            <div className="fs--1 fw-semi-bold">{duration}</div>
          </Col>
          <Col xs="5" className="pe-card">
            <ProgressBar now={progress} style={{ height: 5 }} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const data: lectureItem[] = [
  {
    id: 1,
    title: "치과 진료과목",
    progress: 90,
    duration: "180",
    color: "primary",
  },
  {
    id: 2,
    title: "치과 인테리어 소개",
    progress: 78,
    duration: "120",
    color: "success",
  },
  {
    id: 3,
    title: "환자응대",
    progress: 79,
    duration: "55",
    color: "info",
  },
  {
    id: 4,
    title: "문진표 작성",
    progress: 38,
    duration: "30",
    color: "warning",
  },
  {
    id: 5,
    title: "진료비 수납",
    progress: 40,
    duration: "21",
    color: "danger",
  },
];

const PopLectureList = () => {
  return (
    <Card>
      <Card.Header>
        <h6 className="mb-0 mt-2">인기 강의 상위 5개</h6>
      </Card.Header>

      <Card.Body className="py-0">
        {data.map((lecture: lectureItem, index: number) => (
          <Lecture
            lecture={lecture}
            isLast={index === data.length - 1}
            key={lecture.id}
          />
        ))}
      </Card.Body>

      {/* <FalconCardFooterLink title="Show all projects" size="sm" /> */}
    </Card>
  );
};

export default PopLectureList;
