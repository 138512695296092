import { useEffect, useState } from "react";

const WorkExperienceRow = ({
  min_work_experience,
  max_work_experience,
  changeMinWorkExperience,
  changeMaxWorkExperience,
}: {
  min_work_experience: null | number;
  max_work_experience: null | number;
  changeMinWorkExperience: (experience: null | string) => void;
  changeMaxWorkExperience: (experience: null | string) => void;
}) => {
  const [experience, setExperience] = useState<string>("new");
  const [showYears, setShowYears] = useState<boolean>(false);

  useEffect(() => {
    if (min_work_experience === 0 && max_work_experience === 0) {
      setExperience("new");
    } else if (min_work_experience === null && max_work_experience === null) {
      setExperience("null");
    } else {
      setExperience("experienced");
    }
  }, [min_work_experience, max_work_experience]);

  useEffect(() => {
    if (experience === "experienced") {
      setShowYears(true);
    } else {
      setShowYears(false);
    }
  }, [experience]);

  return (
    <div className="row">
      <div className="item-box">
        <div className="subject">경력조건*</div>
        <div className="con">
          <div className="career-box">
            <div className="sbox smaller off">
              <select
                value={experience}
                onChange={(e) => {
                  const { value } = e.target;

                  if (value === "new") {
                    setExperience("new");
                    changeMinWorkExperience("0");
                    changeMaxWorkExperience("0");
                    setShowYears(false);
                  } else if (value === "null") {
                    setExperience("null");
                    changeMinWorkExperience(null);
                    changeMaxWorkExperience(null);
                    setShowYears(false);
                  } else {
                    setExperience("experienced");
                    setShowYears(true);
                  }
                }}
              >
                <option value="new">신입</option>
                <option value="experienced">경력</option>
                <option value="null">무관</option>

              </select>
            </div>

            {showYears && (
              <>
                <div className="tbox smaller">
                  <input
                    type="text"
                    placeholder="0"
                    value={min_work_experience || 0}
                    onChange={(e) =>
                      changeMinWorkExperience(
                        e.target.value.replace(/[^0-9]/g, "")
                      )
                    }
                  />
                </div>

                <div className="unit-box">년</div>

                <div className="year-range-box">-</div>

                <div className="tbox smaller">
                  <input
                    type="text"
                    placeholder="0"
                    value={max_work_experience || 0}
                    onChange={(e) =>
                      changeMaxWorkExperience(
                        e.target.value.replace(/[^0-9]/g, "")
                      )
                    }
                  />
                </div>

                <div className="unit-box">년</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WorkExperienceRow;
